import { PopupActions } from '@randstad-lean-mobile-factory/react-components-core';
import { BUTTON_STATUS } from '@randstad-lean-mobile-factory/react-components-core/dist/Buttons/FetchButton/FetchButton.types';
import * as z from 'zod';

import { IdLabel, MissionWithoutSchedule } from 'src/Services/API';

export interface Props {
  selectedMissions: MissionWithoutSchedule[];
  selectedCompany?: IdLabel;
  selectedQualification?: IdLabel;
  setSelectedMissions: (missions: MissionWithoutSchedule[]) => void;
  setSelectedCompany: (company: IdLabel | undefined) => void;
  setSelectedQualification: (qualification: IdLabel | undefined) => void;
  missionDetailFetchStatus: BUTTON_STATUS;
  innerRef: React.RefObject<PopupActions>;
}

export const missionSearchModalSchema = z.object({
  selectedCompany: z
    .object({
      id: z.string(),
      label: z.string(),
      siret: z.string().optional(),
      companyAddress: z
        .object({
          address1: z.string().optional(),
          address2: z.string().optional(),
          address3: z.string().optional(),
          postalCode: z.string().optional(),
          city: z.string().optional(),
        })
        .optional(),
    })
    .optional(),
  selectedQualification: z.object({ id: z.string(), label: z.string() }).optional(),
  selectedMissionIds: z.array(z.string()),
});
