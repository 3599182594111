import {
  Button,
  FetchButton,
  Modal,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { DrawingPencil } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { TextArea, useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import React from 'react';

import { useReopenR1 } from 'src/Hooks/Candidates/useReopenR1';
import { toFetchStatus } from 'src/Services/ReactQuery';

import styles from './R1ReopenModal.module.scss';
import { Props, r1ReopenSchema } from './R1ReopenModal.types';

const R1ReopenModal = ({ isOpen, close, status, interviewId }: Props) => {
  const { control, handleSubmit, reset } = useFormWithZodResolver({
    schema: r1ReopenSchema,
    defaultValues: { comment: '' },
  });
  const { mutate, ...mutation } = useReopenR1();
  return (
    <Modal
      open={isOpen}
      title="retour du R1 à l'agence"
      icon={<DrawingPencil className={styles.modalIcon} />}
      onClose={() => {
        reset();
        close();
      }}
      footerActionsRight={[
        <Button.Secondary onClick={() => close()}>annuler</Button.Secondary>,
        <FetchButton
          fetchStatus={toFetchStatus(mutation)}
          title="valider"
          onClick={handleSubmit(({ comment }) => {
            mutate({ id: interviewId, status, comment });
          })}
          onSuccess={() => close()}
        />,
      ]}
    >
      <WithLightTitle title="détails sur le retour du R1">
        <TextArea
          control={control}
          name="comment"
          className={styles.comment}
          placeholder="décrivez les raisons du retour du R1"
        />
      </WithLightTitle>
    </Modal>
  );
};

export default R1ReopenModal;
