import { HorizontalCardWithTitle } from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';

import styles from './CandidateTests.module.scss';
import { Props } from './CandidateTests.types';

const CandidateTests = ({ test }: Props) => {
  const testType = test.type?.label ?? 'validation des compétences';
  const details = `passé le : ${moment(test.completedOn).format('DD/MM/YYYY')} | ${
    test.agencyId
  } | moyenne : ${test.completionRate?.split('/')[0]}%`;

  return (
    <HorizontalCardWithTitle
      className={styles.testCard}
      title={test.label?.toLocaleLowerCase() ?? ''}
      subtitles={[testType, details]}
      titleClassname={styles.testCardTitle}
      subtitlesClassname={styles.testCardSubtitle}
    />
  );
};

export default CandidateTests;
