import {
  Button,
  Modal,
  PopupActions,
  toast,
} from '@randstad-lean-mobile-factory/react-components-core';
import React, { useRef, useState } from 'react';
import ContentLoader from 'react-content-loader';

import { CameraIcon } from 'src/Assets';
import { DocumentFile } from 'src/Containers/AddNewTalent/AddNewTalent.types';

import styles from './PhotoModal.module.scss';
import { Props } from './PhotoModal.types';

// aspect ratio for A4 paper is 1:1.41
const PHOTO_HEIGHT = 720;
const PHOTO_WIDTH = PHOTO_HEIGHT / 1.41;

const PhotoModal = ({ onSuccess }: Props) => {
  const innerRef = useRef<PopupActions>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const photoRef = useRef<HTMLCanvasElement>(null);
  const [localStream, setLocalStream] = useState<MediaStreamTrack[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const onCloseModal = () => {
    localStream.forEach(t => t.stop());
    setIsLoading(true);
  };
  const getVideo = async () => {
    let stream = null;

    try {
      stream = await navigator.mediaDevices.getUserMedia({
        video: { width: PHOTO_WIDTH, height: PHOTO_HEIGHT },
      });
    } catch (err) {
      if (err instanceof DOMException) {
        toast.error(
          err.name === 'NotAllowedError'
            ? "veuillez autoriser l'application à prendre des photos / vidéos"
            : err.name
        );
      }
    }

    const track = stream?.getTracks().map(t => t) ?? [];
    setLocalStream(track);

    setIsLoading(false);

    const video = videoRef.current;
    video && (video.srcObject = stream);
    await video?.play();
  };

  const dataUrlToFile = async (dataUrl: string, fileName: string): Promise<File> => {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: 'image/jpeg' });
  };
  const paintToCanvas = () => {
    const video = videoRef.current;
    const photo = photoRef.current;
    const ctx = photo?.getContext('2d');
    photo && (photo.width = PHOTO_WIDTH) && (photo.height = PHOTO_HEIGHT);

    return setInterval(() => {
      video && ctx?.drawImage(video, 0, 0, PHOTO_WIDTH, PHOTO_HEIGHT);
    }, 200);
  };

  const takePhoto = async () => {
    const photo = photoRef.current;
    const documentFile: DocumentFile = {};
    documentFile.base64File = photo?.toDataURL('image/jpeg');
    documentFile.file = documentFile?.base64File
      ? await dataUrlToFile(documentFile.base64File, 'image.jpeg')
      : undefined;
    innerRef?.current?.close();
    onSuccess(documentFile);
  };

  return (
    <Modal
      ref={innerRef}
      onClose={() => {
        onCloseModal();
      }}
      title="veuillez prendre le document en photo"
      onOpen={() => getVideo()}
      trigger={
        <Button.Secondary
          className={styles.addByPhotoButton}
          leftIcon={<CameraIcon className={styles.cameraIcon} />}
          text="utiliser ma caméra"
        />
      }
      footerActionsRight={[
        <Button.Primary
          key="photomodalprimarybutton"
          onClick={() => {
            takePhoto();
          }}
          disabled={isLoading}
        >
          prendre la photo
        </Button.Primary>,
      ]}
    >
      {isLoading && (
        <ContentLoader height="39rem" width="100%" uniqueKey="new-talent-photo">
          <rect x="2%" y="10" rx="4" ry="4" width="100%" height="100%" />
        </ContentLoader>
      )}
      {!isLoading && (
        <div className={styles.videoContainer}>
          <video onCanPlay={() => paintToCanvas()} ref={videoRef} className={styles.video} />
          <canvas ref={photoRef} hidden />
        </div>
      )}
    </Modal>
  );
};

export default PhotoModal;
