import { Button, Modal, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { IllusRLLanguage } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

import { DefaultIcon } from 'src/Assets';
import CriteriaButton from 'src/Components/CriteriaButton';
import CriteriaCard from 'src/Components/CriteriaCard';
import SelectLanguage from 'src/Containers/Selects/SelectLanguage';
import { useFetchLanguages } from 'src/Hooks/Languages/useFetchLanguages';
import { languageIconMapping } from 'src/Services/IconMapping';
import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './LanguageModal.module.scss';
import { CriteriaType, Props } from './LanguageModal.types';

const LanguageModal = ({
  nested,
  open,
  onClose,
  criteriaType,
  languages,
  onLanguageChange,
}: Props) => {
  const [selectedLanguages, setSelectedLanguages] = useState(languages);
  const { data, isLoading, isSuccess } = useFetchLanguages();

  const languagesWithIcon =
    data?.map(language => {
      return {
        ...language,
        icon: languageIconMapping[language.label as keyof typeof languageIconMapping],
      };
    }) ?? [];

  const topLanguagesWithIcon = languagesWithIcon.filter(
    language => language.isTopLanguage === true
  );

  const selectedTopLanguages = selectedLanguages.filter(selectedLanguage =>
    topLanguagesWithIcon
      ?.map(topLanguageWithIcon => topLanguageWithIcon.identifier)
      .includes(selectedLanguage.identifier)
  );

  const otherSelectedLanguages = selectedLanguages.filter(
    selectedLanguage =>
      !topLanguagesWithIcon
        ?.map(topLanguageWithIcon => topLanguageWithIcon.identifier)
        .includes(selectedLanguage.identifier)
  );

  useEffect(() => {
    setSelectedLanguages(languages);
  }, [languages, setSelectedLanguages]);

  return (
    <Modal
      icon={<IllusRLLanguage />}
      nested={nested}
      onClose={onClose}
      title="langues"
      open={open}
      footerActionsLeft={[
        <Button.Tertiary
          onClick={() => {
            onLanguageChange(languages);
          }}
        >
          tout réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <Button.Primary
          onClick={() => {
            onLanguageChange(selectedLanguages);
            onClose?.();
          }}
        >
          valider
        </Button.Primary>,
      ]}
    >
      <div className={styles.selectedNumberInfo}>
        {pluralFormat(selectedLanguages.length, 'sélectionnée')}
      </div>
      <WithLightTitle title="sélectionner parmi ces langues les plus utilisées">
        <div className={styles.topLanguagesGrid}>
          {isLoading &&
            new Array(6).fill(undefined).map((_, idx) => {
              return (
                <CriteriaButton.Loading key={idx} className={styles.topLanguageButtonLoader} />
              );
            })}
          {isSuccess && (
            <>
              {topLanguagesWithIcon?.map(topLanguage => {
                const isSelected = selectedLanguages
                  .map(selectedLanguage => selectedLanguage.identifier)
                  .includes(topLanguage.identifier);
                return (
                  <CriteriaButton
                    key={topLanguage.identifier}
                    icon={topLanguage.icon}
                    label={topLanguage.label ?? ''}
                    onClick={() => {
                      if (
                        selectedLanguages.find(
                          language => language.identifier === topLanguage.identifier
                        )
                      ) {
                        setSelectedLanguages(
                          selectedLanguages.filter(
                            language => language.identifier !== topLanguage.identifier
                          )
                        );
                      } else {
                        setSelectedLanguages([...selectedLanguages, topLanguage]);
                      }
                    }}
                    selected={isSelected}
                    className={classnames(styles.topLanguageButton, {
                      [styles.selected]: isSelected,
                    })}
                    iconClassName={styles.topLanguageFlagContainer}
                  />
                );
              })}
            </>
          )}
        </div>
      </WithLightTitle>
      <SelectLanguage
        selectedLanguages={selectedLanguages}
        setLanguages={setSelectedLanguages}
        languages={data?.filter(language => !language.isTopLanguage) ?? []}
      />
      {otherSelectedLanguages.length > 0 && (
        <div className={styles.listContainer}>
          <WithLightTitle title={`${otherSelectedLanguages.length} autre(s) langue(s) ajoutée(s)`}>
            {otherSelectedLanguages.map((language, idx) => (
              <React.Fragment key={language.identifier}>
                <div className={styles.separator}></div>
                <CriteriaCard
                  key={language.identifier}
                  className={styles.criteriaCard}
                  title={language.label ?? ''}
                  weight={criteriaType === CriteriaType.weight ? language.wishPriority : undefined}
                  level={criteriaType === CriteriaType.level ? language.level : undefined}
                  icon={
                    languagesWithIcon?.find(
                      languageWithIcon => languageWithIcon.identifier === language.identifier
                    )?.icon ?? <DefaultIcon className={styles.defaultIcon} />
                  }
                  onWeightChange={weight => {
                    const newLanguage = {
                      ...language,
                      wishPriority: weight,
                    };
                    const newLanguages = otherSelectedLanguages.slice();
                    newLanguages[idx] = newLanguage;
                    setSelectedLanguages([...selectedTopLanguages, ...newLanguages]);
                  }}
                  onLevelChange={level => {
                    const newLanguage = {
                      ...language,
                      level: level,
                    };
                    const newLanguages = otherSelectedLanguages.slice();
                    newLanguages[idx] = newLanguage;
                    setSelectedLanguages([...selectedTopLanguages, ...newLanguages]);
                  }}
                  onDelete={() =>
                    setSelectedLanguages(
                      selectedLanguages.filter(
                        otherLanguage => otherLanguage.identifier !== language.identifier
                      )
                    )
                  }
                />
              </React.Fragment>
            ))}
            <div className={styles.separator}></div>
          </WithLightTitle>
        </div>
      )}
    </Modal>
  );
};

export default LanguageModal;
