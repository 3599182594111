import { Button, Modal } from '@randstad-lean-mobile-factory/react-components-core';

import { IllusWarning } from 'src/Assets';
import { useDeleteCandidateR1 } from 'src/Hooks/Candidates/useDeleteCandidateR1';

import styles from './DeleteR1Modal.module.scss';
import { Props } from './DeleteR1Modal.types';

const DeleteR1Modal = ({ open, onClose, r1Interview }: Props) => {
  const deleteR1Mutation = useDeleteCandidateR1(r1Interview?.interviewId ?? '');
  return (
    <Modal
      open={open}
      title="attention"
      onClose={onClose}
      icon={<IllusWarning />}
      footerActionsRight={[
        <Button.Secondary
          onClick={() => {
            onClose();
          }}
        >
          annuler
        </Button.Secondary>,
        <Button.Primary
          onClick={() => {
            deleteR1Mutation.mutate();
            onClose();
          }}
        >
          supprimer ce R1
        </Button.Primary>,
      ]}
    >
      <div className={styles.text}>Êtes-vous sûr de vouloir supprimer ce R1 ?</div>
    </Modal>
  );
};

export default DeleteR1Modal;
