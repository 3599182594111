import { WithLightTitle, AddButton } from '@randstad-lean-mobile-factory/react-components-core';
import { PlusOpen } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { ComboBox, DropDown } from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useState } from 'react';
import { useController } from 'react-hook-form';

import { FETCH_STATUS } from 'src/Redux/Types';
import { Language, LanguageLevel } from 'src/Services/API';
import { pluralFormat } from 'src/Utils/pluralFormat';

import { detectedItemsCount, generateUniqueKey, replaceItem } from '../../utils';
import ParsedDocumentLabel from '../Components/ParsedDocumentLabel/ParsedDocumentLabel.component';
import RemoveItem from '../Components/RemoveItem/RemoveItem.component';

import styles from './SelectLanguages.module.scss';
import { Props } from './SelectLanguages.types';

const researchedLanguages = (
  keyword: string,
  languages: Language[],
  selectedLanguagesIdentifiers: string[]
) => {
  return languages.filter(
    x =>
      x.label?.toLowerCase().includes(keyword.toLowerCase()) &&
      !selectedLanguagesIdentifiers.some(identifier => x.id === identifier)
  );
};

const languageLevels = [
  {
    key: LanguageLevel.OccasionalPractice,
    value: 'Pratique occasionelle',
  },
  {
    key: LanguageLevel.CommonPractice,
    value: 'Pratique courante',
  },
  {
    key: LanguageLevel.Bilingual,
    value: 'Bilingue',
  },
];

const SelectLanguage = ({ control, languages, watch }: Props) => {
  const languageController = useController({
    control,
    name: 'languages',
  });

  const watchLanguages = watch('languages');
  const detectedLanguagesCount = detectedItemsCount(languageController.field.value);
  const [keywords, setKeywords] = useState(
    languageController.field.value.map(language => language.selectedItem.label)
  );
  return (
    <div className={styles.container}>
      <div className={styles.subtitle}>
        langues ({pluralFormat(detectedLanguagesCount, 'détectée')})
      </div>
      {watchLanguages &&
        watchLanguages.map((language, index) => {
          return (
            <div className={styles.languageItem} key={language.uniqueKey}>
              {language.parsedDocumentLabel && (
                <ParsedDocumentLabel
                  title={'langue détectée'}
                  label={language.parsedDocumentLabel}
                  titleClassName={styles.parsedDocumentLabelTitle}
                />
              )}
              <div className={styles.languageContainer}>
                <WithLightTitle title={`langue #${index + 1}`} className={styles.comboBoxContainer}>
                  <ComboBox
                    id="languages"
                    control={control}
                    name={`languages.${index}.selectedItem`}
                    keyValueExtractor={(searchResult: Language) => ({
                      key: searchResult.id,
                      value: searchResult.label ?? '',
                      level: undefined,
                    })}
                    placeholder="sélectionner"
                    onSearch={keyword => {
                      setKeywords(keywords => {
                        keywords.splice(index, 1, keyword);
                        return [...keywords];
                      });
                    }}
                    searchResults={researchedLanguages(
                      keywords[index] ?? '',
                      languages,
                      languageController.field.value?.map(
                        language => language?.selectedItem?.id ?? ''
                      )
                    )}
                    disableClearable
                    minLengthToSearch={1}
                    fetchStatus={FETCH_STATUS.FULFILLED}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                </WithLightTitle>
                <WithLightTitle title="niveau" className={styles.languageLevelContainer}>
                  <div className={styles.dropDownContainer}>
                    <DropDown
                      control={control}
                      name={`languages.${index}.level`}
                      placeholder="niveau de langue"
                      items={languageLevels ?? []}
                      onSelectItem={(item: { key: string; value: string }) => {
                        languageController.field.onChange(
                          replaceItem(watchLanguages, index, {
                            ...language,
                            level: item,
                          })
                        );
                      }}
                      keyValueExtractor={(searchResult: { key: string; value: string }) => ({
                        key: searchResult.key,
                        value: searchResult.value,
                      })}
                      selectedItem={watchLanguages[index].level}
                      className={styles.languageLevel}
                    />
                    <RemoveItem
                      onClick={() => {
                        languageController.field.onChange(
                          watchLanguages.filter((_, idx) => idx !== index)
                        );
                      }}
                      className={styles.removeItem}
                    />
                  </div>
                </WithLightTitle>
              </div>
              <div className={styles.separator} />
            </div>
          );
        })}
      <AddButton
        icon={<PlusOpen />}
        text="ajouter une langue"
        onClick={() => {
          languageController.field.onChange([
            ...watchLanguages,
            {
              selectedItem: { id: '', level: undefined },
              uniqueKey: generateUniqueKey(watchLanguages),
            },
          ]);
        }}
        className={styles.addButton}
      />
    </div>
  );
};
export default SelectLanguage;
