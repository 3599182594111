import { Button, Modal, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { IllusRLVilles } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useState } from 'react';

import CriteriaCard from 'src/Components/CriteriaCard';
import { RADIUS } from 'src/Components/RadiusPicker/RadiusPicker.component';
import SelectLocations from 'src/Containers/Selects/SelectLocations';

import styles from './LocationModal.module.scss';
import { Props } from './LocationModal.types';

const LocationModal = ({ location, onClose, onLocationChange }: Props) => {
  const [selectedLocation, setSelectedLocation] = useState(location);

  return (
    <Modal
      icon={<IllusRLVilles />}
      onClose={onClose}
      nested
      open
      title="villes"
      footerActionsLeft={[
        <Button.Tertiary
          onClick={() => {
            setSelectedLocation(location);
          }}
        >
          tout réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <Button.Primary
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            onLocationChange(selectedLocation!);
            onClose();
          }}
        >
          valider
        </Button.Primary>,
      ]}
    >
      <SelectLocations
        selectedLocation={selectedLocation}
        setLocation={location => {
          location.radius = RADIUS.KM25;
          setSelectedLocation(location);
        }}
      />
      {selectedLocation && (
        <div className={styles.listContainer}>
          <WithLightTitle title={`ville ajoutée`}>
            <div className={styles.separator}></div>
            <CriteriaCard
              className={styles.criteriaCard}
              title={selectedLocation.label ?? ''}
              radius={selectedLocation.radius}
              onRadiusChange={radius => {
                const newLocation = {
                  label: selectedLocation.label,
                  zipCode: selectedLocation.zipCode,
                  radius: radius,
                };
                setSelectedLocation(newLocation);
              }}
              onDelete={() => setSelectedLocation(undefined)}
            />

            <div className={styles.separator}></div>
          </WithLightTitle>
        </div>
      )}
    </Modal>
  );
};

export default LocationModal;
