import {
  AutoSuggest,
  Badge,
  HorizontalTextIconCard,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Trashcan } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React from 'react';
import ContentLoader from 'react-content-loader';

import styles from './AutosuggestWithList.module.scss';
import { Props } from './AutosuggestWithList.types';

const AutosuggestWithList = <T extends { id: string; label: string }>({
  values,
  onChange,
  dataExtractor,
  title,
  isFetching,
  isSuccess,
  emptyText,
  singularTitle,
  isDisplayMode,
  cardContainerClassName,
  autosuggestTitle,
  results,
  onSearch,
  autosuggestFetchStatus,
  sectionTitle,
  disabled,
}: Props<T>) => {
  return (
    <>
      <div className={styles.title}>{sectionTitle}</div>
      {isFetching && (
        <ContentLoader height="5rem" width="100%" uniqueKey={title.replace(/ /g, '')}>
          <rect x="2%" y="10" rx="4" ry="4" width="80%" height="50" />
        </ContentLoader>
      )}
      {isSuccess && (
        <>
          {!isDisplayMode && (
            <WithLightTitle
              title={autosuggestTitle}
              className={classnames(cardContainerClassName, styles.autosuggestContainer)}
            >
              <AutoSuggest
                disabled={disabled}
                id={title.replace(/ /g, '')}
                value={values}
                keyValueExtractor={(searchResult: T) => ({
                  key: searchResult.id,
                  value: searchResult.label ?? '',
                })}
                onSearch={onSearch}
                onChange={items => {
                  onChange?.([...items]);
                }}
                searchResults={results}
                placeholder="1 caractère minimum"
                fetchStatus={autosuggestFetchStatus}
              />
            </WithLightTitle>
          )}
          {values.length ? (
            <WithLightTitle
              title={`${values.length} ${values.length > 1 ? title : singularTitle}`}
              className={classnames(styles.skillCardContainer, cardContainerClassName)}
            >
              {values.map(skill => {
                return (
                  <HorizontalTextIconCard
                    key={skill.id}
                    rightComponent={
                      dataExtractor(skill).level ? (
                        <Badge value={dataExtractor(skill).level?.toString() ?? ''} />
                      ) : undefined
                    }
                    leftIcon={dataExtractor(skill).icon}
                    label={dataExtractor(skill).label}
                    rightIcon={
                      !isDisplayMode && (
                        <Trashcan
                          onClick={() => onChange(values.filter(value => value.id !== skill.id))}
                        />
                      )
                    }
                    containerClassName={styles.skillCard}
                  />
                );
              })}
            </WithLightTitle>
          ) : (
            <div className={styles.noSkill}>{emptyText}</div>
          )}
        </>
      )}
    </>
  );
};

export default AutosuggestWithList;
