import { CandidateExperience } from 'src/Services/API';

export enum EXPERIENCE_TYPE {
  INTERNAL_EXPERIENCES = 'internal experiences',
  EXTERNAL_EXPERIENCES = 'external experiences',
}

export enum SORT_ORDER {
  ASCENDING = 'croissant',
  DESCENDING = 'decroissant',
}

export const backgroundMenuLabel = {
  [EXPERIENCE_TYPE.INTERNAL_EXPERIENCES]: 'chez randstad',
  [EXPERIENCE_TYPE.EXTERNAL_EXPERIENCES]: 'en externe',
};
export interface GroupedExperience {
  label: string;
  companyName: string;
  experiences: CandidateExperience[];
}

export enum SORT_OPTIONS {
  NAME = 'nom client',
  YEAR = 'ordre chronologique',
  HOURS = 'heures effectués',
}
