import {
  Button,
  Checkbox,
  ComboBox,
  Modal,
  PopupActions,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  ErrorMessage,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import React from 'react';
import ContentLoader from 'react-content-loader';
import { useController } from 'react-hook-form';

import { IllusJob } from 'src/Assets';
import { useFetchJobQualifications } from 'src/Hooks/Repositories/useFetchJobQualifications';
import { useFetchJobs } from 'src/Hooks/Repositories/useFetchJobs';

import styles from './JobModal.module.scss';
import { jobModalSchema, Props } from './JobModal.types';

const JobModal = ({ selectedJobs, onValidate, jobToModify, mainQualification, trigger }: Props) => {
  const innerRef = React.useRef<PopupActions>(null);
  const { control, handleSubmit, reset } = useFormWithZodResolver({
    schema: jobModalSchema(jobToModify === undefined),
    defaultValues: {
      currentJob: jobToModify ? { id: jobToModify?.id, label: jobToModify?.label } : undefined,
      currentQualifications: jobToModify?.qualifications ?? [],
      currentMainQualification: mainQualification,
    },
  });
  const jobController = useController({ control, name: 'currentJob' });
  const qualificationsController = useController({ control, name: 'currentQualifications' });
  const mainQualificationController = useController({
    control,
    name: 'currentMainQualification',
  });
  const jobsQuery = useFetchJobs();
  const jobQualificationsQuery = useFetchJobQualifications(jobController.field.value?.id);

  return (
    <Modal
      ref={innerRef}
      onClose={() => {
        reset();
      }}
      onOpen={() => {
        jobController.field.onChange(
          jobToModify ? { id: jobToModify?.id, label: jobToModify?.label } : undefined
        );
        qualificationsController.field.onChange(jobToModify?.qualifications ?? []);
        mainQualificationController.field.onChange(mainQualification);
      }}
      icon={<IllusJob className={styles.icon} />}
      trigger={trigger}
      title={jobToModify ? "modification d'un métier" : "ajout d'un métier"}
      footerActionsRight={[
        <Button.Secondary onClick={innerRef.current?.close}>annuler</Button.Secondary>,
        <Button.Primary
          onClick={handleSubmit(() => {
            const newJob = {
              id: jobController.field.value?.id ?? '',
              label: jobController.field.value?.label ?? '',
              qualifications: qualificationsController.field.value,
              contractCount: jobToModify?.contractCount ?? 0,
              companyCount: jobToModify?.companyCount ?? 0,
            };
            let jobs = [...selectedJobs];
            if (qualificationsController.field.value.length !== 0) {
              if (jobToModify !== undefined) {
                const jobsToModifyIdx = selectedJobs.findIndex(job => job.id === jobToModify.id);
                jobs.splice(jobsToModifyIdx, 1, newJob);
              } else {
                jobs = [...selectedJobs, newJob];
              }
            } else {
              jobs = selectedJobs.filter(job => job.id !== jobController.field.value?.id);
            }
            onValidate({
              jobs: jobs,
              job: newJob,
              mainQualification: mainQualificationController.field.value ?? '',
            });
            innerRef.current?.close();
          })}
        >
          valider
        </Button.Primary>,
      ]}
    >
      {jobToModify ? (
        <>
          <WithLightTitle title="nom du métier" className={styles.jobName}>
            <div>{jobToModify?.label}</div>
          </WithLightTitle>
          <WithLightTitle
            title="nombre de compte et de contrats pour ce métier"
            className={styles.companyAndContract}
          >
            <p>
              {jobToModify?.companyCount
                ? `${jobToModify.companyCount} comptes`
                : 'nombre de compte non renseigné pour ce métier'}
            </p>
            <p>
              {jobToModify?.contractCount
                ? `${jobToModify.contractCount} contrats`
                : 'nombre de contrat non renseigné pour ce métier'}
            </p>
          </WithLightTitle>
        </>
      ) : (
        <WithLightTitle title="choix du métier" className={styles.jobSearch}>
          <ComboBox
            id="jobs"
            value={jobController.field.value}
            minLengthToSearch={1}
            useNativeOptionFiltering
            keyValueExtractor={(job: { id?: string; label?: string }) => ({
              key: job.id ?? '',
              value: job.label ?? '',
            })}
            placeholder="1 caractère minimum"
            searchResults={jobsQuery.data ?? []}
            onChange={job => {
              if (job !== null) {
                jobController.field.onChange(job);
                const selectedJob = selectedJobs.find(jobItem => jobItem.id === job.id);
                if (selectedJob) {
                  qualificationsController.field.onChange(selectedJob.qualifications);
                } else {
                  qualificationsController.field.onChange([]);
                }
              }
            }}
          />
        </WithLightTitle>
      )}
      {jobQualificationsQuery.isLoading &&
        jobController.field.value &&
        new Array(5).fill(null).map((_, idx) => (
          <ContentLoader key={idx} height="3rem" width="100%" uniqueKey="qualification-loading">
            <rect x="2%" y="10" rx="4" ry="4" width="90%" height="24" />
          </ContentLoader>
        ))}
      {jobQualificationsQuery.isSuccess && (
        <div>
          <WithLightTitle
            title="choix des qualifications pour ce métier"
            rightTitleComponent={
              <div className={styles.mainQualificationTitle}>qualification pricipale</div>
            }
          >
            <div className={styles.selectAllCheckbox}>
              <Checkbox
                label="tout sélectionner"
                className={styles.checkbox}
                halfChecked={qualificationsController.field.value.length > 0}
                checked={
                  qualificationsController.field.value.length ===
                  jobQualificationsQuery.data?.length
                }
                onChange={() =>
                  qualificationsController.field.value.length > 0
                    ? qualificationsController.field.onChange(
                        jobQualificationsQuery.data.find(
                          qualification =>
                            qualification.id === mainQualificationController.field.value
                        )
                          ? [mainQualificationController.field.value ?? '']
                          : []
                      )
                    : qualificationsController.field.onChange(
                        jobQualificationsQuery.data?.map(item => item.id ?? '') ?? []
                      )
                }
              />
            </div>
            <div>
              {jobQualificationsQuery.data?.map(qualification => {
                console.log('la', qualification);
                const isChecked = qualificationsController.field.value?.includes(
                  qualification.id ?? ''
                );
                const isMainQualification =
                  qualification.id === mainQualificationController.field.value;
                return (
                  <div key={qualification.id} className={styles.checkbox}>
                    <Checkbox
                      key={qualification.id}
                      label={qualification.label}
                      checked={isChecked}
                      disabled={isMainQualification}
                      onChange={() =>
                        isChecked
                          ? qualificationsController.field.onChange(
                              qualificationsController.field.value?.filter(
                                qualificationItem => qualificationItem !== qualification.id
                              )
                            )
                          : qualificationsController.field.onChange([
                              ...qualificationsController.field.value,
                              qualification.id ?? '',
                            ])
                      }
                    />
                    <input
                      type="radio"
                      name={qualification.id}
                      disabled={
                        !qualificationsController.field.value.includes(qualification.id ?? '')
                      }
                      checked={isMainQualification}
                      id={qualification.id}
                      className={styles.button}
                      value={qualification.id}
                      onClick={() => mainQualificationController.field.onChange(qualification.id)}
                    />
                  </div>
                );
              })}
            </div>
          </WithLightTitle>
        </div>
      )}
      <ErrorMessage error={qualificationsController.fieldState.error} />
    </Modal>
  );
};
export default JobModal;
