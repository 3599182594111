import { Button, Modal } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

const InterviewValidationModal = (props: {
  open: boolean;
  isR1?: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal
      onClose={props.onClose}
      title={`Le formulaire ${props.isR1 ? 'R1' : 'R2'} a bien été validé.`}
      open={props.open}
      footerActionsRight={[<Button.Primary onClick={props.onClose}>valider</Button.Primary>]}
    >
      <div>
        {props.isR1
          ? 'Il sera transmis aux CGC concernés dans les plus brefs délais.'
          : 'La demande de passage du candidat en CDI a bien été enregistrée'}
      </div>
    </Modal>
  );
};

export default InterviewValidationModal;
