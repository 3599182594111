import { LetterBadge } from '@randstad-lean-mobile-factory/react-components-core';
import {
  CheckmarkCircleOutline,
  Clock,
  Contract,
  Group,
  Stylo,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import moment from 'moment';

import ActionsButton from 'src/Containers/CandidateResults/ActionsButton/ActionsButton.component';
import {
  CANDIDATE_STATUS_LABEL,
  getCandidateStatusStyle,
} from 'src/Containers/CandidateResults/CandidateResults.types';

import ScoringIndicator from '../ScoringIndicator';

import styles from './Card.module.scss';
import { Props } from './Card.types';

const Card = ({ candidate, canBeSelected, isSelected, onSelectionChange }: Props) => {
  const fullName = `${candidate.firstName} ${candidate.name}`;
  const qualification = candidate.mainQualification?.label ?? '';
  const location = `${candidate.zipCode} ${candidate.city}`;
  const contractEndDate = moment(candidate.availabilityDate).isAfter(moment())
    ? moment(candidate.availabilityDate)
    : undefined;

  return (
    <div
      className={classnames(styles.container, styles.containerUnselected, {
        [styles.containerSelected]: canBeSelected && isSelected,
      })}
      onClick={() => {
        if (canBeSelected) {
          onSelectionChange(!isSelected);
        }
      }}
    >
      <div
        className={classnames(
          styles.topContainer,
          getCandidateStatusStyle(candidate.candidateStatus)
        )}
      >
        <div>
          <LetterBadge
            text={fullName
              .split(/\s/)
              .map((word: string) => word[0])
              .join('')
              .substring(0, 2)}
            className={classnames({
              [styles.whiteBadge]:
                candidate.candidateStatus === CANDIDATE_STATUS_LABEL['CDI Interim'],
            })}
          />
          <div
            className={classnames(styles.fullName, {
              [styles.cdiFullName]:
                candidate.candidateStatus === CANDIDATE_STATUS_LABEL['CDI Interim'],
            })}
          >
            {fullName}
          </div>
          <div
            className={classnames(styles.qualification, {
              [styles.cdiQualification]:
                candidate.candidateStatus === CANDIDATE_STATUS_LABEL['CDI Interim'],
            })}
          >
            {qualification}
          </div>
          <div className={styles.location}>{location}</div>
        </div>
        <div className={styles.topContainerSelected}>
          {canBeSelected ? (
            isSelected ? (
              <CheckmarkCircleOutline className={styles.checkMark} />
            ) : (
              <div className={styles.checkMarkEmpty} />
            )
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.bottomLineContainer}>
          <div>
            <div className={styles.contractType}>
              {(() => {
                switch (candidate.candidateStatus) {
                  case CANDIDATE_STATUS_LABEL.Intérimaire:
                    return 'intérimaire';
                  case CANDIDATE_STATUS_LABEL['CDI Interim']:
                    return 'CDI intérimaire';
                  case CANDIDATE_STATUS_LABEL['Candidat évalué']:
                  case CANDIDATE_STATUS_LABEL['Candidat']:
                    return 'candidat';
                }
              })()}
            </div>

            <div className={styles.origin}>{`origine : ${candidate.origin}`}</div>
          </div>
          <div>
            {candidate.scoring !== 0 && candidate.scoring !== undefined && (
              <>
                <ScoringIndicator scoring={candidate.scoring} />
                <div className={styles.scoringSubtitle}>pertinence</div>
              </>
            )}
          </div>
        </div>
        <div className={styles.separator} />
        {contractEndDate && (
          <>
            <div className={styles.contractLineContainer}>
              <Contract />
              <div className={styles.contractEnd}>{`en contrat (fin: ${contractEndDate.format(
                'DD/MM/YYYY'
              )})`}</div>
            </div>
            <div className={styles.separator} />
          </>
        )}
        <div className={styles.bottomLineContainer}>
          <div className={styles.imageIndicator}>
            <Clock />
            <div className={styles.indicatorValue}>{candidate.nbHours}</div>
            <div className={styles.indicatorUnits}>hrs</div>
          </div>
          <div className={styles.imageIndicator}>
            <Group />
            <div className={styles.indicatorValue}>{candidate.nbCompany}</div>
            <div className={styles.indicatorUnits}>clients</div>
          </div>
          <div className={styles.imageIndicator}>
            <Stylo />
            <div className={styles.indicatorValue}>{candidate.nbMissions}</div>
            <div className={styles.indicatorUnits}>contrats</div>
          </div>
        </div>
        <div className={styles.separator} />
        <div style={{ flex: 1 }} />
        <ActionsButton
          candidateId={candidate.candidateId ?? ''}
          candidateName={fullName}
          candidateStatus={candidate.candidateStatus ?? ''}
          canBeSelected={canBeSelected}
        />
      </div>
    </div>
  );
};

export default Card;
