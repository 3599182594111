import {
  Button,
  FetchButton,
  Loader,
  Modal,
  toast,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { IllusRLVilles } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useEffect, useMemo } from 'react';
import { useController, useFormState } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import ComboBoxWithDisplayMode from 'src/Components/WithDisplayMode/ComboBoxWithDisplayMode';
import styles from 'src/Containers/Modals/InterviewsModals/R1ReassignCGCModal/R1ReassignCGCModal.module.scss';
import { useFetchCandidateR1FromURL } from 'src/Hooks/Candidates/useFetchCandidateR1FromURL';
import { useUpdateCandidateR1 } from 'src/Hooks/Candidates/useUpdateCandidateR1';
import { useFetchCGC } from 'src/Hooks/Repositories/useFetchCGC';
import { getPerimeterUnits } from 'src/Redux/Perimeter/Selectors';
import { FETCH_STATUS } from 'src/Redux/Types';
import { toFetchStatus } from 'src/Services/ReactQuery';

import { r1ReassignCGCModalSchema } from './R1ReassignCGCModal.schema';

const R1ReassignCGCModal = () => {
  const units = useSelector(getPerimeterUnits);
  const params = useParams<{
    id: string;
  }>();
  const candidateR1 = useFetchCandidateR1FromURL();
  const candidateR1FetchStatus = toFetchStatus(candidateR1);
  const cgcList = useFetchCGC();
  const cgcFetchStatus = toFetchStatus(cgcList);
  const candidateR1Update = useUpdateCandidateR1(params.id);
  const fetchStatus = toFetchStatus(candidateR1Update);
  const history = useHistory();

  const { control, handleSubmit, setValue } = useFormWithZodResolver({
    schema: r1ReassignCGCModalSchema,
    defaultValues: {
      cgc: cgcList.data?.find(cgc => cgc.id === candidateR1.data?.cgcId),
    },
  });

  const { isSubmitting } = useFormState({
    control: control,
  });

  const cgcController = useController({ name: 'cgc', control });

  const canModifyR1 = useMemo(
    () =>
      candidateR1.data &&
      (units.includes(candidateR1.data.agencyName) ||
        units.includes(candidateR1.data?.cgcId ?? '')),
    [candidateR1.data, units]
  );

  const contextHandleSubmit = () =>
    handleSubmit(values => {
      candidateR1.data &&
        canModifyR1 &&
        candidateR1Update.mutate({
          ...candidateR1.data,
          cgcId: values.cgc?.id,
        });
    });

  useEffect(() => {
    if (
      candidateR1FetchStatus === FETCH_STATUS.FULFILLED &&
      candidateR1.data &&
      cgcList.data &&
      cgcFetchStatus === FETCH_STATUS.FULFILLED
    ) {
      const candidateR1CGC = cgcList.data?.find(cgc => cgc.id === candidateR1.data?.cgcId);
      candidateR1.data.cgcId && setValue('cgc', candidateR1CGC);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateR1FetchStatus, candidateR1.isFetching, cgcFetchStatus, cgcList.isFetching]);

  return (
    <Modal
      title="réassigner un CGC"
      icon={<IllusRLVilles />}
      onClose={() => history.goBack()}
      open
      nested
      footerActionsRight={[
        <div className={styles.closeBtn}>
          <Button.Secondary onClick={() => history.goBack()}>annuler</Button.Secondary>
          {canModifyR1 && (
            <FetchButton
              title="valider"
              errorTitle="réessayer"
              fetchStatus={isSubmitting ? FETCH_STATUS.FULFILLED : fetchStatus}
              className={styles.button}
              onClick={contextHandleSubmit()}
              onSuccess={() => {
                toast.success('Le CGC a bien été modifié');
                history.goBack();
              }}
              onError={() => toast.error('Erreur lors du changement de CGC')}
            />
          )}
        </div>,
      ]}
    >
      <WithLightTitle title="CGC">
        {candidateR1.isLoading ? (
          <Loader heightInRem={1.5} />
        ) : (
          <ComboBoxWithDisplayMode
            id="cgc"
            disableClearable
            minLengthToSearch={0}
            fetchStatus={cgcFetchStatus}
            value={cgcController.field.value}
            searchResults={cgcList.data ?? []}
            useNativeOptionFiltering
            keyValueExtractor={cgc => ({
              key: cgc.id ?? '',
              value: [cgc.label, cgc.id].join(' - ') ?? '',
            })}
            onChange={cgc => cgcController.field.onChange(cgc)}
            placeholder="choisissez un nouveau CGC"
          />
        )}
      </WithLightTitle>
      {!canModifyR1 && (
        <div className={styles.error}>Vous n'avez pas les droits pour changer le cgc</div>
      )}
    </Modal>
  );
};

export default R1ReassignCGCModal;
