import {
  AddButton,
  DragDropList,
  FetchButton,
  HorizontalCardWithTitle,
  toast,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  DragIcon,
  PlusCircle,
  PlusOpen,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { TextInput, useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import ContentLoader from 'react-content-loader';
import { useController, useFieldArray, useFormState } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import CriteriaCard from 'src/Components/CriteriaCard';
import TextAreaWithDisplayMode from 'src/Components/WithDisplayMode/TextAreaWithDisplayMode';
import ExternalExperienceModal from 'src/Containers/Modals/ExternalExperienceModal';
import { useFetchCandidateExperiences } from 'src/Hooks/Candidates/useFetchCandidateExperiences';
import { useFetchCandidateR1FromURL } from 'src/Hooks/Candidates/useFetchCandidateR1FromURL';
import { useFetchR1ExperienceExchangeQuestions } from 'src/Hooks/Candidates/useFetchR1ExperienceExchangeQuestions';
import { useUpdateCandidateR1 } from 'src/Hooks/Candidates/useUpdateCandidateR1';
import { useUpdateExternalExperience } from 'src/Hooks/Candidates/useUpdateExternalExperience';
import { FormStatusContext } from 'src/Hooks/Navigation/useFormNavigationContextElements';
import { getPerimeterUnits } from 'src/Redux/Perimeter/Selectors';
import { FETCH_STATUS } from 'src/Redux/Types';
import {
  CandidateExperience,
  ContractTypeIdEnum,
  InterviewsExchangeQuestion,
} from 'src/Services/API';
import { mergeFetchStatus, toFetchStatus } from 'src/Services/ReactQuery';
import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './CandidateExperiences.module.scss';
import { candidateExperiencesSchema, currentNeedSchema } from './CandidateExperiences.types';
import ExperienceActionButton from './ExperienceActionButton/ExperienceActionButton.component';

const CandidateExperiences = () => {
  const units = useSelector(getPerimeterUnits);
  const params = useParams<{
    id: string;
    candidateId: string;
  }>();
  const {
    data: candidateExperiencesData,
    isLoading: isLoadingExperiences,
    isSuccess: isExperiencesSuccess,
  } = useFetchCandidateExperiences(params.candidateId);
  const {
    data: candidateR1Data,
    isLoading: isLoadingCandidateR1,
    isSuccess: isCandidateR1Success,
    isFetching: isFetchingCandidateR1,
  } = useFetchCandidateR1FromURL();
  const {
    data: questions,
    isLoading: isLoadingQuestions,
    isSuccess: isQuestionsSuccess,
    isFetching: isFetchingQuestions,
  } = useFetchR1ExperienceExchangeQuestions();
  const candidateR1Update = useUpdateCandidateR1(params.id);
  const candidateExperienceUpdate = useUpdateExternalExperience(params.candidateId);
  const fetchStatus = mergeFetchStatus(
    toFetchStatus(candidateR1Update),
    toFetchStatus(candidateExperienceUpdate)
  );

  const responses = questions?.map(question => {
    return {
      id: question.id,
      label: question.label,
      value:
        candidateR1Data?.professionalExperienceExchange?.experienceExchange?.responses.find(
          response => response.id === question.id
        )?.value ?? '',
    };
  });
  const emptyResponses = questions?.map(question => {
    return { id: question.id, label: question.label, value: '' };
  }) as InterviewsExchangeQuestion[];

  const isDisplayMode = useMemo(
    () =>
      candidateR1Data && (candidateR1Data.isClosed || !units.includes(candidateR1Data.agencyName)),
    [candidateR1Data, units]
  );

  const initializeExperienceValues = (experiences?: CandidateExperience[]) =>
    experiences?.map(experience => {
      return {
        startDate: new Date(experience.startDate ?? ''),
        endDate: new Date(experience.endDate ?? ''),
        qualification: experience.qualification,
        contractType: experience.contractType,
        companyName: experience.companyName,
        duration: experience.duration,
        isInternalExperience: experience.contractId ? true : false,
        companyId: experience.companyId,
        endReason: experience.endReason,
      };
    });

  const { control, setValue, handleSubmit, formState, reset } = useFormWithZodResolver({
    schema: candidateExperiencesSchema,
    defaultValues: {
      exchanges: {
        importantNeeds: candidateR1Data?.professionalExperienceExchange?.importantNeeds ?? [],
        experienceExchange: { responses } ?? { responses: emptyResponses },
      },
      experiences: initializeExperienceValues(
        isDisplayMode ? candidateR1Data?.experiences : candidateExperiencesData
      ),
    },
  });
  const importantNeedsController = useController({ control, name: 'exchanges.importantNeeds' });
  const experiencesController = useController({ control, name: 'experiences' });
  const {
    control: currentNeedControl,
    setValue: currentNeedSetValue,
    handleSubmit: currentNeedHandleSubmit,
  } = useFormWithZodResolver({
    schema: currentNeedSchema(importantNeedsController.field.value),
    defaultValues: {
      currentNeed: '',
    },
  });

  const { fields, update } = useFieldArray({
    control,
    name: 'exchanges.experienceExchange.responses',
    keyName: 'key',
    shouldUnregister: true,
  });

  const updateNeedsOrder = (result: DropResult) => {
    if (!result.destination) return;
    const items = Array.from(importantNeedsController.field.value);
    const [updatedResult] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, updatedResult);
    importantNeedsController.field.onChange(items);
  };

  const { isDirty, isSubmitting, isSubmitted, isSubmitSuccessful } = useFormState({
    control: control,
  });

  const currentNeedSubmit = useCallback(() => {
    currentNeedHandleSubmit(data => {
      importantNeedsController.field.onChange([
        ...importantNeedsController.field.value,
        data.currentNeed,
      ]);
      currentNeedSetValue('currentNeed', '');
    })();
  }, [currentNeedHandleSubmit, importantNeedsController.field, currentNeedSetValue]);

  const handleEnter = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        currentNeedSubmit();
      }
    },
    [currentNeedSubmit]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleEnter);
    return () => document.removeEventListener('keydown', handleEnter);
  }, [handleEnter]);

  const experiencesHandleSubmit = () =>
    handleSubmit(values => {
      candidateR1Data &&
        !isDisplayMode &&
        candidateR1Update.mutate({
          ...candidateR1Data,
          professionalExperienceExchange: values.exchanges,
        });

      candidateExperienceUpdate.mutate(
        values.experiences
          .filter(item => !item.isInternalExperience)
          .map(experience => {
            return {
              ...experience,
              contractTypeId:
                ContractTypeIdEnum[(experience.contractType?.label ?? '') as ContractTypeIdEnum],
              qualificationId: experience.qualification?.id ?? '',
              companyName: experience.companyName ?? '',
            };
          })
      );
      reset(values);
    });

  useEffect(() => {
    if (isCandidateR1Success && isQuestionsSuccess && isExperiencesSuccess) {
      setValue('exchanges.experienceExchange.responses', responses ?? emptyResponses);
      setValue(
        'exchanges.importantNeeds',
        candidateR1Data?.professionalExperienceExchange?.importantNeeds ?? []
      );
      setValue(
        'experiences',
        initializeExperienceValues(
          isDisplayMode ? candidateR1Data?.experiences : candidateExperiencesData
        ) ?? []
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCandidateR1Success, isQuestionsSuccess, isExperiencesSuccess, isDisplayMode]);

  const formStatusContext = useContext(FormStatusContext);
  useEffect(() => {
    formStatusContext.setHasFormBeenTouched(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
    formStatusContext.updateValidationStatus({
      handleSubmit: experiencesHandleSubmit,
      fetchStatus,
      formStateElements: { isSubmitSuccessful, isSubmitted, isSubmitting },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted, isSubmitting, isSubmitSuccessful, fetchStatus]);

  return (
    <>
      <div className={styles.title}>liste des expériences</div>
      {(isLoadingExperiences || isLoadingQuestions || isLoadingCandidateR1) &&
        new Array(3).fill(undefined).map((_, idx) => (
          <ContentLoader
            key={`${idx}loader`}
            height="5rem"
            width="100%"
            uniqueKey="experienceLoading"
          >
            <rect x="2%" y="10" rx="4" ry="4" width="80%" height="20" />
          </ContentLoader>
        ))}
      {isExperiencesSuccess && isQuestionsSuccess && isCandidateR1Success && (
        <>
          {experiencesController.field.value && experiencesController.field.value.length > 0 ? (
            experiencesController.field.value
              .filter(item => !item.duration || item.duration >= 3)
              .map((item, idx) => {
                return (
                  <HorizontalCardWithTitle
                    key={`${item.companyName}${
                      item.qualification?.id
                    }${item.startDate?.toString()}card`}
                    className={styles.experienceCard}
                    subtitles={[
                      `${item.qualification?.label} | ${item.contractType?.label} ${
                        item.duration ? ` | ${item.duration} mois` : ''
                      } `,
                      item.endReason?.label ?? '',
                    ]}
                    title={`${item.companyName} ${item.companyId ? 'via exp randstad' : ''}`}
                    rightActions={
                      item.companyId || isDisplayMode ? undefined : (
                        <ExperienceActionButton
                          experienceToModify={item}
                          onDelete={() => {
                            const experiencesCopy = experiencesController.field.value.slice();
                            experiencesCopy.splice(idx, 1);
                            experiencesController.field.onChange(experiencesCopy);
                          }}
                          onValidate={experienceItem => {
                            const experiencesCopy = experiencesController.field.value.slice();
                            experiencesCopy.splice(idx, 1, experienceItem);
                            experiencesController.field.onChange(experiencesCopy);
                          }}
                        />
                      )
                    }
                  />
                );
              })
          ) : (
            <p className={styles.noExperience}>
              Ce candidat ne possède actuellement aucune expérience
            </p>
          )}
          {!isDisplayMode && (
            <ExternalExperienceModal
              trigger={<AddButton icon={<PlusOpen />} text="ajouter une expérience" />}
              onValidate={experience =>
                experiencesController.field.onChange([
                  ...experiencesController.field.value,
                  experience,
                ])
              }
            />
          )}
        </>
      )}
      <div className={styles.separator}></div>
      <div className={styles.title}>support d'aide à la décision agence - R1</div>
      {(isLoadingCandidateR1 ||
        isLoadingExperiences ||
        isFetchingCandidateR1 ||
        isLoadingQuestions ||
        isFetchingQuestions ||
        !fields.length) && (
        <ContentLoader height="5rem" width="100%" uniqueKey="experience">
          <rect x="2%" y="10" rx="4" ry="4" width="80%" height="20" />
        </ContentLoader>
      )}
      {isCandidateR1Success &&
        isExperiencesSuccess &&
        !isFetchingCandidateR1 &&
        isQuestionsSuccess &&
        !isFetchingQuestions &&
        !!fields.length && (
          <>
            {fields.map((field, index) => (
              <WithLightTitle
                key={field.id}
                title={field.label}
                className={styles.container}
                titleClassName={styles.titleContainer}
                rightTitleComponent={
                  !candidateR1Data?.isClosed && (
                    <div className={styles.textLength}>
                      {pluralFormat(field.value?.length ?? 0, 'caractère')}
                    </div>
                  )
                }
              >
                <TextAreaWithDisplayMode
                  isDisplayMode={isDisplayMode}
                  title={field.label}
                  className={styles.textAreaWidth}
                  placeholder={
                    questions?.find(question => question.label === field.label)?.placeHolder
                  }
                  value={field.value}
                  onChange={event => {
                    update(index, {
                      id: field.id,
                      label: field.label,
                      value: (event.target as HTMLTextAreaElement).value,
                    });
                  }}
                />
              </WithLightTitle>
            ))}
            {!isDisplayMode && (
              <WithLightTitle
                title="Liste de vos besoins à classer par ordre d'importance (10 maximum)"
                className={styles.textInput}
              >
                <TextInput
                  rightIcon={
                    <div onClick={currentNeedSubmit}>
                      <PlusCircle />
                    </div>
                  }
                  name={'currentNeed'}
                  placeholder={`ajouter des besoins séparés d'une virgule`}
                  control={currentNeedControl}
                />
              </WithLightTitle>
            )}
            {importantNeedsController.field.value.length > 0 && (
              <WithLightTitle
                title={`${pluralFormat(
                  importantNeedsController.field.value.length,
                  'besoin ajouté'
                )}${
                  isDisplayMode ? '' : ", glisser les besoins pour les trier par ordre d'importance"
                }`}
                className={styles.needs}
              >
                {isDisplayMode ? (
                  importantNeedsController.field.value.length > 0 ? (
                    importantNeedsController.field.value.map((need, idx) => {
                      return (
                        <CriteriaCard
                          className={styles.criteriaCard}
                          title={`${idx + 1}. ${need}`}
                        />
                      );
                    })
                  ) : (
                    "champ non rempli lors de l'entretien"
                  )
                ) : (
                  <DragDropList
                    droppableId="importantNeeds"
                    onDragEnd={updateNeedsOrder}
                    items={importantNeedsController.field.value.map((need, idx) => {
                      return {
                        id: need,
                        item: (
                          <>
                            <CriteriaCard
                              icon={<DragIcon className={styles.dragIcon} />}
                              className={styles.criteriaCard}
                              title={`${idx + 1}. ${need}`}
                              onDelete={() => {
                                importantNeedsController.field.onChange(
                                  importantNeedsController.field.value.filter(
                                    needItem => need !== needItem
                                  )
                                );
                              }}
                            />
                          </>
                        ),
                      };
                    })}
                  />
                )}
              </WithLightTitle>
            )}
          </>
        )}
      {!isDisplayMode && (
        <FetchButton
          secondary
          title="valider les résumés et échanges"
          errorTitle="réessayer"
          fetchStatus={isSubmitting ? FETCH_STATUS.FULFILLED : fetchStatus}
          className={styles.button}
          onClick={experiencesHandleSubmit()}
          onSuccess={() => toast.success('Votre R1 a été modifié')}
          onError={() => toast.error("Erreur lors de l'enregistrement de votre R1")}
        />
      )}
      {Object.keys(formState.errors).length !== 0 && (
        <div className={styles.errorMessage}>
          il y a des erreurs ou des champs non remplis dans le formulaire
        </div>
      )}
    </>
  );
};

export default CandidateExperiences;
