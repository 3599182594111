import {
  Checkbox,
  HorizontalCardWithTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import classnames from 'classnames';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useController } from 'react-hook-form';

import AddBar from 'src/Components/AddBar/AddBar.component';
import { ErrorMessage } from 'src/Components/ErrorMessage';
import { useFetchCandidateBusinessesFromURL } from 'src/Hooks/Candidates/useFetchCandidateBusinessesFromURL';
import { FETCH_STATUS } from 'src/Redux/Types';
import { BusinessSkills } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/ReactQuery';

import { CandidateResumeContext } from '../CandidateResumeContext';

import styles from './BusinessesSkills.module.scss';
import { businessesSkillsSchema } from './BusinessesSkills.schema';

const BusinessesSkills = () => {
  const candidateResumeContext = useContext(CandidateResumeContext);
  candidateResumeContext.setIsNextEnabled(true);
  const initialValues = candidateResumeContext.skills;

  const [businessesQueryInitialized, setBusinessesQueryInitialized] = useState(false);
  const businessesQuery = useFetchCandidateBusinessesFromURL();
  const candidateResumeFetchStatus = toFetchStatus(businessesQuery);

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useFormWithZodResolver({
    schema: businessesSkillsSchema,
    defaultValues: {
      businessesSkills: initialValues?.businessesSkills,
      behaviourSkills: initialValues?.behaviourSkills,
    },
  });

  const businessesSkills = watch('businessesSkills') ?? [];
  const behaviourSkills = watch('behaviourSkills') ?? [];

  const businessesSkillsController = useController({
    name: 'businessesSkills',
    control,
  });

  const behaviourSkillsController = useController({
    name: 'behaviourSkills',
    control,
  });

  const SkillsHandleSubmit = useCallback(
    () =>
      handleSubmit(values => {
        candidateResumeContext.setSkills({
          businessesSkills: values.businessesSkills ?? [],
          behaviourSkills: values.behaviourSkills ?? [],
        });
        reset(values);
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleSubmit, reset]
  );

  const submit = SkillsHandleSubmit();

  useEffect(() => {
    if (!businessesQueryInitialized && businessesQuery.isSuccess) {
      setBusinessesQueryInitialized(true);
    }
  }, [businessesQuery, businessesQueryInitialized]);

  useEffect(() => {
    if (isDirty) {
      submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessesSkills, behaviourSkills]);

  const addOrRemoveBusiness = (arr: BusinessSkills[], item: BusinessSkills) => {
    const filtered = arr.filter(el => el.id !== item.id);
    if (filtered.length === arr.length || arr.length === 0) {
      return [...arr, item];
    }
    return filtered;
  };

  const previousQualificationsIds = candidateResumeContext.qualifications.qualifications.map(
    qualif => qualif.qualificationId
  );

  const all_businessesSkills: BusinessSkills[] =
    businessesQuery.data === undefined
      ? []
      : businessesQuery.data
          .filter(business =>
            business.candidateQualifications?.some(qualif =>
              previousQualificationsIds.includes(qualif.qualificationId ?? '')
            )
          )
          .flatMap(business => business.candidateBusinessesSkills)
          .filter((s): s is BusinessSkills => s !== undefined);

  const initialState = all_businessesSkills.map(skill =>
    businessesSkills?.map(s => s.id).includes(skill.id ?? '')
  );
  const [selectedSkills, setSelectedSkills] = useState<boolean[]>(initialState ?? []);
  const [selectedBehaviourSkills, setBehaviourSkills] = useState<string[]>(behaviourSkills);
  useEffect(() => {
    behaviourSkillsController.field.onChange(selectedBehaviourSkills);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBehaviourSkills]);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <p>sélectionnez les compétences à mettre en avant</p>
      </div>
      {candidateResumeFetchStatus === FETCH_STATUS.PENDING &&
        new Array(5).fill(null).map((_, idx) => (
          <ContentLoader key={'load_' + idx} height="3rem" width="100%" uniqueKey="diploma">
            <rect x="2%" y="20" rx="4" ry="4" width="100%" height="20" />
          </ContentLoader>
        ))}
      {candidateResumeFetchStatus === FETCH_STATUS.REJECTED && (
        <ErrorMessage message="Une erreur est survenue dans la récupération des compétences" />
      )}
      {candidateResumeFetchStatus === FETCH_STATUS.FULFILLED && (
        <div className={styles.gridContainer}>
          {all_businessesSkills.map((skill, index) => {
            const label = skill.label ?? '';
            return (
              <HorizontalCardWithTitle
                key={index}
                title={label.charAt(0).toUpperCase() + label.slice(1).toLowerCase()}
                titleClassname={styles.title}
                className={classnames(
                  styles.card,
                  selectedSkills[index] ? styles.selected : styles.idle
                )}
                leftActions={
                  <Checkbox
                    checked={selectedSkills[index]}
                    onChange={() => {
                      const newSelectedSkills = [...selectedSkills];
                      newSelectedSkills[index] = !selectedSkills[index];
                      setSelectedSkills(newSelectedSkills);

                      const newSkills = addOrRemoveBusiness(
                        businessesSkillsController.field.value ?? [],
                        skill
                      );
                      businessesSkillsController.field.onChange(newSkills);
                    }}
                  />
                }
              />
            );
          })}
        </div>
      )}
      <AddBar items={selectedBehaviourSkills} setItems={setBehaviourSkills} />
    </div>
  );
};

export default BusinessesSkills;
