import { Modal, Button, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { IllusRLDiploma } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useState } from 'react';

import CriteriaCard from 'src/Components/CriteriaCard';
import SelectDiplomas from 'src/Containers/Selects/SelectDiplomas';

import styles from './DiplomaModal.module.scss';
import { Props } from './DiplomaModal.types';

const DiplomaModal = ({ diplomas, onDiplomasChange, onClose }: Props) => {
  const [selectedDiplomas, setSelectedDiplomas] = useState(diplomas);

  return (
    <Modal
      icon={<IllusRLDiploma />}
      nested
      onClose={onClose}
      title="diplômes"
      open
      footerActionsLeft={[
        <Button.Tertiary
          onClick={() => {
            setSelectedDiplomas(diplomas);
          }}
        >
          tout réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <Button.Primary
          onClick={() => {
            onDiplomasChange(selectedDiplomas);
            onClose();
          }}
        >
          valider
        </Button.Primary>,
      ]}
    >
      <SelectDiplomas selectedDiplomas={selectedDiplomas} setDiplomas={setSelectedDiplomas} />
      {selectedDiplomas.length > 0 && (
        <div className={styles.listContainer}>
          <WithLightTitle title={`${selectedDiplomas.length} diplôme(s) ajoutée(s)`}>
            {selectedDiplomas.map((diploma, idx) => (
              <React.Fragment key={diploma.identifier}>
                <div className={styles.separator}></div>
                <CriteriaCard
                  className={styles.criteriaCard}
                  title={diploma.label ?? ''}
                  weight={diploma.wishPriority}
                  onWeightChange={weight => {
                    const newDiploma = {
                      label: diploma.label,
                      identifier: diploma.identifier,
                      wishPriority: weight,
                    };
                    // eslint-disable-next-line prefer-const
                    const newDiplomas = selectedDiplomas.slice();
                    newDiplomas[idx] = newDiploma;
                    setSelectedDiplomas(newDiplomas);
                  }}
                  onDelete={() => {
                    const diplomaTable = selectedDiplomas.filter(
                      otherDiploma => otherDiploma.identifier !== diploma.identifier
                    );
                    setSelectedDiplomas(diplomaTable);
                  }}
                />
              </React.Fragment>
            ))}
            <div className={styles.separator}></div>
          </WithLightTitle>
        </div>
      )}
    </Modal>
  );
};

export default DiplomaModal;
