import { Badge } from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import React from 'react';

import BadgePicker from 'src/Components/BadgePicker';
import { R1Priority } from 'src/Services/API';

import styles from './StatusPicker.module.scss';
import { Props } from './StatusPicker.types';

const StatusPicker = ({ selectedPriority, onChange, isDisplayMode }: Props) => {
  const items = [
    {
      key: R1Priority.Favorable,
      value: 'souhaité',
      className: styles.positive,
    },
    {
      key: R1Priority.Defavorable,
      value: 'non souhaité',
      className: styles.negative,
    },
    {
      key: R1Priority.Neutre,
      value: 'sans avis',
      className: styles.neutral,
    },
  ];
  return (
    <>
      {isDisplayMode ? (
        <Badge
          value={selectedPriority}
          className={classnames(
            styles.badge,
            items.find(item => item.key === selectedPriority)?.className
          )}
        />
      ) : (
        <BadgePicker
          selectedItem={selectedPriority}
          items={items}
          onChange={key => onChange(key as R1Priority)}
        />
      )}
    </>
  );
};

export default StatusPicker;
