import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getCanSearch, getSearchCriteria, getSearchMode } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';
import { WishPriority } from 'src/Services/API';

import { SearchSummary } from './SearchSummary.component';
import { OwnProps } from './SearchSummary.types';

const MemoSearchSummary = React.memo(SearchSummary);

const EnhancedSearchSummary = (ownProps: OwnProps) => {
  const dispatch = useDispatch();
  const searchMode = useSelector(getSearchMode);
  const criteria = useSelector(getSearchCriteria);
  const reset = useCallback(() => {
    dispatch(searchActions.reset());
  }, [dispatch]);
  const resetCandidateStatuses = useCallback(() => {
    dispatch(searchActions.resetCandidateStatuses());
  }, [dispatch]);
  const resetWorkingHoursFilter = useCallback(() => {
    dispatch(searchActions.resetWorkingHoursFilter());
  }, [dispatch]);
  const resetAvailability = useCallback(() => {
    dispatch(searchActions.resetAvailability());
  }, [dispatch]);
  const removeQualifications = useCallback(
    (id: string) => {
      dispatch(searchActions.removeQualification(id));
    },
    [dispatch]
  );
  const onSkillWeightUpdate = useCallback(
    (id: string, weight: WishPriority) => {
      dispatch(searchActions.updateSkillWeight({ id, weight }));
    },
    [dispatch]
  );
  const removeSkill = useCallback(
    (id: string) => {
      dispatch(searchActions.removeSkill(id));
    },
    [dispatch]
  );
  const removeLocation = useCallback(() => {
    dispatch(searchActions.removeLocation());
  }, [dispatch]);
  const onQualificationWeightUpdate = useCallback(
    (id: string, weight: WishPriority) => {
      dispatch(searchActions.updateQualificationWeight({ id, weight }));
    },
    [dispatch]
  );
  const onLocationRadiusUpdate = useCallback(
    (radius: number) => {
      dispatch(searchActions.updateLocationRadius(radius));
    },
    [dispatch]
  );
  const removeDiploma = useCallback(
    (id: string) => {
      dispatch(searchActions.removeDiploma(id));
    },
    [dispatch]
  );
  const onDiplomaWeightUpdate = useCallback(
    (id: string, weight: WishPriority) => {
      dispatch(searchActions.updateDiplomaWeight({ id, weight }));
    },
    [dispatch]
  );
  const removeDrivingLicense = useCallback(
    (id: string) => {
      dispatch(searchActions.removeDrivingLicense(id));
    },
    [dispatch]
  );
  const onDrivingLicenseWeightUpdate = useCallback(
    (id: string, weight: WishPriority) => {
      dispatch(searchActions.updateDrivingLicenseWeight({ id, weight }));
    },
    [dispatch]
  );
  const removeCompany = useCallback(() => {
    dispatch(searchActions.removeCompany());
  }, [dispatch]);
  const onCompanyWeightUpdate = useCallback(
    (id: string, weight: WishPriority) => {
      dispatch(searchActions.updateCompanyWeight(weight));
    },
    [dispatch]
  );
  const removeService = useCallback(() => {
    dispatch(searchActions.removeService());
  }, [dispatch]);
  const onServiceWeightUpdate = useCallback(
    (id: string, weight: WishPriority) => {
      dispatch(searchActions.updateServiceWeight(weight));
    },
    [dispatch]
  );
  const removePotentialPosition = useCallback(
    (id: string) => {
      dispatch(searchActions.removePotentialPosition(id));
    },
    [dispatch]
  );
  const removeLanguage = useCallback(
    (id: string) => {
      dispatch(searchActions.removeLanguage(id));
    },
    [dispatch]
  );
  const onLanguageWeightUpdate = useCallback(
    (id: string, weight: WishPriority) => {
      dispatch(searchActions.updateLanguageWeight({ id, weight }));
    },
    [dispatch]
  );
  const removeHabilitation = useCallback(
    (id: string) => {
      dispatch(searchActions.removeHabilitation(id));
    },
    [dispatch]
  );
  const onHabilitationWeightUpdate = useCallback(
    (id: string, weight: WishPriority) => {
      dispatch(searchActions.updateHabilitationWeight({ id, weight }));
    },
    [dispatch]
  );
  const removeCaces = useCallback(
    (id: string) => {
      dispatch(searchActions.removeCaces(id));
    },
    [dispatch]
  );
  const onCacesWeightUpdate = useCallback(
    (id: string, weight: WishPriority) => {
      dispatch(searchActions.updateCacesWeight({ id, weight }));
    },
    [dispatch]
  );
  const removeKeyword = useCallback(
    (id: string) => {
      dispatch(searchActions.removeKeyword(id));
    },
    [dispatch]
  );
  const onKeywordWeightUpdate = useCallback(
    (id: string, weight: WishPriority) => {
      dispatch(searchActions.updateKeywordWeight({ id, weight }));
    },
    [dispatch]
  );
  const history = useHistory();
  const canSearch = useSelector(getCanSearch);
  return (
    <MemoSearchSummary
      onSearch={() => {
        history.push('/results');
      }}
      canSearch={canSearch}
      searchMode={searchMode}
      criteria={criteria}
      reset={reset}
      onAvailabilityReset={resetAvailability}
      onQualificationDelete={removeQualifications}
      onSkillWeightUpdate={onSkillWeightUpdate}
      onSkillDelete={removeSkill}
      onLocationDelete={removeLocation}
      onQualificationWeightUpdate={onQualificationWeightUpdate}
      onLocationRadiusUpdate={onLocationRadiusUpdate}
      onDiplomaDelete={removeDiploma}
      onDiplomaWeightUpdate={onDiplomaWeightUpdate}
      onDrivingLicenseDelete={removeDrivingLicense}
      onDrivingLicenseWeightUpdate={onDrivingLicenseWeightUpdate}
      onCompanyDelete={removeCompany}
      onCompanyWeightUpdate={onCompanyWeightUpdate}
      onServiceDelete={removeService}
      onServiceWeightUpdate={onServiceWeightUpdate}
      onPotentialPositionDelete={removePotentialPosition}
      onLanguageDelete={removeLanguage}
      onLanguageWeightUpdate={onLanguageWeightUpdate}
      onHabilitationDelete={removeHabilitation}
      onHabilitationWeightUpdate={onHabilitationWeightUpdate}
      onCacesDelete={removeCaces}
      onCacesWeightUpdate={onCacesWeightUpdate}
      onKeywordDelete={removeKeyword}
      onKeywordWeightUpdate={onKeywordWeightUpdate}
      resetCandidateStatuses={resetCandidateStatuses}
      resetWorkingHoursFilter={resetWorkingHoursFilter}
      {...ownProps}
    />
  );
};

export default EnhancedSearchSummary;
