import { Badge, Button, PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import { PinOutline } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useDispatch, useSelector } from 'react-redux';

import { useFetchConsultantsEstablishments } from 'src/Hooks/Perimeter/useFetchConsultantsEstablishments';
import { getStatsSelectedAgencies } from 'src/Redux/InterviewsStats/Selector';
import { interviewsStatsActions } from 'src/Redux/InterviewsStats/Slice';
import { FETCH_STATUS } from 'src/Redux/Types';
import { toFetchStatus } from 'src/Services/ReactQuery';

import RegionCheckbox from './RegionCheckbox';
import styles from './ZoneSelector.module.scss';

const ZoneSelector = () => {
  const consultantEstablishmentsQuery = useFetchConsultantsEstablishments();
  const consultantEstablishmentsFetchStatus = toFetchStatus(consultantEstablishmentsQuery);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const selectedAgencies = useSelector(getStatsSelectedAgencies) ?? [];
  const [localSelectedAgencies, setLocalSelectedAgencies] = useState(selectedAgencies);
  const selectedRegionCount = consultantEstablishmentsQuery.data?.regions?.filter(region => {
    const regionSelectedAgencies = region.zones
      ?.flatMap(zone => zone.agencies?.map(agency => agency.agencyId ?? ''))
      .filter(agency => localSelectedAgencies.includes(agency ?? ''));
    return regionSelectedAgencies !== undefined && regionSelectedAgencies.length > 0;
  }).length;
  const reset = () => {
    setLocalSelectedAgencies(selectedAgencies);
  };
  return (
    <div className={styles.container}>
      {consultantEstablishmentsFetchStatus === FETCH_STATUS.PENDING && (
        <ContentLoader height="2.5rem" width="9rem" uniqueKey="regions">
          <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
        </ContentLoader>
      )}
      {consultantEstablishmentsFetchStatus === FETCH_STATUS.FULFILLED && (
        <PopupMenu
          open={open}
          position="bottom right"
          width={346}
          onClose={() => {
            dispatch(interviewsStatsActions.setStatsSelectedAgencies(localSelectedAgencies));
            setOpen(false);
          }}
          onOpen={() => {
            setOpen(true);
          }}
          trigger={
            <Button.Secondary
              text="région"
              className={classnames(styles.filterButton, { [styles.openPopup]: open })}
              leftIcon={<PinOutline />}
              rightIcon={
                <Badge
                  value={selectedRegionCount?.toString() ?? '0'}
                  className={classnames(styles.dot, { [styles.openPopupDot]: open })}
                />
              }
            />
          }
        >
          <div className={styles.popupContentContainer}>
            <div className={styles.titleContainer}>
              <div className={styles.sideBarTitle}>sélectionnez vos régions</div>
              <Button.Tertiary onClick={reset} className={styles.resetButton}>
                réinitialiser
              </Button.Tertiary>
            </div>
            {consultantEstablishmentsQuery.data?.regions?.map(region => (
              <RegionCheckbox
                region={region}
                localSelectedAgencies={localSelectedAgencies}
                setLocalSelectedAgencies={setLocalSelectedAgencies}
                key={`${region.regionId} - ${region.regionTitle}`}
              />
            ))}
          </div>
        </PopupMenu>
      )}
    </div>
  );
};

export default ZoneSelector;
