import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from 'src/Hooks/types';
import { CandidatesService } from 'src/Services/API';

export const useFetchQualificationSkillDetails = (id: string, qualificationId: string) => {
  return useQuery([QueryKeys.fetchQualificationSkillDetails, id], async () => {
    return await CandidatesService.candidatesControllerGetCandidateSkilldetails({
      id,
      body: { qualificationId },
    });
  });
};
