import { ComboBox, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import throttle from 'lodash.throttle';
import React, { useCallback, useState } from 'react';

import { useFetchLocations } from 'src/Hooks/Locations';
import { MatchingCriteriaLocationWithLabel } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/ReactQuery';

import { Props } from './SelectLocations.types';

const SelectLocations = ({ selectedLocation, setLocation }: Props) => {
  const [keyword, setKeyword] = useState('');
  const mutation = useFetchLocations();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledMethod = useCallback(throttle(mutation.mutate, 500), [mutation.mutate]);
  return (
    <WithLightTitle
      title={`${keyword !== '' && mutation.data ? mutation.data.length : '-'} villes disponibles ${
        selectedLocation?.label
      }`}
    >
      <ComboBox
        id="locations"
        value={selectedLocation}
        keyValueExtractor={(searchResult: MatchingCriteriaLocationWithLabel) => ({
          key: searchResult.zipCode,
          value: searchResult.label ?? '',
        })}
        onSearch={(word: string) => {
          setKeyword(word);
          word !== '' && throttledMethod(word);
        }}
        onChange={location => {
          if (location !== null) {
            setLocation(location);
          }
          setKeyword('');
        }}
        searchResults={keyword !== '' ? mutation.data ?? [] : []}
        placeholder="1 caractère minimum"
        minLengthToSearch={1}
        fetchStatus={toFetchStatus(mutation)}
      />
    </WithLightTitle>
  );
};

export default SelectLocations;
