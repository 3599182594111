import {
  FetchButton,
  toast,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  ErrorMessage,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import moment from 'moment';
import React, { useContext, useEffect, useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import { useController, useFormState } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import ComboBoxWithDisplayMode from 'src/Components/WithDisplayMode/ComboBoxWithDisplayMode';
import DatePickerInputWithDisplayMode from 'src/Components/WithDisplayMode/DatePickerInputWithDisplayMode';
import { useFetchCandidateLastContract } from 'src/Hooks/Candidates/useFetchCandidateLastContract';
import { useFetchCandidateR1FromURL } from 'src/Hooks/Candidates/useFetchCandidateR1FromURL';
import { useUpdateCandidateR1 } from 'src/Hooks/Candidates/useUpdateCandidateR1';
import { FormStatusContext } from 'src/Hooks/Navigation/useFormNavigationContextElements';
import { useFetchConsultantInfo } from 'src/Hooks/Perimeter/useFetchConsultantInfo';
import { useFetchCGC } from 'src/Hooks/Repositories/useFetchCGC';
import { getPerimeter, getPerimeterUnits } from 'src/Redux/Perimeter/Selectors';
import { FETCH_STATUS } from 'src/Redux/Types';
import { toFetchStatus } from 'src/Services/ReactQuery';

import styles from './ContextInformations.module.scss';
import { contextInformationSchema } from './ContextInformations.types';

const ContextInformations = () => {
  const params = useParams<{
    id: string;
  }>();
  const units = useSelector(getPerimeterUnits);
  const consultantPerimeter = useSelector(getPerimeter);
  const candidateR1 = useFetchCandidateR1FromURL();
  const candidateR1FetchStatus = toFetchStatus(candidateR1);
  const candidateR1Update = useUpdateCandidateR1(params.id);
  const fetchStatus = toFetchStatus(candidateR1Update);
  const consultantInfo = useFetchConsultantInfo(candidateR1.data?.consultantId ?? '');
  const { data: candidateContract } = useFetchCandidateLastContract(
    candidateR1.data?.candidateId ?? ''
  );
  const cgcList = useFetchCGC();
  const cgcFetchStatus = toFetchStatus(cgcList);
  const { control, handleSubmit, setValue, formState, reset } = useFormWithZodResolver({
    schema: contextInformationSchema,
    defaultValues: {
      interviewDate: candidateR1.data?.interviewDate
        ? new Date(candidateR1.data?.interviewDate)
        : new Date(),
      hiringDate: candidateR1.data?.hiringDate,
      agencyName: candidateR1.data?.agencyName ?? consultantPerimeter?.defaultAgencyId,
      cgc: cgcList.data?.find(cgc => cgc.id === candidateR1.data?.cgcId),
    },
  });
  const interviewDateController = useController({ name: 'interviewDate', control });
  const hiringDateController = useController({ name: 'hiringDate', control });
  const agencyNameController = useController({ name: 'agencyName', control });
  const cgcController = useController({ name: 'cgc', control });
  const isCandidateR1Pending =
    candidateR1FetchStatus === FETCH_STATUS.PENDING || candidateR1.isFetching;
  const isCGCPending = cgcFetchStatus === FETCH_STATUS.PENDING || cgcList.isFetching;
  const isCandidateR1Fulfilled =
    candidateR1FetchStatus === FETCH_STATUS.FULFILLED && !candidateR1.isFetching;
  const isCGCFullfilled = cgcFetchStatus === FETCH_STATUS.FULFILLED && !cgcList.isFetching;

  const isDisplayMode = useMemo(
    () =>
      candidateR1.data &&
      (candidateR1.data.isClosed || !units.includes(candidateR1.data.agencyName)),
    [candidateR1.data, units]
  );

  const contextHandleSubmit = () =>
    handleSubmit(values => {
      candidateR1.data &&
        !isDisplayMode &&
        candidateR1Update.mutate({
          ...candidateR1.data,
          hiringDate: values.hiringDate,
          interviewDate: values.interviewDate,
          agencyName: values.agencyName,
          cgcId: values.cgc?.id,
        });
      reset(values);
    });

  useEffect(() => {
    if (
      candidateR1FetchStatus === FETCH_STATUS.FULFILLED &&
      candidateR1.data &&
      cgcList.data &&
      cgcFetchStatus === FETCH_STATUS.FULFILLED
    ) {
      const candidateR1CGC = cgcList.data?.find(cgc => cgc.id === candidateR1.data?.cgcId);
      candidateR1.data.hiringDate && setValue('hiringDate', new Date(candidateR1.data.hiringDate));
      candidateR1.data.cgcId && setValue('cgc', candidateR1CGC);
      candidateR1.data.interviewDate &&
        setValue('interviewDate', new Date(candidateR1.data.interviewDate));
      setValue('agencyName', candidateR1.data.agencyName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    candidateR1FetchStatus,
    candidateR1.isFetching,
    consultantPerimeter?.defaultAgencyId,
    cgcFetchStatus,
    cgcList.isFetching,
  ]);

  const { isDirty, isSubmitting, isSubmitted, isSubmitSuccessful } = useFormState({
    control: control,
  });
  const formStatusContext = useContext(FormStatusContext);
  useEffect(() => {
    formStatusContext.setHasFormBeenTouched(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
    formStatusContext.updateValidationStatus({
      handleSubmit: contextHandleSubmit,
      fetchStatus,
      formStateElements: { isSubmitSuccessful, isSubmitted, isSubmitting },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted, isSubmitting, isSubmitSuccessful, fetchStatus]);

  return (
    <>
      <div className={styles.title}>dates</div>
      {(isCandidateR1Pending || isCGCPending) && (
        <ContentLoader height="5rem" width="100%" uniqueKey="dates">
          <rect x="2%" y="10" rx="4" ry="4" width="80%" height="50" />
        </ContentLoader>
      )}
      {isCandidateR1Fulfilled && isCGCFullfilled && (
        <div className={styles.dateContainer}>
          <WithLightTitle title="date entretien" className={styles.datePickerInputContainer}>
            <DatePickerInputWithDisplayMode
              date={interviewDateController.field.value}
              onSelectDate={interviewDateController.field.onChange}
              isDisplayMode={isDisplayMode}
            />
          </WithLightTitle>
          <div>
            <WithLightTitle
              title="date prévisionnelle embauche CDI"
              className={styles.datePickerInputContainer}
            >
              <DatePickerInputWithDisplayMode
                date={hiringDateController.field.value}
                onSelectDate={hiringDateController.field.onChange}
                isDisplayMode={isDisplayMode}
                minDate={
                  new Date(
                    Math.max(
                      interviewDateController.field.value?.getTime() ?? 0,
                      moment().startOf('day').valueOf()
                    )
                  )
                }
              />
            </WithLightTitle>
          </div>
        </div>
      )}
      <div className={styles.separator} />
      <div className={styles.sectionContainer}>
        <div className={styles.title}>autre</div>
        {isCandidateR1Fulfilled && consultantPerimeter && (
          <>
            <WithLightTitle title="nom de l'agence">
              <ComboBoxWithDisplayMode
                id="agencies"
                disableClearable
                isDisplayMode={isDisplayMode}
                minLengthToSearch={0}
                fetchStatus={FETCH_STATUS.FULFILLED}
                value={agencyNameController.field.value}
                searchResults={units}
                useNativeOptionFiltering
                keyValueExtractor={(agency: string) => ({
                  key: agency,
                  value: agency ?? '',
                })}
                onChange={agency => agencyNameController.field.onChange(agency)}
                placeholder="choisissez une agence"
              />
            </WithLightTitle>
            <ErrorMessage error={agencyNameController.fieldState.error} />
          </>
        )}
        {(isCandidateR1Pending || consultantPerimeter === undefined) && (
          <ContentLoader height="5rem" width="100%" uniqueKey="agencyName">
            <rect x="2%" y="10" rx="4" ry="4" width="80%" height="50" />
          </ContentLoader>
        )}
        {candidateContract && (
          <div className={styles.customerContainer}>
            <WithLightTitle
              title={
                moment().isBetween(candidateContract.startDate, candidateContract.endDate)
                  ? 'client actuel'
                  : 'dernier client'
              }
            >
              <div className={styles.customer}>{candidateContract.companyName}</div>
            </WithLightTitle>
            <WithLightTitle title="durée de la mission">
              <div className={styles.customer}>
                {`${moment(candidateContract.endDate).diff(
                  moment(candidateContract.startDate),
                  'months'
                )} mois`}
              </div>
            </WithLightTitle>
          </div>
        )}
        {isCandidateR1Fulfilled &&
          consultantInfo.data?.name &&
          isCGCFullfilled &&
          cgcList.data !== undefined && (
            <div className={styles.recruiterContainer}>
              <WithLightTitle title="CGC" className={styles.cgcContainer}>
                <ComboBoxWithDisplayMode
                  id="cgc"
                  disableClearable
                  minLengthToSearch={0}
                  isDisplayMode={isDisplayMode}
                  fetchStatus={cgcFetchStatus}
                  value={cgcController.field.value}
                  searchResults={cgcList.data ?? []}
                  useNativeOptionFiltering
                  keyValueExtractor={cgc => ({
                    key: cgc.id ?? '',
                    value: [cgc.label?.replace('RANDSTAD INHOUSE', ''), cgc.id].join(' - ') ?? '',
                  })}
                  onChange={cgc => cgcController.field.onChange(cgc)}
                  placeholder="choisissez un CGC"
                />
              </WithLightTitle>

              <WithLightTitle title="nom du recruteur" className={styles.recruiterName}>
                <div className={styles.recruiter}>
                  {[consultantInfo.data.name, consultantInfo.data?.firstName ?? ''].join(' ')}
                </div>
              </WithLightTitle>
            </div>
          )}
      </div>
      {!isDisplayMode && (
        <FetchButton
          secondary
          title="valider information contexte"
          errorTitle="réessayer"
          fetchStatus={isSubmitting ? FETCH_STATUS.FULFILLED : fetchStatus}
          className={styles.button}
          onClick={contextHandleSubmit()}
          onSuccess={() => toast.success('Votre R1 a été modifié')}
          onError={() => toast.error("Erreur lors de l'enregistrement de votre R1")}
        />
      )}
      {Object.keys(formState.errors).length !== 0 && (
        <div className={styles.errorMessage}>
          il y a des erreurs ou des champs non remplis dans le formulaire
        </div>
      )}
    </>
  );
};

export default ContextInformations;
