import {
  Button,
  Drawer,
  Loader,
  Stepper,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  ChevronLeft,
  ChevronRight,
  IllusEscalierYellow,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useFetchCandidateDetailsFromURL } from 'src/Hooks/Candidates/useFetchCandidateDetailsFromURL';
import { LocationState } from 'src/Hooks/Modals';
import { FETCH_STATUS } from 'src/Redux/Types';
import { mergeSeveralFetchStatus, toFetchStatus } from 'src/Services/ReactQuery';
import { getResumeEditionRoute } from 'src/Services/Routing';
import { extractRoute, extractSections } from 'src/Utils/navigate';

import ResumeGeneratorExitModal from '../Modals/ResumeGeneratorExitModal';

import styles from './CandidateResume.module.scss';
import { navigation } from './CandidateResume.types';
import { CandidateResumeContext, useCandidateResumeContext } from './CandidateResumeContext';
import CandidateResumeForm from './CandidateResumeForm';

const CandidateResume = () => {
  const location = useLocation<LocationState>();
  const locationState = location.state;
  const background = locationState?.background;
  const params = useParams<{
    candidateId: string;
    firstSection: string;
    secondSection: string;
  }>();
  const history = useHistory();
  const candidateDetailsQuery = useFetchCandidateDetailsFromURL();
  const candidateResumeContext = useCandidateResumeContext();
  const fetchStatus = mergeSeveralFetchStatus([toFetchStatus(candidateDetailsQuery)]);

  const [baseRoute, currentPath] = extractRoute(location, 'resume');
  const [sections, subsections, sectionKeys, subsectionNames] = extractSections(
    navigation,
    'resume'
  );
  const currentSectionIndex = sections.findIndex(section => section.includes(params.firstSection));
  const currentSubsectionIndex = subsections.findIndex(subsection => subsection === currentPath);
  const fields = Object.entries(navigation).map((item, index) =>
    index === currentSectionIndex ? subsectionNames[currentSubsectionIndex] : item[1].name
  );
  const complete = sections.map(section =>
    subsections.slice(0, currentSubsectionIndex).includes(section)
  );

  const navigate = (offset: number) => {
    const idx = currentSubsectionIndex + offset;
    switch (idx) {
      case -1:
        close();
        break;
      case subsections.length:
        validate();
        break;
      default:
        history.push({
          pathname: baseRoute + subsections[idx],
          state: {
            background,
          },
        });
    }
  };

  const changeSelection = (section: string) => {
    if (
      candidateResumeContext.isNextEnabled ||
      subsections.findIndex(s => s === '/' + section) < currentSectionIndex
    ) {
      history.push({
        pathname: getResumeEditionRoute({
          candidateId: params.candidateId,
          section,
        }),
        state: {
          background,
        },
      });
    }
  };

  const validate = () => {
    // TODO: Send resume to BAPI
    candidateResumeContext.resetContext();
    history.push({
      pathname: '/results',
      state: {
        background,
      },
    });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const close = () => {
    setIsModalOpen(true);
  };

  return (
    <CandidateResumeContext.Provider
      value={{
        qualifications: candidateResumeContext.qualifications,
        setQualifications: candidateResumeContext.setQualifications,
        skills: candidateResumeContext.skills,
        setSkills: candidateResumeContext.setSkills,
        resetContext: candidateResumeContext.resetContext,
        isNextEnabled: candidateResumeContext.isNextEnabled,
        setIsNextEnabled: candidateResumeContext.setIsNextEnabled,
      }}
    >
      <Drawer
        title={'synthèse candidat'}
        titleClassName={styles.title}
        onClose={close}
        headerContent={
          <Stepper
            key={'simplified_header_' + params.candidateId}
            fields={fields}
            selectedkey={sectionKeys}
            selected={sectionKeys[currentSectionIndex]}
            complete={complete}
            onSelectionChange={section => changeSelection(section)}
          />
        }
      >
        <div className={styles.container}>
          <div className={styles.container}>
            <div className={styles.tabContainer}>
              {fetchStatus === FETCH_STATUS.PENDING && (
                <div className={styles.loader}>
                  <Loader heightInRem={8} />
                </div>
              )}

              {fetchStatus === FETCH_STATUS.REJECTED && (
                <div className={styles.errorContainer}>
                  <div className={styles.errorYellowLadder}>
                    <IllusEscalierYellow />
                  </div>
                  <p className={styles.errorTitle}>erreur serveur, connexion perdue</p>
                  <p className={styles.errorDescription}>
                    dès que vous retrouverez la connexion, vous pourrez recharger la page
                  </p>
                </div>
              )}

              {fetchStatus === FETCH_STATUS.FULFILLED && (
                <CandidateResumeForm
                  initialRequiredFields={{
                    qualifications: {
                      hasQualifications: true,
                    },
                  }}
                />
              )}
            </div>
            <div className={styles.footer}>
              <Button.Secondary
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ChevronLeft />
                précédent
              </Button.Secondary>

              <Button.Primary
                disabled={!candidateResumeContext.isNextEnabled}
                onClick={() => {
                  navigate(1);
                }}
              >
                suivant
                <ChevronRight />
              </Button.Primary>
            </div>
          </div>
        </div>
        <ResumeGeneratorExitModal
          close={() => {
            candidateResumeContext.resetContext();
            history.push({
              pathname: '/results',
              state: {
                background,
              },
            });
          }}
          open={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      </Drawer>
    </CandidateResumeContext.Provider>
  );
};

export default CandidateResume;
