import 'moment/locale/fr';
import moment from 'moment';

moment.locale('fr');

export const dayTimeDifference = (startDate?: Date, endDate?: Date) => {
  if (startDate === undefined || endDate === undefined) {
    return 0;
  }
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);

  return endMoment.diff(startMoment, 'days');
};
