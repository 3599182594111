import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getDrivingLicense } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';
import { MatchingCriteriaItemWithLabel } from 'src/Services/API';

import DrivingLicenseModal from './DrivingLicenseModal.component';

const MemoDrivingLicenseModal = React.memo(DrivingLicenseModal);

const EnhancedDrivingLicenseModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const drivingLicenses = useSelector(getDrivingLicense);
  const onDrivingLicenseChange = useCallback(
    (drivingLicenses: MatchingCriteriaItemWithLabel[]) =>
      dispatch(searchActions.setDrivingLicenses(drivingLicenses)),
    [dispatch]
  );
  const onClose = useCallback(() => {
    history.goBack();
  }, [history]);
  return (
    <MemoDrivingLicenseModal
      drivingLicenses={drivingLicenses}
      onDrivingLicenseChange={onDrivingLicenseChange}
      onClose={onClose}
    />
  );
};

export default EnhancedDrivingLicenseModal;
