import { Badge, Checkbox, Loader } from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import React, { useState } from 'react';
import { useParams } from 'react-router';

import { useFetchQualificationSkillDetails } from 'src/Hooks/Candidates/useFetchQualificationBusinessSkills';
import { FETCH_STATUS } from 'src/Redux/Types';
import { mergeFetchStatus, toFetchStatus } from 'src/Services/ReactQuery';
import { dayTimeDifference } from 'src/Utils/moment';

import styles from './CandidateQualifications.module.scss';
import { Props } from './CandidateQualifications.types';

// TODO : update and use react Card component instead of this one
const CandidateQualifications = ({
  initialState,
  qualification,
  updateQualificationClick,
  candidateResumeFetchStatus,
  experiences,
  experiencesFetchStatus,
}: Props) => {
  const params = useParams<{
    candidateId: string;
  }>();
  const businessSkillsQuery = useFetchQualificationSkillDetails(
    params.candidateId,
    qualification.qualificationId ?? ''
  );
  const businessSkillsFetchStatus = mergeFetchStatus(
    candidateResumeFetchStatus,
    toFetchStatus(businessSkillsQuery)
  );

  const getMonths = () => {
    const DAYS_IN_MONTH = 30;
    const days = experiences.reduce(
      (acc, experience) => acc + dayTimeDifference(experience.startDate, experience.endDate),
      0
    );
    return Math.ceil(days / DAYS_IN_MONTH);
  };

  const [selected, setSelected] = useState<boolean>(initialState);
  const months = getMonths();
  const label = qualification.label ?? '';
  const title = label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
  const subTitle = `nombre de mois : ${months} mois`;
  const nbSkills = businessSkillsQuery.data?.businessSkills?.length ?? 0;
  const skills = `${nbSkills} compétence${nbSkills > 1 ? 's' : ''}`;

  return (
    <div className={classnames(styles.container, selected ? styles.selected : styles.idle)}>
      <Checkbox
        checked={selected}
        onChange={() => {
          setSelected(!selected);
          updateQualificationClick(qualification);
        }}
      />
      <div className={styles.leftSide}>
        <div className={styles.leftTextContainer}>
          {qualification.main && (
            <div className={styles.mainQualification}>qualification principale</div>
          )}
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>
            {experiencesFetchStatus === FETCH_STATUS.FULFILLED
              ? subTitle
              : experiencesFetchStatus === FETCH_STATUS.PENDING && <Loader heightInRem={1} />}
          </div>
        </div>
        {candidateResumeFetchStatus === FETCH_STATUS.PENDING && <Loader heightInRem={1} />}
        {businessSkillsFetchStatus === FETCH_STATUS.FULFILLED && (
          <Badge value={skills} className={styles.badge} />
        )}
      </div>
    </div>
  );
};

export default CandidateQualifications;
