import {
  Badge,
  HorizontalCardWithTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { DragIcon } from '@randstad-lean-mobile-factory/react-components-ui-shared';

import { DragNDropDisabledIcon } from 'src/Assets';
import { pluralFormat } from 'src/Utils/pluralFormat';

import JobActionButton from './JobActionButton/JobActionButton.component';
import styles from './JobItem.module.scss';
import { Props } from './JobItem.types';

const JobItem = ({ job, allJobs, mainQualification, isDisplayMode, onEdit }: Props) => {
  const isDraggable = !isDisplayMode && !job.qualifications.includes(mainQualification ?? '');
  return (
    <HorizontalCardWithTitle
      leftIcon={!isDisplayMode && (isDraggable ? <DragIcon /> : <DragNDropDisabledIcon />)}
      title={job.label ?? ''}
      leftSideClassName={styles.mainQualificationStyle}
      subtitles={[
        [
          pluralFormat(job.contractCount ?? 0, 'contrat'),
          pluralFormat(job.companyCount ?? 0, 'compte'),
        ].join(', '),
      ]}
      rightActions={
        <>
          <div className={styles.badges}>
            {job.qualifications.includes(mainQualification ?? '') && <Badge value="principale" />}
            <Badge
              className={styles.qualificationCountBadge}
              value={pluralFormat(job.qualifications.length ?? 0, 'qualification')}
            />
          </div>
          {!isDisplayMode && (
            <JobActionButton
              allJobs={allJobs}
              jobToModify={job}
              mainQualification={mainQualification}
              onEditModalValidation={onEdit}
            />
          )}
        </>
      }
      className={styles.jobCard}
    />
  );
};

export default JobItem;
