import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CompletionStatusState, RequiredFields } from './Types';

const initialState: CompletionStatusState = {
  requiredFields: {
    qualifications: { hasQualifications: true },
  },
};

const { reducer, actions } = createSlice({
  name: 'completionStatus',
  initialState,
  reducers: {
    setRequiredFields: (state, action: PayloadAction<RequiredFields>) => {
      state.requiredFields = action.payload;
    },
  },
});

export const completionStatusReducer = reducer;
export const completionStatusActions = actions;
