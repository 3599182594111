import {
  Badge,
  Button,
  Checkbox,
  PopupMenu,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { Filters } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCardFilters, getDashboardStatus } from 'src/Redux/UserPreferences/Selectors';
import { userPreferencesActions } from 'src/Redux/UserPreferences/Slice';
import { CARD_FILTERS } from 'src/Redux/UserPreferences/Types';
import { CandidateInterviewsSummaryStatus } from 'src/Services/API';

import styles from './StatusSelector.module.scss';

const StatusSelector = () => {
  const [open, setOpen] = useState(false);
  const selectedDashboardStatus = useSelector(getDashboardStatus);
  const selectedCardFilters = useSelector(getCardFilters);
  const [localDashboardStatus, setLocalDashboardStatus] = useState(selectedDashboardStatus);
  const [localCardFilters, setLocalCardFilters] = useState(selectedCardFilters as CARD_FILTERS[]);

  const dispatch = useDispatch();
  return (
    <div className={styles.container}>
      <PopupMenu
        open={open}
        position="bottom right"
        width={436}
        onClose={() => {
          dispatch(userPreferencesActions.setDashboardStatus(localDashboardStatus));
          dispatch(userPreferencesActions.setCardFilters(localCardFilters));
          setOpen(false);
        }}
        onOpen={() => {
          setOpen(true);
        }}
        trigger={
          <Button.Secondary
            text="filtres"
            leftIcon={<Filters />}
            className={classnames(styles.filterButton, { [styles.openPopup]: open })}
            rightIcon={
              <Badge
                value={
                  (selectedDashboardStatus.length + selectedCardFilters.length)?.toString() ?? '0'
                }
                className={classnames(styles.dot, { [styles.openPopupDot]: open })}
              />
            }
          />
        }
      >
        <div className={styles.popupContentContainer}>
          <WithLightTitle title="liste des statuts">
            <div className={styles.mainStatusesContainer}>
              {Object.values(CandidateInterviewsSummaryStatus)
                .filter(
                  status =>
                    ![
                      CandidateInterviewsSummaryStatus['refusé par intérimaire'],
                      CandidateInterviewsSummaryStatus['validé par dérogation'],
                    ].includes(status)
                )
                .map(status => (
                  <div className={styles.checkboxContainer} key={status}>
                    <Checkbox
                      label={status}
                      checked={localDashboardStatus.includes(status)}
                      onClick={() =>
                        localDashboardStatus.includes(status)
                          ? setLocalDashboardStatus(
                              localDashboardStatus.filter(statusElement => statusElement !== status)
                            )
                          : setLocalDashboardStatus([...localDashboardStatus, status])
                      }
                    />
                  </div>
                ))}
            </div>
            <div className={styles.secondaryStatusesContainer}>
              {Object.values(CandidateInterviewsSummaryStatus)
                .filter(status =>
                  [
                    CandidateInterviewsSummaryStatus['refusé par intérimaire'],
                    CandidateInterviewsSummaryStatus['validé par dérogation'],
                  ].includes(status)
                )
                .map(status => (
                  <div className={styles.checkboxContainer} key={status}>
                    <Checkbox
                      label={status}
                      checked={localDashboardStatus.includes(status)}
                      onClick={() =>
                        localDashboardStatus.includes(status)
                          ? setLocalDashboardStatus(
                              localDashboardStatus.filter(statusElement => statusElement !== status)
                            )
                          : setLocalDashboardStatus([...localDashboardStatus, status])
                      }
                    />
                  </div>
                ))}
            </div>
          </WithLightTitle>
          <WithLightTitle title="filtres">
            {Object.values(CARD_FILTERS).map(filter => (
              <div className={styles.checkboxContainer} key={filter}>
                <Checkbox
                  label={filter}
                  checked={localCardFilters.includes(filter)}
                  onClick={() =>
                    localCardFilters.includes(filter)
                      ? setLocalCardFilters(
                          localCardFilters.filter(filterElement => filterElement !== filter)
                        )
                      : setLocalCardFilters([...localCardFilters, filter])
                  }
                />
              </div>
            ))}
          </WithLightTitle>
        </div>
      </PopupMenu>
    </div>
  );
};

export default StatusSelector;
