import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, Router, Switch, Route } from 'react-router';

import { completionStatusActions } from 'src/Redux/CompletionStatus/Slice';

import { navigation } from '../CandidateResume.types';

import styles from './CandidateResumeForm.module.scss';
import { Props } from './CandidateResumeForm.types';

const CandidateResumeForm = ({ initialRequiredFields }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(completionStatusActions.setRequiredFields(initialRequiredFields));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.formContainer}>
        <div className={styles.content}>
          <Router history={history}>
            <Switch>
              {Object.values(navigation).map(node =>
                node.routes.map(route => {
                  const Component = route.component;
                  return (
                    <Route key={route.name} path={`${route.path}`} exact>
                      {Component && <Component />}
                    </Route>
                  );
                })
              )}
            </Switch>
          </Router>
        </div>
      </div>
    </div>
  );
};

export default CandidateResumeForm;
