import { Location } from 'history';

import { CandidateResumeNav } from 'src/Containers/CandidateResume/CandidateResume.types';

export const extractSections = (nav: CandidateResumeNav, sep: string): string[][] => {
  const sections = Object.entries(nav).flatMap(item => {
    const subroutes = item[1].routes;
    return subroutes[subroutes.length - 1].path.split(sep).pop() || '';
  });

  const subsections = Object.entries(nav).flatMap(item => {
    const subroutes = item[1].routes.map(route => route.path.split(sep).pop() || '');
    return subroutes;
  });

  const sectionKeys = Object.entries(nav).flatMap(item => {
    const subroutes = item[1].routes[0].path.split(sep).pop()?.slice(1) || '';
    return subroutes;
  });

  const subsectionNames = Object.entries(nav).flatMap(item => {
    const subroutes = item[1].routes.map(route => route.name);
    return subroutes;
  });

  return [sections, subsections, sectionKeys, subsectionNames];
};

export const extractRoute = (location: Location<unknown>, sep: string): string[] => {
  const baseRoute = location.pathname.split(sep)[0] + sep;
  const currentPath = location.pathname.split(sep).pop() || '';

  return [baseRoute, currentPath];
};
