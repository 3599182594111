import { Badge, Button, Checkbox } from '@randstad-lean-mobile-factory/react-components-core';
import {
  GoBackArrow,
  WhiteCircleCross,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useActiveCriteria } from 'src/Hooks/Criteria';
import { getSearchMode } from 'src/Redux/Search/Selectors';
import { EnumFilterResultWorkingHours, SEARCH_MODE } from 'src/Redux/Search/Types';
import { CandidateStatuses } from 'src/Services/API';

import SearchResultsFilter from './SearchResultsFilter';
import styles from './SearchResultsSidebar.module.scss';
import { FilterItemType, Props } from './SearchResultsSidebar.types';

const typologyFilter = [
  { label: 'TT', id: CandidateStatuses.INT },
  { label: 'CDI TT', id: CandidateStatuses.CDI },
  { label: 'Candidat Osmose', id: CandidateStatuses.CAE },
  { label: 'Candidat CVthèque', id: CandidateStatuses.CVT },
];

const workingHoursFilter = [
  { label: '< 400', id: EnumFilterResultWorkingHours.UNDER_400 },
  { label: '400 - 1000', id: EnumFilterResultWorkingHours.UNDER_1000 },
  { label: '> 1000', id: EnumFilterResultWorkingHours.OVER_1000 },
];

const filters = (props: Props): FilterItemType[] => [
  {
    choices: typologyFilter,
    selectedChoices: props.candidateStatuses,
    label: 'typologie de personnes',
    onItemClick: id =>
      props.candidateStatuses.includes(id as CandidateStatuses)
        ? props.removeCandidateStatuses(id as CandidateStatuses)
        : props.setCandidateStatuses(id as CandidateStatuses),
  },
  {
    choices: workingHoursFilter,
    selectedChoices: props.resultFilters.workingHours.map(filter => filter.id),
    label: "nombre d'heures",
    onItemClick: id =>
      props.resultFilters.workingHours.find(filterItem => filterItem.id === id)
        ? props.removeWorkingHoursFilter(id as EnumFilterResultWorkingHours)
        : props.addWorkingHoursFilter(id as EnumFilterResultWorkingHours),
  },
];

const SearchResultsSidebar = (props: Props) => {
  const history = useHistory();
  const filterList = filters(props);
  const criteria = useActiveCriteria();
  const searchMode = useSelector(getSearchMode);
  const reset = () => {
    props.resetCandidateStatuses();
    props.resetWorkingHoursFilter();
  };
  return (
    <div className={styles.container}>
      <div
        className={styles.goBack}
        onClick={() => {
          history.push('/');
        }}
      >
        <Button.Tertiary className={styles.goBackArrowButton}>
          <GoBackArrow className={styles.goBackArrow} />
        </Button.Tertiary>
        <div className={styles.goBackButtonText}>nouveaux critères de recherche</div>
      </div>
      {searchMode === SEARCH_MODE.BY_CRITERIA && (
        <div className={styles.badgesContainer}>
          {criteria.map(criteriaItem =>
            criteriaItem.items.map(item => (
              <Badge
                key={item.identifier}
                value={item.label}
                leftIcon={<WhiteCircleCross />}
                className={styles.criteriaBadge}
                onLeftIconClick={() => criteriaItem.onDelete(item.identifier)}
              />
            ))
          )}
        </div>
      )}
      {props.searchMode === SEARCH_MODE.BY_CRITERIA && (
        <div className={styles.sideBarElementsContainer}>
          <div className={styles.sideBarTitle}>afficher les résultats</div>
          <div
            onClick={reset}
            className={classnames(styles.resetButton, {
              [styles.disabledResetButton]: !props.hasFilter,
            })}
          >
            réinitialiser
          </div>
          {filterList.map(filterElement => {
            return (
              <SearchResultsFilter
                key={filterElement.label}
                label={filterElement.label}
                children={filterElement.choices.map(item => {
                  return (
                    <div key={item.id} className={styles.checkBoxContainer}>
                      <Checkbox
                        label={item.label}
                        checked={filterElement.selectedChoices.includes(item.id)}
                        onChange={() => filterElement.onItemClick(item.id)}
                      />
                    </div>
                  );
                })}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SearchResultsSidebar;
