import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useController } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import CandidateQualifications from 'src/Components/CandidateQualifications/CandidateQualifications.component';
import CompletionStatusMessagesComponent from 'src/Components/CompletionStatusMessages/CompletionStatusMessages.component';
import { COMPLETION_ERROR_MESSAGE } from 'src/Components/CompletionStatusMessages/CompletionStatusMessages.types';
import { ErrorMessage } from 'src/Components/ErrorMessage';
import { useFetchCandidateBusinessesFromURL } from 'src/Hooks/Candidates/useFetchCandidateBusinessesFromURL';
import { useFetchCandidateExperiences } from 'src/Hooks/Candidates/useFetchCandidateExperiences';
import { getRequiredFields } from 'src/Redux/CompletionStatus/Selectors';
import { completionStatusActions } from 'src/Redux/CompletionStatus/Slice';
import { FETCH_STATUS } from 'src/Redux/Types';
import { QualificationBusinessSkills } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/ReactQuery';

import { CandidateResumeContext } from '../CandidateResumeContext';

import { qualificationsSchema } from './Qualification.schema';
import styles from './Qualifications.module.scss';

const Qualifications = () => {
  const candidateResumeContext = useContext(CandidateResumeContext);
  const { candidateId } = useParams<{ candidateId: string }>();
  const initialValues = candidateResumeContext.qualifications;

  const [businessesQueryInitialized, setBusinessesQueryInitialized] = useState(false);
  const [initMissingFields, setInitMissingFields] = useState<boolean>(true);
  const businessesQuery = useFetchCandidateBusinessesFromURL();
  const candidateResumeFetchStatus = toFetchStatus(businessesQuery);
  const fetchCandidateExperiences = useFetchCandidateExperiences(candidateId);
  const experiencesFetchStatus = toFetchStatus(fetchCandidateExperiences);
  const requiredFields = useSelector(getRequiredFields);
  const dispatch = useDispatch();

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useFormWithZodResolver({
    schema: qualificationsSchema,
    defaultValues: {
      hasQualifications: initialValues?.hasQualifications,
      qualifications: initialValues?.qualifications,
    },
  });

  const hasQualifications = watch('hasQualifications');
  const qualifications = watch('qualifications');

  const qualificationsController = useController({
    name: 'qualifications',
    control,
  });

  const QualificationsHandleSubmit = useCallback(
    () =>
      handleSubmit(values => {
        candidateResumeContext.setQualifications({
          hasQualifications: values.qualifications.length > 0,
          qualifications: values.qualifications,
        });
        reset(values);
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleSubmit, reset]
  );

  const submit = QualificationsHandleSubmit();

  useEffect(() => {
    if (!businessesQueryInitialized && businessesQuery.isSuccess) {
      setBusinessesQueryInitialized(true);
    }
  }, [businessesQuery, businessesQueryInitialized]);

  useEffect(() => {
    if (isDirty) {
      submit();
    }
    candidateResumeContext.setIsNextEnabled(qualifications.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasQualifications, qualifications]);

  const missingRequiredFields = [
    qualifications.length === 0 && COMPLETION_ERROR_MESSAGE.noQualifications,
  ].filter(Boolean);

  useEffect(() => {
    const hasMissingQualifications = missingRequiredFields.includes(
      COMPLETION_ERROR_MESSAGE.noQualifications
    );

    const newRequiredFields = {
      ...requiredFields,
      qualifications: {
        ...requiredFields.qualifications,
        hasQualifications: !hasMissingQualifications,
      },
    };

    dispatch(completionStatusActions.setRequiredFields(newRequiredFields));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasQualifications, qualificationsController.field.value]);

  const addOrRemove = (arr: QualificationBusinessSkills[], item: QualificationBusinessSkills) => {
    const filtered = arr.filter(el => el.qualificationId !== item.qualificationId);

    if (filtered.length === arr.length) {
      return [...arr, item];
    }
    return filtered;
  };

  const all_qualifications: QualificationBusinessSkills[] =
    businessesQuery.data === undefined
      ? []
      : businessesQuery.data
          .flatMap(business => business.candidateQualifications)
          .filter((q): q is QualificationBusinessSkills => q !== undefined);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <p>sur quelle qualification souhaitez-vous faire une synthèse ?</p>
      </div>
      {!initMissingFields && missingRequiredFields.length > 0 && (
        <CompletionStatusMessagesComponent
          missingRequiredFields={missingRequiredFields as COMPLETION_ERROR_MESSAGE[]}
        />
      )}
      {candidateResumeFetchStatus === FETCH_STATUS.PENDING &&
        new Array(5).fill(null).map((_, idx) => (
          <ContentLoader key={'load_' + idx} height="3rem" width="100%" uniqueKey="diploma">
            <rect x="2%" y="20" rx="4" ry="4" width="100%" height="20" />
          </ContentLoader>
        ))}
      {candidateResumeFetchStatus === FETCH_STATUS.REJECTED && (
        <ErrorMessage message="Une erreur est survenue dans la récupération des compétences" />
      )}
      {candidateResumeFetchStatus === FETCH_STATUS.FULFILLED && (
        <div className={styles.gridContainer}>
          {all_qualifications.map((qualification, index) => (
            <CandidateQualifications
              key={`${index}_${index}`}
              initialState={qualifications.map(q => q.label).includes(qualification?.label ?? '')}
              qualification={qualification}
              updateQualificationClick={(qualification: QualificationBusinessSkills) => {
                setInitMissingFields(false);
                const newQualifications = addOrRemove(
                  qualificationsController.field.value,
                  qualification
                );
                qualificationsController.field.onChange(newQualifications);
              }}
              candidateResumeFetchStatus={candidateResumeFetchStatus}
              experiences={
                fetchCandidateExperiences?.data?.filter(
                  experience => experience.qualification?.id === qualification.qualificationId
                ) ?? []
              }
              experiencesFetchStatus={experiencesFetchStatus}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Qualifications;
