import { Button, Modal, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { IllusRLCompany } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useState } from 'react';

import CriteriaCard from 'src/Components/CriteriaCard';
import SelectCompany from 'src/Containers/Selects/SelectCompany';

import styles from './CompanyModal.module.scss';
import { Props } from './CompanyModal.types';

const CompanyModal = ({ company, onCompanyChange, onClose }: Props) => {
  const [selectedCompany, setSelectedCompany] = useState(company);

  return (
    <Modal
      icon={<IllusRLCompany />}
      onClose={onClose}
      nested
      open
      title="comptes"
      footerActionsLeft={[
        <Button.Tertiary
          onClick={() => {
            setSelectedCompany(company);
          }}
        >
          tout réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <Button.Primary
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            onCompanyChange(selectedCompany!);
            onClose();
          }}
        >
          valider
        </Button.Primary>,
      ]}
    >
      <SelectCompany selectedCompany={selectedCompany} setCompany={setSelectedCompany} />
      {selectedCompany && (
        <div className={styles.listContainer}>
          <WithLightTitle title={`compte ajouté`}>
            <div className={styles.separator}></div>
            <CriteriaCard
              className={styles.criteriaCard}
              title={selectedCompany.label ?? ''}
              weight={selectedCompany.wishPriority}
              onWeightChange={weight => {
                const newCompany = {
                  label: selectedCompany.label,
                  identifier: selectedCompany.identifier,
                  wishPriority: weight,
                };
                setSelectedCompany(newCompany);
              }}
              onDelete={() => setSelectedCompany(undefined)}
            />

            <div className={styles.separator}></div>
          </WithLightTitle>
        </div>
      )}
    </Modal>
  );
};

export default CompanyModal;
