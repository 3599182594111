import { Button, Modal } from '@randstad-lean-mobile-factory/react-components-core';
import {
  FlecheBack,
  IllusRLHabilitations,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useRef, useState } from 'react';

import { MatchingCriteriaItemWithLabel } from 'src/Services/API';

import ElectricHabilitationModal from './ElectricHabilitationModal/ElectricHabilitationModal.component';
import { ElectricHabilitationModalRef } from './ElectricHabilitationModal/ElectricHabilitationModal.types';
import styles from './HabilitationModal.module.scss';
import { HABILITATION_MODALS_STEP, Props } from './HabilitationModal.types';
import NormalHabilitationModal from './NormalHabilitationModal/NormalHabilitationModal.component';

interface ModalChild {
  component: React.ReactNode;
  onPrevious?: () => void;
  onNext?: () => void;
  next?: HABILITATION_MODALS_STEP;
  isValid?: boolean;
  icon: React.ReactNode;
  previous?: HABILITATION_MODALS_STEP;
  primaryButtonText?: string;
  onReinitialize: () => void;
}

const HabilitationModal = ({ onClose, habilitations, onHabilitationChange }: Props) => {
  const ref = useRef<ElectricHabilitationModalRef>(null);
  const [selectedHabilitations, setSelectedHabilitations] = useState(habilitations);
  const [selectedElectricHabilitation, setSelectedElectricHabilitation] = useState<
    MatchingCriteriaItemWithLabel | undefined
  >(undefined);
  const resetElectricHabilitation = () => {
    setSelectedElectricHabilitation(undefined);
    ref.current?.reset();
  };
  const [step, setStep] = useState(HABILITATION_MODALS_STEP.NORMAL_HABILITATION);

  const ModalChildren: Record<HABILITATION_MODALS_STEP, ModalChild> = {
    [HABILITATION_MODALS_STEP.NORMAL_HABILITATION]: {
      component: (
        <NormalHabilitationModal
          onElectricButtonClick={() => setStep(HABILITATION_MODALS_STEP.ELECTRIC_HABILITATION)}
          selectedHabilitations={selectedHabilitations}
          setSelectedHabilitations={setSelectedHabilitations}
        />
      ),
      icon: <IllusRLHabilitations />,
      primaryButtonText: 'valider',
      isValid: true,
      onReinitialize: () => setSelectedHabilitations(habilitations),
      onNext: () => {
        onHabilitationChange(selectedHabilitations);
        onClose();
      },
      onPrevious: onClose,
    },
    [HABILITATION_MODALS_STEP.ELECTRIC_HABILITATION]: {
      component: (
        <ElectricHabilitationModal
          ref={ref}
          setElectricHabilitation={setSelectedElectricHabilitation}
        />
      ),
      icon: (
        <FlecheBack
          className={styles.goBackArrow}
          onClick={() => {
            setStep(HABILITATION_MODALS_STEP.NORMAL_HABILITATION);
            resetElectricHabilitation();
          }}
        />
      ),
      primaryButtonText: 'ajouter',
      isValid: selectedElectricHabilitation !== undefined,
      onReinitialize: () => {
        resetElectricHabilitation();
      },
      onPrevious: () => {
        setStep(HABILITATION_MODALS_STEP.NORMAL_HABILITATION);
        resetElectricHabilitation();
      },
      onNext: () => {
        selectedElectricHabilitation &&
          !selectedHabilitations.includes(selectedElectricHabilitation) &&
          setSelectedHabilitations([...selectedHabilitations, selectedElectricHabilitation]);
        resetElectricHabilitation();
        setStep(HABILITATION_MODALS_STEP.NORMAL_HABILITATION);
      },
    },
  };

  const { component, isValid, primaryButtonText, onNext, onReinitialize, onPrevious, icon } =
    ModalChildren[step];

  return (
    <Modal
      icon={icon}
      nested
      onClose={onClose}
      title="habilitations"
      open
      footerActionsLeft={[
        <Button.Tertiary onClick={onReinitialize}>tout réinitialiser</Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary onClick={onPrevious}>annuler</Button.Secondary>,
        <Button.Primary
          disabled={!isValid}
          onClick={() => {
            onNext && onNext();
          }}
        >
          {primaryButtonText}
        </Button.Primary>,
      ]}
    >
      {component}
    </Modal>
  );
};

export default HabilitationModal;
