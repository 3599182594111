import { Button, Modal } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useCallback } from 'react';

import { IllusWarning } from 'src/Assets';

import styles from './ResumeGeneratorExitModal.module.scss';
import { Props } from './ResumeGeneratorExitModal.types';

const ResumeGeneratorExitModal = ({ close, open, setIsModalOpen }: Props) => {
  const onClose = useCallback(() => {
    setIsModalOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Modal
      open={open}
      title="retour à la recherche"
      icon={<IllusWarning />}
      onClose={() => {
        onClose();
      }}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <Button.Primary
          onClick={() => {
            close();
          }}
        >
          quitter
        </Button.Primary>,
      ]}
    >
      <p className={styles.text}>
        Si vous quittez la génération de synthèse, vos modifications seront perdues
      </p>
    </Modal>
  );
};

export default ResumeGeneratorExitModal;
