import React from 'react';

import ResumeButton from 'src/Components/ResumeButton';
import { useOpenPersonalResume } from 'src/Hooks/Candidates';

import { Props } from './PersonalResumeButton.types';

const PersonalResumeButton = ({ candidateId, candidateName, className }: Props) => {
  const { mutate, isLoading } = useOpenPersonalResume();
  return (
    <ResumeButton
      title="CV personnel"
      onClick={() => mutate({ candidateId, candidateName })}
      isLoading={isLoading}
      className={className}
    />
  );
};

export default PersonalResumeButton;
