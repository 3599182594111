import {
  FetchButton,
  toast,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import throttle from 'lodash.throttle';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useController, useFieldArray, useFormState } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import AutosuggestWithList from 'src/Components/AutosuggestWithList';
import ScoringButtonWithDisplayMode from 'src/Components/WithDisplayMode/ScoringButtonWithDisplayMode';
import TextAreaWithDisplayMode from 'src/Components/WithDisplayMode/TextAreaWithDisplayMode';
import TextAreaWithLightTitleWithDisplayMode from 'src/Components/WithDisplayMode/TextAreaWithLightTitleWithDisplayMode';
import ToggleSwitchWithDisplayMode from 'src/Components/WithDisplayMode/ToggleSwitchWithDisplayMode';
import { useFetchCandidateR2FromURL } from 'src/Hooks/CandidateR2/useFetchCandidateR2FromURL';
import { useFetchR2ProjectionsQuestions } from 'src/Hooks/CandidateR2/useFetchR2ProjectionsQuestions';
import { useFetchR2ValidationQuestions } from 'src/Hooks/CandidateR2/useFetchR2ValidationQuestions';
import { useUpdateCandidateR2 } from 'src/Hooks/CandidateR2/useUpdateCandidateR2';
import { useFetchHabilitationsAndDiploma } from 'src/Hooks/Habilitations/useFetchHabilitationsAndDiploma';
import { FormStatusContext } from 'src/Hooks/Navigation/useFormNavigationContextElements';
import { useFetchQualificationsWithThrottle } from 'src/Hooks/Qualifications/useFetchQualifications';
import { getPerimeterUnits } from 'src/Redux/Perimeter/Selectors';
import { FETCH_STATUS } from 'src/Redux/Types';
import { ScoringEnum } from 'src/Services/API';
import { mergeSeveralFetchStatus, toFetchStatus } from 'src/Services/ReactQuery';
import { pluralFormat } from 'src/Utils/pluralFormat';

import styles from './R2CandidateProjections.module.scss';
import { candidateProjectionsSchema } from './R2CandidateProjections.types';

const R2CandidateProjections = () => {
  const units = useSelector(getPerimeterUnits);
  const params = useParams<{
    R2Id: string;
  }>();
  const {
    data: candidateR2Data,
    isLoading: isLoadingCandidateR2,
    isSuccess: isCandidateR2Success,
    isFetching: isFetchingCandidateR2,
    isError: isErrorCandidateR2,
  } = useFetchCandidateR2FromURL();
  const {
    data: questions,
    isLoading: isLoadingQuestions,
    isSuccess: isQuestionsSuccess,
    isFetching: isFetchingQuestions,
    isError: isErrorQuestions,
  } = useFetchR2ProjectionsQuestions();
  const {
    data: validationQuestions,
    isLoading: isLoadingValidationQuestions,
    isSuccess: isValidationQuestionsSuccess,
    isFetching: isFetchingValidationQuestions,
    isError: isErrorValidationQuestions,
  } = useFetchR2ValidationQuestions();
  const candidateR2Update = useUpdateCandidateR2(params.R2Id);
  const candidateR2FetchStatus = toFetchStatus({
    isError: isErrorCandidateR2,
    isLoading: isLoadingCandidateR2,
    isSuccess: isCandidateR2Success,
    isFetching: isFetchingCandidateR2,
  });
  const validationQuestionsFetchStatus = toFetchStatus({
    isError: isErrorValidationQuestions,
    isLoading: isLoadingValidationQuestions,
    isSuccess: isValidationQuestionsSuccess,
    isFetching: isFetchingValidationQuestions,
  });
  const questionsFetchStatus = toFetchStatus({
    isError: isErrorQuestions,
    isLoading: isLoadingQuestions,
    isSuccess: isQuestionsSuccess,
    isFetching: isFetchingQuestions,
  });
  const [qualificationKeyword, setQualificationKeyword] = useState('');
  const [formationKeyword, setFormationKeyword] = useState('');
  const qualificationThrottledMutation = useFetchQualificationsWithThrottle();
  const formationMutation = useFetchHabilitationsAndDiploma();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledFormationMethod = useCallback(throttle(formationMutation.mutate, 500), [
    formationMutation.mutate,
  ]);
  const formationFetchStatus = toFetchStatus(formationMutation);
  const fetchStatus = mergeSeveralFetchStatus([
    candidateR2FetchStatus,
    questionsFetchStatus,
    validationQuestionsFetchStatus,
  ]);
  const updateFetchStatus = toFetchStatus(candidateR2Update);
  const responses = questions?.map(question => {
    return {
      id: question.id,
      label: question.label,
      value:
        candidateR2Data?.projections?.otherProjections?.responses.find(
          response => response.id === question.id
        )?.value ?? '',
    };
  });
  const validationResponses = validationQuestions?.map(question => {
    return {
      id: question.id,
      label: question.label,
      value:
        candidateR2Data?.validationElements?.responses.find(response => response.id === question.id)
          ?.value ?? false,
    };
  });

  const { control, setValue, handleSubmit, formState, watch, reset } = useFormWithZodResolver({
    schema: candidateProjectionsSchema,
    defaultValues: {
      otherProjections: { responses },
      evolution: candidateR2Data?.projections?.evolution ?? [],
      formation: candidateR2Data?.projections?.formation ?? [],
      validations: {
        responses: validationResponses ?? [],
        comment: candidateR2Data?.validationElements?.comment,
      },
      professionalProjections: {
        projections: candidateR2Data?.professionalProjections?.projections,
        scoring: candidateR2Data?.professionalProjections?.scoring ?? ScoringEnum.Unknown,
        comment: candidateR2Data?.professionalProjections?.comment,
      },
    },
  });

  const canModifyR2 = useMemo(
    () => candidateR2Data && units.includes(candidateR2Data.cgcId) && !candidateR2Data.isClosed,
    [candidateR2Data, units]
  );

  const projectionsHandleSubmit = () =>
    handleSubmit(values => {
      candidateR2Data &&
        canModifyR2 &&
        candidateR2Update.mutate({
          ...candidateR2Data,
          projections: {
            otherProjections: values.otherProjections,
            evolution: values.evolution,
            formation: values.formation,
          },
          validationElements: values.validations,
          professionalProjections: values.professionalProjections,
        });
      reset(values);
    });

  useEffect(() => {
    if (isCandidateR2Success && isQuestionsSuccess && isValidationQuestionsSuccess) {
      setValue('validations.responses', validationResponses ?? []);
      setValue('validations.comment', candidateR2Data?.validationElements?.comment);
      setValue('otherProjections.responses', responses ?? []);
      setValue('evolution', candidateR2Data?.projections?.evolution ?? []);
      setValue('formation', candidateR2Data?.projections?.formation ?? []);
      setValue(
        'professionalProjections.projections',
        candidateR2Data?.professionalProjections?.projections
      );
      setValue(
        'professionalProjections.scoring',
        candidateR2Data?.professionalProjections?.scoring ?? ScoringEnum.Unknown
      );
      setValue(
        'professionalProjections.comment',
        candidateR2Data?.professionalProjections?.comment
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCandidateR2Success, isQuestionsSuccess, isValidationQuestionsSuccess]);

  const { fields, update } = useFieldArray({
    control,
    name: 'otherProjections.responses',
    keyName: 'key',
    shouldUnregister: true,
  });

  const { fields: validationResponsesFields, update: validationResponsesUpdate } = useFieldArray({
    control,
    name: 'validations.responses',
    keyName: 'validationKey',
    shouldUnregister: true,
  });
  const validationComment = useController({ name: 'validations.comment', control });
  const scoringController = useController({
    name: 'professionalProjections.scoring',
    control,
  });
  const evolutionController = useController({ name: 'evolution', control });
  const formationController = useController({ name: 'formation', control });
  const professionalProjection = watch('professionalProjections.projections');
  const professionalComment = watch('professionalProjections.comment');

  const { isDirty, isSubmitting, isSubmitted, isSubmitSuccessful } = useFormState({
    control: control,
  });
  const formStatusContext = useContext(FormStatusContext);
  useEffect(() => {
    formStatusContext.setHasFormBeenTouched(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
    formStatusContext.updateValidationStatus({
      handleSubmit: projectionsHandleSubmit,
      fetchStatus: updateFetchStatus,
      formStateElements: { isSubmitSuccessful, isSubmitted, isSubmitting },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted, isSubmitting, isSubmitSuccessful, updateFetchStatus]);

  return (
    <>
      <div className={styles.title}>projection personelle</div>
      {(fetchStatus === FETCH_STATUS.PENDING || !fields.length) &&
        new Array(3).fill(null).map((_, idx) => (
          <ContentLoader key={idx} height="13.5rem" width="100%" uniqueKey="r2projections">
            <rect x="2%" y="20" rx="4" ry="4" width="100%" height="160" />
          </ContentLoader>
        ))}
      {fetchStatus === FETCH_STATUS.FULFILLED && !!fields.length && (
        <>
          <div className={styles.autosuggestContainer}>
            <AutosuggestWithList
              isDisplayMode={!canModifyR2}
              values={evolutionController.field.value}
              onChange={qualifications => evolutionController.field.onChange(qualifications)}
              dataExtractor={(item: { id: string; label: string }) => {
                return { key: item.id, label: item.label };
              }}
              title="qualifications"
              isFetching={false}
              isSuccess={true}
              emptyText=""
              singularTitle="qualification"
              cardContainerClassName={styles.cardsContainer}
              autosuggestFetchStatus={toFetchStatus(qualificationThrottledMutation)}
              autosuggestTitle="Souhaits de qualifications pour évoluer (10 maximum)"
              results={
                qualificationKeyword !== ''
                  ? qualificationThrottledMutation.data
                      ?.map(qualification => {
                        return { id: qualification.identifier, label: qualification.label ?? '' };
                      })
                      .filter(
                        qualification =>
                          !evolutionController.field.value
                            .map(item => item.id)
                            .includes(qualification.id)
                      ) ?? []
                  : []
              }
              onSearch={(word: string) => {
                setQualificationKeyword(word);
                word !== '' && qualificationThrottledMutation.mutate(word);
              }}
              disabled={evolutionController.field.value.length >= 10}
            />
          </div>
          <div className={styles.autosuggestContainer}>
            <AutosuggestWithList
              isDisplayMode={!canModifyR2}
              values={formationController.field.value}
              onChange={formations => formationController.field.onChange(formations)}
              dataExtractor={(item: { id: string; label: string }) => {
                return { key: item.id, label: item.label };
              }}
              title="formations"
              isFetching={false}
              isSuccess={true}
              emptyText=""
              singularTitle="formation"
              cardContainerClassName={styles.cardsContainer}
              autosuggestFetchStatus={formationFetchStatus}
              autosuggestTitle="Formations envisagées (10 maximum)"
              results={
                formationKeyword !== ''
                  ? formationMutation.data?.filter(
                      formation =>
                        !formationController.field.value.map(item => item.id).includes(formation.id)
                    ) ?? []
                  : []
              }
              onSearch={(word: string) => {
                setFormationKeyword(word);
                word !== '' && throttledFormationMethod(word);
              }}
              disabled={formationController.field.value.length >= 10}
            />
          </div>
          {fields.map((field, index) => (
            <WithLightTitle
              key={field.label}
              title={field.label}
              className={styles.container}
              rightTitleComponent={
                <div className={styles.textLength}>
                  {pluralFormat(field.value?.length ?? 0, 'caractère')}
                </div>
              }
            >
              <TextAreaWithDisplayMode
                title={field.label}
                isDisplayMode={candidateR2Data?.isClosed}
                className={styles.textAreaWidth}
                placeholder={
                  questions?.find(question => question.label === field.label)?.placeHolder
                }
                value={field.value}
                onChange={event => {
                  update(index, {
                    id: field.id,
                    label: field.label,
                    value: (event.target as HTMLTextAreaElement).value,
                  });
                }}
              />
            </WithLightTitle>
          ))}
          <div className={styles.separator} />
          <div className={styles.title}>validation des éléments relatif au contrat CDI-I</div>
          <WithLightTitle title="liste des éléments">
            {validationResponsesFields.map((field, index) => (
              <div key={field.label} className={styles.toggleContainer}>
                <ToggleSwitchWithDisplayMode
                  toggleLabel={field.label}
                  isDisplayMode={candidateR2Data?.isClosed}
                  checked={field.value}
                  onCheckStatusChange={checked => {
                    validationResponsesUpdate(index, {
                      id: field.id,
                      label: field.label,
                      value: checked,
                    });
                  }}
                />
              </div>
            ))}
          </WithLightTitle>
          <TextAreaWithLightTitleWithDisplayMode
            control={control}
            name={'validations.comment'}
            containerClassName={styles.container}
            title="commentaires"
            textAreaClassName={styles.commentTextArea}
            valueLength={validationComment.field.value?.length}
            isDisplayMode={candidateR2Data?.isClosed}
          />
          <div className={styles.separator} />
          <div className={styles.title}>projection professionnelle</div>
          <TextAreaWithLightTitleWithDisplayMode
            control={control}
            name={'professionalProjections.projections'}
            containerClassName={styles.container}
            title="commentaires"
            textAreaClassName={styles.textAreaWidth}
            valueLength={professionalProjection?.length}
            isDisplayMode={candidateR2Data?.isClosed}
            placeholder="perspectives de repositionnement chez un autre client en fonction des compétences"
          />
          <WithLightTitle
            title="scoring du potentiel de repositionnement du candidat"
            className={styles.container}
          >
            <ScoringButtonWithDisplayMode
              value={scoringController.field.value}
              onChange={scoringController.field.onChange}
              isDisplayMode={candidateR2Data?.isClosed}
            />
          </WithLightTitle>
          <TextAreaWithLightTitleWithDisplayMode
            control={control}
            isDisplayMode={candidateR2Data?.isClosed}
            containerClassName={styles.container}
            title="commentaires"
            name={'professionalProjections.comment'}
            textAreaClassName={styles.textAreaWidth}
            valueLength={professionalComment?.length}
          />
        </>
      )}
      {canModifyR2 && (
        <FetchButton
          secondary
          title="valider projections"
          errorTitle="réessayer"
          fetchStatus={isSubmitting ? FETCH_STATUS.FULFILLED : updateFetchStatus}
          className={styles.button}
          onClick={projectionsHandleSubmit()}
          onSuccess={() => toast.success('Votre R2 a été modifié')}
          onError={() => toast.error("Erreur lors de l'enregistrement de votre R2")}
        />
      )}
      {Object.keys(formState.errors).length !== 0 && (
        <div className={styles.errorMessage}>
          il y a des erreurs ou des champs non remplis dans le formulaire
        </div>
      )}
    </>
  );
};

export default R2CandidateProjections;
