import {
  Button,
  FetchButton,
  Modal,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  TextAreaWithLightTitle,
  TextInput,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import React from 'react';

import { ExemptionIllus } from 'src/Assets';
import { useCloseCandidateR2 } from 'src/Hooks/CandidateR2/useCloseCandidateR2';
import { useFetchCandidateETests } from 'src/Hooks/CandidateR2/useFetchCandidateETests';
import { useFetchCandidateR2 } from 'src/Hooks/CandidateR2/useFetchCandidateR2';
import { FETCH_STATUS } from 'src/Redux/Types';
import { YesNoChoicesEnum } from 'src/Services/API';
import { mergeSeveralFetchStatus, toFetchStatus } from 'src/Services/ReactQuery';

import styles from './ExemptionValidationModal.module.scss';
import { interviewExemptionValidationSchema, Props } from './ExemptionValidationModal.types';

const ExemptionValidationModal = ({ isOpen, close, interviewId, candidateId }: Props) => {
  const { control, handleSubmit, reset, watch } = useFormWithZodResolver({
    schema: interviewExemptionValidationSchema,
    defaultValues: { comment: '', decisionMaker: '' },
  });
  const candidateR2Query = useFetchCandidateR2(interviewId);
  const eTests = useFetchCandidateETests(candidateId);
  const fetchStatus = mergeSeveralFetchStatus([
    toFetchStatus(candidateR2Query),
    toFetchStatus(eTests),
  ]);
  const validationComment = watch('comment');
  const { mutate, ...mutation } = useCloseCandidateR2(interviewId);
  return (
    <Modal
      open={isOpen}
      title="valider par dérogation"
      icon={<ExemptionIllus className={styles.modalIcon} />}
      onClose={() => {
        reset();
        close();
      }}
      footerActionsRight={[
        <Button.Secondary onClick={() => close()}>annuler</Button.Secondary>,
        <FetchButton
          disabled={fetchStatus !== FETCH_STATUS.FULFILLED}
          fetchStatus={toFetchStatus(mutation)}
          title="valider"
          onClick={handleSubmit(({ comment, decisionMaker }) => {
            if (candidateR2Query.data !== undefined) {
              mutate({
                candidateR2: {
                  ...candidateR2Query.data,
                  eTests: eTests.data,
                  validation: {
                    ...candidateR2Query.data.validation,
                    validation: YesNoChoicesEnum.non,
                    isCDI: true,
                  },
                },
                exemptionComment: comment,
                exemptionMakerName: decisionMaker,
              });
            }
          })}
          onSuccess={() => close()}
        />,
      ]}
    >
      <WithLightTitle title="nom du valideur">
        <TextInput
          control={control}
          name="decisionMaker"
          placeholder="renseigner le nom du valideur"
        />
      </WithLightTitle>
      <TextAreaWithLightTitle
        valueLength={validationComment.length}
        title="détails sur la validation"
        control={control}
        name="comment"
        containerClassName={styles.comment}
        textAreaClassName={styles.textArea}
        placeHolder="decrivez les raisons de la validation par dérogation"
      />
    </Modal>
  );
};

export default ExemptionValidationModal;
