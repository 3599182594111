import React, { useState } from 'react';

import {
  CandidateResumeQualification,
  CandidateResumeSkill,
} from './Qualifications/Qualifications.types';

const initQualifications = (): CandidateResumeQualification => {
  return { hasQualifications: false, qualifications: [] };
};

const initSkills = (): CandidateResumeSkill => {
  return { businessesSkills: [], behaviourSkills: [] };
};

export const CandidateResumeContext = React.createContext({
  qualifications: initQualifications(),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  setQualifications: (qualifications: CandidateResumeQualification) => {},
  skills: initSkills(),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  setSkills: (skills: CandidateResumeSkill) => {},
  isNextEnabled: true,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  setIsNextEnabled: (isNextEnabled: boolean) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  resetContext: () => {},
});

export function useCandidateResumeContext() {
  const [qualifications, setQualifications] = useState(initQualifications());
  const [skills, setSkills] = useState(initSkills());
  const [isNextEnabled, setIsNextEnabled] = useState<boolean>(true);

  const resetContext = () => {
    setQualifications(initQualifications());
    setSkills(initSkills());
  };

  return {
    qualifications,
    setQualifications,
    skills,
    setSkills,
    resetContext,
    isNextEnabled,
    setIsNextEnabled,
  };
}
