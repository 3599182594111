import {
  Badge,
  TextInput,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  CircleError,
  CloseOld,
  PlusInCircle,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useState } from 'react';

import styles from './AddBar.module.scss';
import { Props } from './AddBar.types';

const AddBar = ({ items, setItems }: Props) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleAddItem = () => {
    if (inputValue.trim() !== '') {
      setItems(prevItems => [...prevItems, inputValue.trim()]);
      setInputValue('');
    }
  };

  const handleRemoveItem = (index: number) => {
    setItems(prevItems => prevItems.filter((_, i) => i !== index));
  };

  return (
    <div className={styles.itemsSearchBar}>
      <WithLightTitle
        title="Ajouter des compétences comportementales (10 max)"
        titleClassName={styles.title}
        className={styles.searchBar}
      >
        <TextInput
          value={inputValue}
          onChange={handleInputChange}
          rightIcon={
            <>
              <CloseOld className={styles.cross} onClick={() => setInputValue('')} />
              <PlusInCircle className={styles.plus} onClick={handleAddItem} />
            </>
          }
        />
      </WithLightTitle>
      {items.length > 0 && (
        <WithLightTitle
          title="compétences ajoutées"
          titleClassName={styles.title}
          className={styles.itemsBadges}
        >
          {items.map((item, index) => (
            <Badge
              className={styles.badge}
              key={index}
              value={item}
              rightIcon={
                <CircleError
                  className={styles.deleteIcon}
                  onClick={() => handleRemoveItem(index)}
                />
              }
            />
          ))}
        </WithLightTitle>
      )}
    </div>
  );
};

export default AddBar;
