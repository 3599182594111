import {
  Button,
  FetchButton,
  Modal,
  PopupActions,
  RadioButtonGroup,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  UploadCloud,
  BDPExportHeader,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import moment from 'moment';
import React, { useRef, useState } from 'react';

import { useExportInterviewsData } from 'src/Hooks/Interviews/useExportInterviewsData';
import { toFetchStatus } from 'src/Services/ReactQuery';

import styles from './ExportModal.module.scss';

const ExportModal = () => {
  const ref = useRef<PopupActions>(null);
  const [exportYear, setExportYear] = useState<Date | undefined>();
  const currentYear = moment().get('year').toString();
  const exportMutation = useExportInterviewsData();
  const fetchStatus = toFetchStatus(exportMutation);
  const lastYear = moment().subtract(1, 'year').get('year').toString();
  return (
    <Modal
      title="export des données"
      ref={ref}
      icon={<BDPExportHeader />}
      onClose={() => setExportYear(undefined)}
      trigger={
        <div className={styles.trigger}>
          <UploadCloud />
          <p className={styles.triggerButton}>Exporter les données sur l'année</p>
        </div>
      }
      footerActionsRight={[
        <Button.Secondary onClick={() => ref.current?.close()}>annuler</Button.Secondary>,
        <FetchButton
          title="valider"
          disabled={exportYear === undefined}
          fetchStatus={fetchStatus}
          onSuccess={() => {
            ref.current?.close();
          }}
          onClick={() => {
            exportMutation.mutate({ startDate: exportYear ?? new Date() });
          }}
        />,
      ]}
    >
      <p className={styles.text}>sur quelle année voulez vous exporter les données ?</p>
      <RadioButtonGroup
        name="yearForExport"
        radioButtonsInfo={[
          {
            id: moment().subtract(1, 'year').startOf('year').toISOString(),
            value: lastYear,
            label: lastYear,
          },
          { id: moment().startOf('year').toISOString(), value: currentYear, label: currentYear },
        ]}
        onChange={(_, id) => setExportYear(new Date(id))}
      />
    </Modal>
  );
};

export default ExportModal;
