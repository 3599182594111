import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'src/Redux/RootReducer';

import { CompletionStatusState } from './Types';

export const getCompletionStatusState = (state: RootState) => state.completionStatus;

export const getRequiredFields = createSelector(
  [getCompletionStatusState],
  (state: CompletionStatusState) => state.requiredFields
);

export default getCompletionStatusState;
