import {
  Button,
  PopupMenu,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  AscendingOrder,
  DescendingOrder,
  Sort,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useState } from 'react';

import { SORT_OPTIONS, SORT_ORDER } from '../Background.types';

import styles from './SortOptions.module.scss';
import { Props } from './SortOptions.types';

export const popUpMenuSortIcons = {
  [SORT_ORDER.ASCENDING]: <AscendingOrder />,
  [SORT_ORDER.DESCENDING]: <DescendingOrder />,
};

const SortOptions = ({
  selectedSortOption,
  setSelectedSortOption,
  selectedSortOrder,
  setSelectedSortOrder,
}: Props) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={styles.container}>
      <PopupMenu
        open={open}
        position="left top"
        width={240}
        onClose={() => {
          setOpen(false);
        }}
        onOpen={() => {
          setOpen(true);
        }}
        trigger={
          <Button.Tertiary
            leftIcon={<Sort />}
            className={classnames(styles.filterButton, { [styles.openPopup]: open })}
          />
        }
        selectedIds={[selectedSortOption, selectedSortOrder]}
      >
        <div className={styles.popupContentContainer}>
          <WithLightTitle title="par ordre" titleClassName={styles.title}>
            {Object.entries(SORT_ORDER).map(([_, value]) => (
              <PopupMenu.Item
                key={value}
                id={value}
                text={value}
                onClick={() => setSelectedSortOrder(value)}
                className={styles.popUpItem}
                keepOpenOnClick={true}
                icon={popUpMenuSortIcons[value]}
              />
            ))}
          </WithLightTitle>
          <WithLightTitle title="type d'affichage" titleClassName={styles.title}>
            {Object.entries(SORT_OPTIONS).map(([_, value]) => (
              <PopupMenu.Item
                key={value}
                id={value}
                text={value}
                onClick={() => setSelectedSortOption(value)}
                className={styles.popUpItem}
                keepOpenOnClick={true}
              />
            ))}
          </WithLightTitle>
        </div>
      </PopupMenu>
    </div>
  );
};

export default SortOptions;
