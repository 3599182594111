import { Button, Modal, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { IllusRLPotential } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useState } from 'react';

import CriteriaCard from 'src/Components/CriteriaCard';
import SelectPotentialPositions from 'src/Containers/Selects/SelectPotentialPositions';

import styles from './PotentialPositionModal.module.scss';
import { Props } from './PotentialPositionModal.types';

const PotentialPositionModal = ({
  potentialPositions,
  onPotentialPositionChange,
  onClose,
}: Props) => {
  const [selectedPotentialPositions, setSelectedPotentialPositions] = useState(potentialPositions);

  return (
    <Modal
      icon={<IllusRLPotential />}
      nested
      onClose={onClose}
      title="pré-affectations"
      open
      footerActionsLeft={[
        <Button.Tertiary
          onClick={() => {
            setSelectedPotentialPositions(potentialPositions);
          }}
        >
          tout réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <Button.Primary
          onClick={() => {
            onPotentialPositionChange(selectedPotentialPositions);
            onClose();
          }}
        >
          valider
        </Button.Primary>,
      ]}
    >
      <SelectPotentialPositions
        setPotentialPositions={setSelectedPotentialPositions}
        selectedPotentialPositions={selectedPotentialPositions}
      />
      {selectedPotentialPositions.length > 0 && (
        <div className={styles.listContainer}>
          <WithLightTitle title={`pré-affectation(s) ajoutée(s)`}>
            {selectedPotentialPositions.map(potentialPosition => (
              <React.Fragment key={potentialPosition.identifier}>
                <div className={styles.separator}></div>
                <CriteriaCard
                  className={styles.criteriaCard}
                  title={potentialPosition.label ?? ''}
                  onDelete={() => {
                    const potentialPositions = selectedPotentialPositions.filter(
                      otherpotentialPosition =>
                        otherpotentialPosition.identifier !== potentialPosition.identifier
                    );
                    setSelectedPotentialPositions(potentialPositions);
                  }}
                />
              </React.Fragment>
            ))}
            <div className={styles.separator}></div>
          </WithLightTitle>
        </div>
      )}
    </Modal>
  );
};

export default PotentialPositionModal;
