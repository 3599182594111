import { combineReducers } from '@reduxjs/toolkit';

import { completionStatusReducer } from './CompletionStatus/Slice';
import { interviewsStatsReducer } from './InterviewsStats/Slice';
import { missionsReducer } from './Missions/Slice';
import { perimeterReducer } from './Perimeter/Slice';
import { searchReducer } from './Search/Slice';
import { AppDispatch } from './store';
import { userPreferencesReducer } from './UserPreferences/Slice';

const rootReducer = combineReducers({
  completionStatus: completionStatusReducer,
  perimeter: perimeterReducer,
  search: searchReducer,
  userPreferences: userPreferencesReducer,
  missions: missionsReducer,
  interviewsStats: interviewsStatsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export interface ThunkAPI {
  dispatch: AppDispatch;
  state: RootState;
  extra?: unknown;
  rejectValue?: unknown;
}

export default rootReducer;
