import { Button, Loader } from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import React from 'react';

import styles from './ResumeButton.module.scss';
import { Props } from './ResumeButton.types';

const ResumeButton = ({ title, className, onClick, isLoading, disabled }: Props) => {
  return (
    <Button.Secondary.XSmall
      className={classnames(styles.container, className, { [styles.noHover]: isLoading })}
      onClick={onClick}
      disabled={disabled}
    >
      {isLoading ? <Loader heightInRem={1.2} darkMode /> : title}
    </Button.Secondary.XSmall>
  );
};

export default ResumeButton;
