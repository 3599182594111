import React from 'react';

import ResumeButton from 'src/Components/ResumeButton';
import { useOpenOSMResume } from 'src/Hooks/Candidates';

import { Props } from './OSMResumeButton.types';

const OSMResumeButton = ({ candidateId, className, disabled, candidateName }: Props) => {
  const { mutate, isLoading } = useOpenOSMResume();
  return (
    <ResumeButton
      isLoading={isLoading}
      className={className}
      onClick={() => mutate({ candidateId, candidateName })}
      title="CV osmose"
      disabled={disabled}
    />
  );
};

export default OSMResumeButton;
