import { Button, Modal, WithLightTitle } from '@randstad-lean-mobile-factory/react-components-core';
import { IllusRLService } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useState } from 'react';

import CriteriaCard from 'src/Components/CriteriaCard';
import SelectService from 'src/Containers/Selects/SelectService';

import styles from './ServiceModal.module.scss';
import { Props } from './ServiceModal.types';

const ServiceModal = ({ service, onServiceChange, onClose }: Props) => {
  const [selectedService, setSelectedService] = useState(service);

  return (
    <Modal
      icon={<IllusRLService />}
      onClose={onClose}
      nested
      open
      title="services"
      footerActionsLeft={[
        <Button.Tertiary
          onClick={() => {
            setSelectedService(service);
          }}
        >
          tout réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <Button.Primary
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            onServiceChange(selectedService!);
            onClose();
          }}
        >
          valider
        </Button.Primary>,
      ]}
    >
      <SelectService selectedService={selectedService} setService={setSelectedService} />
      {selectedService && (
        <div className={styles.listContainer}>
          <WithLightTitle title={`service ajouté`}>
            <div className={styles.separator}></div>
            <CriteriaCard
              className={styles.criteriaCard}
              title={selectedService.label ?? ''}
              weight={selectedService.wishPriority}
              onWeightChange={weight => {
                const newService = {
                  label: selectedService.label,
                  identifier: selectedService.identifier,
                  wishPriority: weight,
                };
                setSelectedService(newService);
              }}
              onDelete={() => setSelectedService(undefined)}
            />

            <div className={styles.separator}></div>
          </WithLightTitle>
        </div>
      )}
    </Modal>
  );
};

export default ServiceModal;
