/* eslint-disable @typescript-eslint/no-empty-function */
import { Button, PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import {
  BackTo,
  BigActionButton,
  Calendrier,
  DocumentPDF,
  Flux,
  Pointer,
  Refresh,
  ThreeDots,
  Trashcan,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import DeleteR1Modal from 'src/Containers/Modals/DeleteR1Modal';
import ExemptionValidationModal from 'src/Containers/Modals/ExemptionValidationModal';
import InterviewClosedByTTModal from 'src/Containers/Modals/InterviewClosedByTTModal';
import R2PlanningModal from 'src/Containers/Modals/R2PlanningModal';
import { useGenerateR1Pdf } from 'src/Hooks/CandidateR1/useGenerateR1Pdf';
import { useCreateCandidateR2 } from 'src/Hooks/CandidateR2/useCreateCandidateR2';
import { useGenerateR2Pdf } from 'src/Hooks/CandidateR2/useGenerateR2Pdf';
import { useCreateCandidateR1 } from 'src/Hooks/Candidates/useCreateCandidateR1';
import { useResetCandidateInterviews } from 'src/Hooks/Interviews/useResetCandidateInterviews';
import { getPerimeterUnits } from 'src/Redux/Perimeter/Selectors';
import { FETCH_STATUS } from 'src/Redux/Types';
import { CandidateInterviewsSummaryStatus } from 'src/Services/API';
import { getR1CandidateRoute, getR2CandidateRoute } from 'src/Services/Routing';
import { onFileOpen } from 'src/Utils/onFileOpen';

import R1ReopenModal from '../R1ReopenModal';

import styles from './DetailButton.module.scss';
import { Props } from './DetailButton.types';

const DetailButton = ({
  candidateInterviewsIdAndStatus,
  candidateId,
  candidateType,
  candidateIdFetchStatus,
  showReassignCGC,
  pageActionButton,
}: Props) => {
  const {
    status,
    r1Interview,
    r2Interview,
    agencyId: interviewsAgencyId,
    cgcId: interviewsCgcId,
  } = candidateInterviewsIdAndStatus ?? {};
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [hasOpenR1ReopeningModal, showR1ReopeningModal] = useState(false);
  const [hasOpenClosedByTTModal, showCloseByTTModal] = useState(false);
  const [hasOpenForceValidationModal, showForceValidationModal] = useState(false);
  const [deleteR1, setDeleteR1] = useState(false);
  const [hasOpenR2PlanningModal, showR2PlanningModal] = useState(false);
  const units = useSelector(getPerimeterUnits);
  const generateR1InterviewPdf = useGenerateR1Pdf();
  const generateR2InterviewPdf = useGenerateR2Pdf();
  const createR1Mutation = useCreateCandidateR1(candidateId, {
    onSuccess: data => {
      history.push(
        getR1CandidateRoute({
          candidateId: data.candidateId,
          R1Id: data.R1Id,
        }),
        { origin: history.location.pathname }
      );
    },
  });
  const createR2Mutation = useCreateCandidateR2(candidateId, {
    onSuccess: data => {
      history.push(getR2CandidateRoute({ candidateId: data.candidateId, R2Id: data.R2Id }), {
        origin: history.location.pathname,
      });
    },
  });
  const resetInterviewsMutation = useResetCandidateInterviews(candidateId, {
    onSuccess: data => {
      history.push(
        getR1CandidateRoute({
          candidateId: data.candidateId,
          R1Id: data.R1Id,
        }),
        { origin: history.location.pathname }
      );
    },
  });

  const isFromInterviewsAgency = interviewsAgencyId && units.includes(interviewsAgencyId);
  const isFromInterviewsCGC = interviewsCgcId && units.includes(interviewsCgcId);

  const options: {
    text: string;
    action: () => void;
    icon: React.ReactNode;
    keepOpenOnclick?: boolean;
  }[] = [];
  if (r1Interview?.interviewId === undefined && r2Interview?.interviewId === undefined) {
    // No existing interviews, we can only create a R1
    options.push({
      icon: <Flux />,
      text: 'initier un R1',
      action: () => {
        createR1Mutation.mutate();
      },
    });
  } else {
    if (isFromInterviewsAgency || isFromInterviewsCGC) {
      // We can only access the R1 from its unit or from its CGC
      options.push({
        icon: <Flux />,
        text:
          status === CandidateInterviewsSummaryStatus['R1 en cours']
            ? 'accéder au R1'
            : 'visualiser le R1',
        action: () => {
          history.push(
            getR1CandidateRoute({
              candidateId,
              R1Id: r1Interview?.interviewId,
            }),
            { origin: history.location.pathname }
          );
        },
      });
      if (r1Interview && r2Interview?.interviewId === undefined) {
        options.push({
          keepOpenOnclick: true,
          icon: generateR1InterviewPdf.isLoading ? (
            <Refresh className={styles.loading} />
          ) : (
            <DocumentPDF />
          ),
          text: 'générer le PDF',
          action: () => {
            generateR1InterviewPdf.mutate(r1Interview.interviewId ?? '', {
              onSuccess: buffer => {
                onFileOpen(buffer, 'application/pdf');
              },
            });
          },
        });
      }
    }
    if (
      (status === CandidateInterviewsSummaryStatus['R2 en cours'] ||
        status === CandidateInterviewsSummaryStatus['R2 refusé']) &&
      isFromInterviewsCGC
    ) {
      options.push({
        icon: <Pointer />,
        text: 'valider par dérogation',
        action: () => {
          showForceValidationModal(true);
        },
      });
    }
    if (
      status === CandidateInterviewsSummaryStatus['R2 à faire'] ||
      status === CandidateInterviewsSummaryStatus['R2 en planification'] ||
      status === CandidateInterviewsSummaryStatus['R2 planifié']
    ) {
      if (isFromInterviewsCGC) {
        if (showReassignCGC) {
          // We can only reassign CGC on an accepted R1 with no R2 from the R1's CGC
          options.push({
            icon: <Flux />,
            text: 'réattribuer à un CGC',
            action: () => history.push(`/reassignCgc/${r1Interview?.interviewId}`),
          });
        }
        // We can only create a R2 while on the R1's CGC
        options.push({
          icon: <Flux />,
          text: 'initier un R2',
          action: () => {
            createR2Mutation.mutate();
          },
        });
      }
      if (isFromInterviewsAgency) {
        // We can only delete a R1 from its unit and when it has no R2
        options.push({
          icon: <Trashcan />,
          text: 'supprimer le R1',
          action: () => setDeleteR1(true),
        });
      }
    }
    if (
      status === CandidateInterviewsSummaryStatus['R2 à faire'] ||
      status === CandidateInterviewsSummaryStatus['R2 en planification'] ||
      status === CandidateInterviewsSummaryStatus['R2 planifié']
    ) {
      // We can only add actions to R2 planning if it's pending, in planning, or planned
      options.push({
        icon: <Calendrier />,
        text: 'planifier le R2',
        action: () => {
          showR2PlanningModal(true);
        },
      });
    }
    if (
      status !== undefined &&
      (r2Interview?.interviewId !== undefined || r1Interview?.interviewId !== undefined) &&
      [
        CandidateInterviewsSummaryStatus['R2 à faire'],
        CandidateInterviewsSummaryStatus['R2 en cours'],
        CandidateInterviewsSummaryStatus['R2 en planification'],
        CandidateInterviewsSummaryStatus['R2 planifié'],
      ].includes(status) &&
      isFromInterviewsCGC
    ) {
      options.push({
        icon: <BackTo />,
        text: "retourner le R1 à l'agence",
        action: () => showR1ReopeningModal(true),
      });
    }
    if (r2Interview?.interviewId !== undefined && (isFromInterviewsCGC || isFromInterviewsAgency)) {
      // We can only modify a R2 from its CGC and see it from its R1's unit
      options.push({
        icon: <Flux />,
        text:
          status !== CandidateInterviewsSummaryStatus['R2 en cours']
            ? 'visualiser le R2'
            : 'accéder au R2',
        action: () => {
          history.push(
            getR2CandidateRoute({
              candidateId,
              R2Id: r2Interview?.interviewId,
            }),
            { origin: history.location.pathname }
          );
        },
      });
      options.push({
        keepOpenOnclick: true,
        icon: generateR2InterviewPdf.isLoading ? (
          <Refresh className={styles.loading} />
        ) : (
          <DocumentPDF />
        ),
        text: 'générer le PDF',
        action: () => {
          generateR2InterviewPdf.mutate(r2Interview.interviewId ?? '', {
            onSuccess: buffer => onFileOpen(buffer, 'application/pdf'),
          });
        },
      });
    }
    if (
      status === CandidateInterviewsSummaryStatus['R1 refusé'] ||
      status === CandidateInterviewsSummaryStatus['R2 refusé'] ||
      status === CandidateInterviewsSummaryStatus['refusé par intérimaire'] ||
      (status === CandidateInterviewsSummaryStatus['R2 terminé'] &&
        r2Interview?.hiringDate &&
        moment(r2Interview.hiringDate).isBefore())
    ) {
      // We can only restart the process if it was refused before or before the specified hiring date
      options.push({
        icon: <Flux />,
        text: 'initier un nouveau R1',
        action: () =>
          resetInterviewsMutation.mutate({
            r1Id: r1Interview?.interviewId ?? '',
            r2Id: r2Interview?.interviewId,
          }),
      });
    }
    if (
      status !== undefined &&
      status !== CandidateInterviewsSummaryStatus['R1 refusé'] &&
      status !== CandidateInterviewsSummaryStatus['refusé par intérimaire'] &&
      status !== CandidateInterviewsSummaryStatus['R2 refusé'] &&
      status !== CandidateInterviewsSummaryStatus['validé par dérogation'] &&
      !(
        status === CandidateInterviewsSummaryStatus['R2 terminé'] &&
        moment(r2Interview?.updatedAt).add(1, 'month').isBefore(moment())
      )
    ) {
      options.push({
        icon: <Flux />,
        text: 'refus du CDII par le TT',
        action: () => showCloseByTTModal(true),
      });
    }
  }

  return candidateType === 'CDI' || options.length === 0 ? (
    <div className={styles.noActions} />
  ) : (
    <div>
      <PopupMenu
        className={styles.popupMenu}
        position="bottom right"
        width={200}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        trigger={
          <Button.Tertiary
            className={classnames(
              styles.trigger,
              { [styles.activeTrigger]: isOpen },
              { [styles.largeTrigger]: pageActionButton }
            )}
          >
            {pageActionButton ? (
              <BigActionButton className={styles.bigIcon} />
            ) : (
              <ThreeDots className={styles.icon} />
            )}
          </Button.Tertiary>
        }
      >
        {options.map(option => (
          <PopupMenu.Item
            key={option.text}
            icon={option.icon}
            text={option.text}
            disabled={candidateIdFetchStatus !== FETCH_STATUS.FULFILLED}
            onClick={option.action}
            keepOpenOnClick={option.keepOpenOnclick}
            className={styles.popupMenuItem}
          />
        ))}
      </PopupMenu>
      <R1ReopenModal
        isOpen={hasOpenR1ReopeningModal}
        close={() => showR1ReopeningModal(false)}
        status={status ?? CandidateInterviewsSummaryStatus['R1 en cours']}
        interviewId={r2Interview?.interviewId ?? r1Interview?.interviewId ?? ''}
      />
      <InterviewClosedByTTModal
        isOpen={hasOpenClosedByTTModal}
        close={() => showCloseByTTModal(false)}
        status={status ?? CandidateInterviewsSummaryStatus['R1 en cours']}
        interviewId={r2Interview?.interviewId ?? r1Interview?.interviewId ?? ''}
      />
      <ExemptionValidationModal
        isOpen={hasOpenForceValidationModal}
        close={() => showForceValidationModal(false)}
        interviewId={r2Interview?.interviewId ?? r1Interview?.interviewId ?? ''}
        candidateId={candidateId}
      />
      <DeleteR1Modal open={deleteR1} onClose={() => setDeleteR1(false)} r1Interview={r1Interview} />
      <R2PlanningModal
        open={hasOpenR2PlanningModal}
        close={() => showR2PlanningModal(false)}
        candidateId={candidateId}
        agencyId={interviewsAgencyId}
        r1InterviewId={r1Interview?.interviewId}
      />
    </div>
  );
};

export default DetailButton;
