import moment from 'moment';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSearchCriteria } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';
import { WishPriority } from 'src/Services/API';

interface CriteriaTableItem {
  items: {
    identifier: string;
    label: string;
    weight?: WishPriority;
    radius?: number;
  }[];
  label: string;
  notMandatory?: boolean;
  onDelete: (identifier: string) => void;
  onWeightUpdate?: (identifier: string, weight: WishPriority) => void;
  onRadiusUpdate?: (radius: number) => void;
}

export const useActiveCriteria = () => {
  const dispatch = useDispatch();
  const criteria = useSelector(getSearchCriteria);
  const resetAvailability = useCallback(() => {
    dispatch(searchActions.resetAvailability());
  }, [dispatch]);
  const removeQualifications = useCallback(
    (id: string) => {
      dispatch(searchActions.removeQualification(id));
    },
    [dispatch]
  );
  const onSkillWeightUpdate = useCallback(
    (id: string, weight: WishPriority) => {
      dispatch(searchActions.updateSkillWeight({ id, weight }));
    },
    [dispatch]
  );
  const removeSkill = useCallback(
    (id: string) => {
      dispatch(searchActions.removeSkill(id));
    },
    [dispatch]
  );
  const removeLocation = useCallback(() => {
    dispatch(searchActions.removeLocation());
  }, [dispatch]);
  const onQualificationWeightUpdate = useCallback(
    (id: string, weight: WishPriority) => {
      dispatch(searchActions.updateQualificationWeight({ id, weight }));
    },
    [dispatch]
  );
  const onLocationRadiusUpdate = useCallback(
    (radius: number) => {
      dispatch(searchActions.updateLocationRadius(radius));
    },
    [dispatch]
  );
  const removeDiploma = useCallback(
    (id: string) => {
      dispatch(searchActions.removeDiploma(id));
    },
    [dispatch]
  );
  const onDiplomaWeightUpdate = useCallback(
    (id: string, weight: WishPriority) => {
      dispatch(searchActions.updateDiplomaWeight({ id, weight }));
    },
    [dispatch]
  );
  const removeDrivingLicense = useCallback(
    (id: string) => {
      dispatch(searchActions.removeDrivingLicense(id));
    },
    [dispatch]
  );
  const onDrivingLicenseWeightUpdate = useCallback(
    (id: string, weight: WishPriority) => {
      dispatch(searchActions.updateDrivingLicenseWeight({ id, weight }));
    },
    [dispatch]
  );
  const removeCompany = useCallback(() => {
    dispatch(searchActions.removeCompany());
  }, [dispatch]);
  const onCompanyWeightUpdate = useCallback(
    (id: string, weight: WishPriority) => {
      dispatch(searchActions.updateCompanyWeight(weight));
    },
    [dispatch]
  );
  const removeService = useCallback(() => {
    dispatch(searchActions.removeService());
  }, [dispatch]);
  const onServiceWeightUpdate = useCallback(
    (id: string, weight: WishPriority) => {
      dispatch(searchActions.updateServiceWeight(weight));
    },
    [dispatch]
  );
  const removePotentialPosition = useCallback(
    (id: string) => {
      dispatch(searchActions.removePotentialPosition(id));
    },
    [dispatch]
  );
  const removeLanguage = useCallback(
    (id: string) => {
      dispatch(searchActions.removeLanguage(id));
    },
    [dispatch]
  );
  const onLanguageWeightUpdate = useCallback(
    (id: string, weight: WishPriority) => {
      dispatch(searchActions.updateLanguageWeight({ id, weight }));
    },
    [dispatch]
  );
  const removeHabilitation = useCallback(
    (id: string) => {
      dispatch(searchActions.removeHabilitation(id));
    },
    [dispatch]
  );
  const onHabilitationWeightUpdate = useCallback(
    (id: string, weight: WishPriority) => {
      dispatch(searchActions.updateHabilitationWeight({ id, weight }));
    },
    [dispatch]
  );
  const removeCaces = useCallback(
    (id: string) => {
      dispatch(searchActions.removeCaces(id));
    },
    [dispatch]
  );
  const onCacesWeightUpdate = useCallback(
    (id: string, weight: WishPriority) => {
      dispatch(searchActions.updateCacesWeight({ id, weight }));
    },
    [dispatch]
  );
  const removeKeyword = useCallback(
    (id: string) => {
      dispatch(searchActions.removeKeyword(id));
    },
    [dispatch]
  );
  const onKeywordWeightUpdate = useCallback(
    (id: string, weight: WishPriority) => {
      dispatch(searchActions.updateKeywordWeight({ id, weight }));
    },
    [dispatch]
  );

  const criteriaTable: CriteriaTableItem[] = [
    {
      items: criteria.qualifications.map(qualification => ({
        label: qualification.label ?? '',
        identifier: qualification.identifier,
        weight: qualification.wishPriority,
      })),
      label: 'qualifications',
      notMandatory: criteria.qualifications.length > 1,
      onDelete: removeQualifications,
      onWeightUpdate: onQualificationWeightUpdate,
    },
    {
      items: criteria.skills.map(skill => ({
        label: skill.label ?? '',
        identifier: skill.identifier,
        weight: skill.wishPriority,
      })),
      label: 'compétences',
      onDelete: removeSkill,
      onWeightUpdate: onSkillWeightUpdate,
    },
    {
      items: criteria.diplomas.map(diploma => ({
        label: diploma.label ?? '',
        identifier: diploma.identifier,
        weight: diploma.wishPriority,
      })),
      label: 'diplômes',
      onDelete: removeDiploma,
      onWeightUpdate: onDiplomaWeightUpdate,
    },
    {
      items: criteria.company
        ? [
            {
              identifier: criteria.company.identifier,
              label: criteria.company.label ?? '',
              weight: criteria.company.wishPriority,
            },
          ]
        : [],
      label: 'comptes',
      onDelete: removeCompany,
      onWeightUpdate: onCompanyWeightUpdate,
    },
    {
      items: criteria.service
        ? [
            {
              identifier: criteria.service.identifier,
              label: criteria.service.label ?? '',
              weight: criteria.service.wishPriority,
            },
          ]
        : [],
      label: 'services',
      onDelete: removeService,
      onWeightUpdate: onServiceWeightUpdate,
    },
    {
      items: criteria.potentialPositions.map(potentialPosition => ({
        label: potentialPosition.label ?? '',
        identifier: potentialPosition.identifier,
      })),
      label: 'pré-affectations',
      onDelete: removePotentialPosition,
    },
    {
      items: criteria.availabilityDate
        ? [
            {
              label: moment(criteria.availabilityDate).format('L'),
              identifier: moment(criteria.availabilityDate).format('L'),
            },
          ]
        : [],
      label: 'disponibilités',
      onDelete: resetAvailability,
    },
    {
      items: criteria.location
        ? [
            {
              identifier: criteria.location.zipCode,
              label: criteria.location.label ?? '',
              radius: criteria.location.radius,
            },
          ]
        : [],
      label: 'villes',
      onDelete: removeLocation,
      onRadiusUpdate: onLocationRadiusUpdate,
    },
    {
      items: criteria.drivingLicenses.map(drivingLicense => ({
        label: `Permis ${drivingLicense.identifier}`,
        identifier: drivingLicense.identifier,
        weight: drivingLicense.wishPriority,
      })),
      label: 'permis',
      onDelete: removeDrivingLicense,
      onWeightUpdate: onDrivingLicenseWeightUpdate,
    },
    {
      items: criteria.languages.map(language => ({
        label: language.label ?? '',
        identifier: language.identifier,
        weight: language.wishPriority,
      })),
      label: 'langues',
      onDelete: removeLanguage,
      onWeightUpdate: onLanguageWeightUpdate,
    },
    {
      items: criteria.habilitations.map(habilitation => ({
        label: habilitation.label ?? '',
        identifier: habilitation.identifier,
        weight: habilitation.wishPriority,
      })),
      label: 'habilitations',
      onDelete: removeHabilitation,
      onWeightUpdate: onHabilitationWeightUpdate,
    },
    {
      items: criteria.caces.map(caces => ({
        label: caces.label ?? '',
        identifier: caces.identifier,
        weight: caces.wishPriority,
      })),
      label: 'caces',
      onDelete: removeCaces,
      onWeightUpdate: onCacesWeightUpdate,
    },
    {
      items: criteria.keywords.map(keyword => ({
        label: keyword.label ?? '',
        identifier: keyword.identifier,
        weight: keyword.wishPriority,
      })),
      label: 'mots clés',
      onDelete: removeKeyword,
      onWeightUpdate: onKeywordWeightUpdate,
    },
  ];

  return criteriaTable;
};
