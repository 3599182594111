import { IllusRLLicenseSmall } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React from 'react';
import { useSelector } from 'react-redux';

import CriteriaButton from 'src/Components/CriteriaButton';
import { useModalWithLocation } from 'src/Hooks/Modals';
import { hasDrivingLicense } from 'src/Redux/Search/Selectors';

import styles from './DrivingLicenseButton.module.scss';

const DrivingLicenseButton = () => {
  const _hasDrivingLicense = useSelector(hasDrivingLicense);
  const drivingLicenseModal = useModalWithLocation('driving-license');
  return (
    <div>
      <CriteriaButton
        label="permis"
        icon={<IllusRLLicenseSmall className={styles.icon} />}
        selected={_hasDrivingLicense}
        onClick={() => drivingLicenseModal.open()}
      />
    </div>
  );
};

export default DrivingLicenseButton;
