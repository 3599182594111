import {
  Badge,
  HorizontalCardWithTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';

import styles from './CandidateAgency.module.scss';
import { Props } from './CandidateAgency.types';

const CandidateAgency = ({ agency }: Props) => {
  const agenceResponsable = agency.main ? '| Agence Responsable' : '';
  const dateText = `date inscription : ${moment(agency.inscriptionDate).format(
    'DD/MM/YYYY'
  )} | dernier contact : ${moment(agency.lastContactDate).format('DD/MM/YYYY')}`;

  return (
    <HorizontalCardWithTitle
      className={styles.container}
      title={agency.agencyName ?? ''}
      subtitles={[`${agency.agencyId} ${agenceResponsable}`, dateText]}
      rightActions={
        (agency.cdiAgency && (
          <div className={styles.text}>
            <span>dans cette agence</span>
            <Badge value="en CDI" className={styles.badgeCDI} />
          </div>
        )) ??
        ''
      }
    />
  );
};

export default CandidateAgency;
