import { Button, LetterBadge } from '@randstad-lean-mobile-factory/react-components-core';
import {
  CheckmarkCircleOutline,
  Contract,
  Pencil,
  People,
  Timing,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import moment from 'moment';
import React from 'react';

import {
  CANDIDATE_STATUS_LABEL,
  getCandidateStatusStyle,
} from 'src/Containers/CandidateResults/CandidateResults.types';
import OSMResumeButton from 'src/Containers/CandidateResults/OSMResumeButton';
import PersonalResumeButton from 'src/Containers/CandidateResults/PersonalResumeButton';
import { useOpenCandidateFile } from 'src/Hooks/Navigation/useOpenCandidateFile';

import ScoringIndicator from '../ScoringIndicator';

import styles from './ListItem.module.scss';
import { Props } from './ListItem.types';

const ListItem = ({ candidate, canBeSelected, isSelected, onSelectionChange }: Props) => {
  const { openCandidateFile } = useOpenCandidateFile();
  const fullName = `${candidate.firstName} ${candidate.name}`;
  const qualification = candidate.mainQualification?.label ?? '';
  const location = `${candidate.zipCode} ${candidate.city}`;
  const contractEndDate = moment(candidate.availabilityDate).isAfter(moment())
    ? moment(candidate.availabilityDate)
    : undefined;

  return (
    <div
      className={classnames(styles.container, styles.containerUnselected, {
        [styles.containerSelected]: canBeSelected && isSelected,
      })}
      onClick={() => {
        if (canBeSelected) {
          onSelectionChange(!isSelected);
        }
      }}
    >
      <div
        className={classnames(
          styles.leftContainer,
          getCandidateStatusStyle(candidate.candidateStatus)
        )}
      >
        <div>
          <LetterBadge
            text={fullName
              .split(/\s/)
              .map((word: string) => word[0])
              .join('')
              .substring(0, 2)}
            className={classnames({
              [styles.whiteBadge]:
                candidate.candidateStatus === CANDIDATE_STATUS_LABEL['CDI Interim'],
            })}
          />
          <div className={styles.fullName}>{fullName}</div>
          <div className={styles.qualification}>{qualification}</div>
          <div className={styles.location}>{location}</div>
        </div>
        <div className={styles.topContainerSelected}>
          {canBeSelected ? (
            isSelected ? (
              <CheckmarkCircleOutline className={styles.checkMark} />
            ) : (
              <div className={styles.checkMarkEmpty} />
            )
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.middleSection}>
          <div className={styles.middleSectionTopPart}>
            <div>
              <div className={styles.contractType}>
                {(() => {
                  switch (candidate.candidateStatus) {
                    case CANDIDATE_STATUS_LABEL.Intérimaire:
                      return 'intérimaire';
                    case CANDIDATE_STATUS_LABEL['CDI Interim']:
                      return 'CDI intérimaire';
                    case CANDIDATE_STATUS_LABEL['Candidat évalué']:
                      return 'candidat';
                  }
                })()}
              </div>
              <div className={styles.origin}>{`origine : ${candidate.origin}`}</div>
            </div>
            {contractEndDate !== undefined && (
              <div className={styles.contractLineContainer}>
                <Contract className={styles.contractIcon} />
                <div className={styles.contractEnd}>en contrat</div>
                <div className={styles.contractEnd}>{`fin ${contractEndDate.format(
                  'DD/MM/YYYY'
                )}`}</div>
              </div>
            )}
          </div>
          <div className={styles.separator}></div>
          <div className={styles.bigScoringContainer}>
            {candidate.scoring !== 0 && candidate.scoring !== undefined && (
              <div className={styles.bigScoring}>
                <div className={styles.scoringSubtitle}>pertinence du profil</div>
                <ScoringIndicator scoring={candidate.scoring} />
              </div>
            )}
          </div>
        </div>
        <div className={styles.rightSection}>
          <div className={styles.imageIndicator}>
            <Timing className={styles.smallIcon} />
            <div className={styles.indicatorText}>{`${candidate.nbHours} hrs`}</div>
          </div>
          <div className={styles.rightSectionSeparator} />
          <div className={styles.imageIndicator}>
            <People className={styles.smallIcon} />
            <div className={styles.indicatorText}>{`${candidate.nbCompany} clients`}</div>
          </div>
          <div className={styles.rightSectionSeparator} />
          <div className={styles.imageIndicator}>
            <Pencil className={styles.smallIcon} />
            <div className={styles.indicatorText}>{`${candidate.nbMissions} contrats`}</div>
          </div>
        </div>
        <div className={styles.buttonContainer} onClick={event => event.stopPropagation()}>
          <OSMResumeButton
            candidateId={candidate.candidateId ?? ''}
            candidateName={fullName}
            className={styles.resumeButton}
            disabled={candidate.candidateStatus === CANDIDATE_STATUS_LABEL['Candidat']}
          />
          <PersonalResumeButton
            candidateId={candidate.candidateId ?? ''}
            candidateName={fullName}
            className={styles.resumeButton}
          />
          {!canBeSelected && (
            <Button.Primary
              className={styles.fileButton}
              onClick={() =>
                openCandidateFile({ candidateId: candidate.candidateId, emitAnalyticsEvent: true })
              }
            >
              voir dossier
            </Button.Primary>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListItem;
