import {
  FetchButton,
  Message,
  toast,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { EuroSign } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useController, useFieldArray, useFormState } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import AssessmentQuestion from 'src/Components/AssessmentQuestion/AssessmentQuestion.component';
import DatePickerInputWithDisplayMode from 'src/Components/WithDisplayMode/DatePickerInputWithDisplayMode';
import TextAreaWithLightTitleWithDisplayMode from 'src/Components/WithDisplayMode/TextAreaWithLightTitleWithDisplayMode';
import TextInputWithDisplayMode from 'src/Components/WithDisplayMode/TextInputWithDisplayMode';
import YesNoUnknownComponent from 'src/Components/YesNoUnknown';
import InterviewValidationModal from 'src/Containers/Modals/InterviewsModals';
import InterviewRefusalModal from 'src/Containers/Modals/InterviewsModals/InterviewRefusalModal';
import { useCloseCandidateR1 } from 'src/Hooks/Candidates/useCloseCandidateR1';
import { useFetchCandidateExperiences } from 'src/Hooks/Candidates/useFetchCandidateExperiences';
import { useFetchCandidateR1FromURL } from 'src/Hooks/Candidates/useFetchCandidateR1FromURL';
import { useFetchCandidateSkillsFromURL } from 'src/Hooks/Candidates/useFetchCandidateSkillsFromURL';
import { useFetchLastYearCandidateContracts } from 'src/Hooks/Candidates/useFetchLastYearCandidateContracts';
import { useFetchR1AssessmentQuestions } from 'src/Hooks/Candidates/useFetchR1AssessmentQuestions';
import { useUpdateCandidateR1 } from 'src/Hooks/Candidates/useUpdateCandidateR1';
import { FormStatusContext } from 'src/Hooks/Navigation/useFormNavigationContextElements';
import { useOpenCandidateFile } from 'src/Hooks/Navigation/useOpenCandidateFile';
import { useFetchSMICAmount } from 'src/Hooks/Repositories/useFetchSMICAmount';
import { getCurrentBrand, getPerimeter, getPerimeterUnits } from 'src/Redux/Perimeter/Selectors';
import { FETCH_STATUS } from 'src/Redux/Types';
import { R1AssessmentResponse, YesNoUnknown } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/ReactQuery';

import styles from './CandidateAssessment.module.scss';
import {
  CandidateAssessmentSchemaType,
  KeyOfCandidateAssessmentSchemaTypeQuestion,
  useCandidateAssessmentSchema,
} from './CandidateAssessment.type';

const recruiterTextArea = {
  title: 'motivation du recruteur (+/-)',
  placeHolder: '',
  name: 'recruiterComment' as KeyOfCandidateAssessmentSchemaTypeQuestion,
};

const supervisorTextArea = {
  title: 'commentaires RA/DA',
  placeHolder: '',
  name: 'supervisorComment' as KeyOfCandidateAssessmentSchemaTypeQuestion,
};

const keyPointsTextArea = {
  title: 'points à ré-aborder lors du R2 CGC',
  placeHolder: '1. ...',
  name: 'keyPoints' as KeyOfCandidateAssessmentSchemaTypeQuestion,
};

export enum ButtonType {
  VALIDATE,
  FINISH,
}

const CandidateAssessment = () => {
  const { openCandidateFile } = useOpenCandidateFile();
  const units = useSelector(getPerimeterUnits);
  const perimeter = useSelector(getPerimeter);
  const isInHouse =
    perimeter?.defaultAgencyId !== undefined
      ? perimeter?.companies
          ?.find(item => item.brand?.code === 'RIS')
          ?.agencies?.includes(perimeter.defaultAgencyId)
      : false;

  const params = useParams<{
    id: string;
    candidateId: string;
  }>();
  const history = useHistory();
  const currentBrand = useSelector(getCurrentBrand);
  const {
    data: candidateR1Data,
    isLoading: isLoadingCandidateR1,
    isSuccess: isCandidateR1Success,
    isFetching: isFetchingCandidateR1,
  } = useFetchCandidateR1FromURL();
  const {
    data: questions,
    isLoading: isLoadingQuestions,
    isSuccess: isQuestionsSuccess,
    isFetching: isFetchingQuestions,
  } = useFetchR1AssessmentQuestions();
  const {
    data: skillsData,
    isLoading: isLoadingSkills,
    isSuccess: isSkillsSuccess,
    isFetching: isFetchingSkills,
  } = useFetchCandidateSkillsFromURL();
  const {
    data: candidateExperiencesData,
    isLoading: isLoadingExperiences,
    isSuccess: isExperiencesSuccess,
  } = useFetchCandidateExperiences(params.candidateId);
  const candidateLastYearContractsQuery = useFetchLastYearCandidateContracts(
    params.candidateId ?? ''
  );
  const {
    data: smicData,
    isLoading: isLoadingSmic,
    isSuccess: isSmicSuccess,
    isFetching: isFetchingSmic,
  } = useFetchSMICAmount();
  const smic = smicData?.smic.monthlyAmount;
  const responses = questions as R1AssessmentResponse[];
  const candidateR1Update = useUpdateCandidateR1(params.id);
  const candidateR1Close = useCloseCandidateR1(params.id);
  const validateStatus = toFetchStatus(candidateR1Update);
  const finishStatus = toFetchStatus(candidateR1Close);
  const [buttonType, setButtonType] = useState(ButtonType.VALIDATE);
  const [validationModal, setValidationModal] = useState(false);
  const [refusalModal, setRefusalModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState([] as JSX.Element[]);

  const { control, setValue, handleSubmit, watch, formState, reset } = useFormWithZodResolver({
    schema: useCandidateAssessmentSchema(),
    defaultValues: {
      assessment: candidateR1Data?.assessment ?? { responses },
      recruiterComment: candidateR1Data?.recruiterComment,
      supervisorComment: candidateR1Data?.supervisorComment,
      keyPoints: candidateR1Data?.keyPoints,
      supervisorAnswer:
        currentBrand?.brand?.code === 'RIS'
          ? YesNoUnknown.Yes
          : candidateR1Data?.supervisorAnswer ?? YesNoUnknown.Unknown,
      choiceDate: candidateR1Data?.choiceDate,
      plannedGMMRAmount: candidateR1Data?.plannedGMMRAmount
        ? candidateR1Data?.plannedGMMRAmount.toString()
        : undefined,
    },
  });

  const errorRedirection = (destination: string) => {
    if (formStatusContext.hasFormBeenTouched) {
      formStatusContext.openNavWarningModal({ destination: destination });
      formStatusContext.setFetchStatus(FETCH_STATUS.FULFILLED);
    } else {
      history.push(destination);
    }
  };

  const mandatoryElements = [
    {
      key: 'hiringDate',
      label: (
        <div>
          Vous devez renseigner la date prévisionelle d'embauche sur l'écran
          <p className={styles.link} onClick={() => errorRedirection('context')}>
            d'information contexte
          </p>
        </div>
      ),
      value: !!candidateR1Data?.hiringDate,
    },
    {
      key: 'hasVehicle',
      label: (
        <div>
          Le candidat doit avoir une autonomie de déplacement sur l'écran
          <p className={styles.link} onClick={() => errorRedirection('profil')}>
            profil candidat
          </p>
        </div>
      ),
      value: !!candidateR1Data?.hasVehicle,
    },
    {
      key: 'mobility',
      label: (
        <div>
          La mobilité du candidat n'est pas conforme sur l'écran
          <p className={styles.link} onClick={() => errorRedirection('profil')}>
            profil candidat
          </p>
        </div>
      ),
      value:
        (candidateR1Data?.maxMobility?.maxMobilityDistance ?? 0) >= 40 &&
        (candidateR1Data?.maxMobility?.maxMobilityTime ?? 0) >= 75,
    },
    {
      key: 'agreement',
      label: (
        <div>
          Vous devez valider la compréhension du CDII par le candidat sur l'écran
          <p className={styles.link} onClick={() => errorRedirection('profil')}>
            profil candidat
          </p>
        </div>
      ),
      value: !!candidateR1Data?.candidateAgreement,
    },
    {
      key: 'choiceDate',
      label: <div>Vous devez compléter la date de décision</div>,
      value: !!watch('choiceDate'),
    },
    {
      key: 'supervisorAnswer',
      label: <div>Le responsable/directeur d'agence doit valider le formulaire</div>,
      value: !!watch('supervisorAnswer') && watch('supervisorAnswer') !== YesNoUnknown.Unknown,
    },
    {
      key: 'amount',
      label: <div>Merci de renseigner le montant GMMR</div>,
      value: !!watch('plannedGMMRAmount'),
    },
    {
      key: 'cgc',
      value: candidateR1Data?.cgcId !== undefined,
      label: (
        <div>
          Vous devez renseigner le CGC affilié sur l'écran
          <p className={styles.link} onClick={() => errorRedirection('context')}>
            d'information contexte
          </p>
        </div>
      ),
    },
    {
      key: 'jobs',
      value:
        skillsData?.jobs?.filter(
          job =>
            candidateR1Data?.selectedJobs?.find(selectedJob => selectedJob.id === job.id) !==
            undefined
        ).length === 3,
      label: (
        <div>
          Vous devez retenir 3 métiers parmi ceux du candidat pour les entretiens sur l'écran
          <p className={styles.link} onClick={() => errorRedirection('profil')}>
            profil candidat
          </p>
        </div>
      ),
    },
  ];

  const isDisplayMode = useMemo(
    () =>
      candidateR1Data && (candidateR1Data.isClosed || !units.includes(candidateR1Data.agencyName)),
    [candidateR1Data, units]
  );

  const assessmentHandleSubmit = (options?: { finish?: boolean; force?: boolean }) => {
    if (candidateR1Data && !isDisplayMode) {
      const handleSubmitSuccess = (values: CandidateAssessmentSchemaType) => {
        const updateBody = {
          ...candidateR1Data,
          assessment: {
            responses: values.assessment.responses.map(response => {
              return { id: response.id, label: response.label, value: response.value };
            }),
          },
          recruiterComment: values.recruiterComment,
          supervisorComment: values.supervisorComment,
          keyPoints: values.keyPoints,
          supervisorAnswer: values.supervisorAnswer,
          choiceDate: values.choiceDate,
          plannedGMMRAmount: values.plannedGMMRAmount
            ? parseFloat(values.plannedGMMRAmount)
            : undefined,
        };
        const closeBody = {
          ...updateBody,
          languages: skillsData?.languages,
          habilitations: skillsData?.habilitations,
          diplomas: skillsData?.diplomas,
          jobs: skillsData?.jobs,
          mainQualificationId: skillsData?.qualifications?.find(qualification => qualification.main)
            ?.id,
          lastYearContractsInfo: candidateLastYearContractsQuery.data,
          experiences: candidateExperiencesData,
        };
        if (options?.finish) {
          candidateR1Close.mutate(closeBody);
        } else {
          candidateR1Update.mutate(updateBody);
        }
        reset(values, { keepValues: true });
      };
      setErrorMessage([]);
      setButtonType(options?.finish ? ButtonType.FINISH : ButtonType.VALIDATE);
      if (options?.finish) {
        switch (supervisorAnswer.field.value) {
          case YesNoUnknown.Yes:
            if (!mandatoryElements.every(item => item.value)) {
              setErrorMessage(
                mandatoryElements.filter(item => !item.value).map(elem => elem.label)
              );
            } else {
              setButtonType(ButtonType.FINISH);
              handleSubmit(values => handleSubmitSuccess(values))();
            }
            break;
          case YesNoUnknown.No:
            if (!watch('choiceDate')) {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              setErrorMessage([mandatoryElements.find(elem => elem.key === 'choiceDate')!.label]);
            } else {
              setRefusalModal(true);
            }
            break;
          default:
            setErrorMessage([
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              mandatoryElements.find(elem => elem.key === 'supervisorAnswer')!.label,
            ]);
        }
      } else {
        setButtonType(ButtonType.VALIDATE);
        handleSubmit(values => handleSubmitSuccess(values))();
      }
    }
  };

  useEffect(() => {
    if (isCandidateR1Success && isQuestionsSuccess) {
      setValue('assessment.responses', candidateR1Data?.assessment?.responses ?? responses);
      setValue('recruiterComment', candidateR1Data?.recruiterComment);
      setValue('supervisorComment', candidateR1Data?.supervisorComment);
      setValue('keyPoints', candidateR1Data?.keyPoints);
      setValue(
        'supervisorAnswer',
        currentBrand?.brand?.code === 'RIS'
          ? YesNoUnknown.Yes
          : candidateR1Data?.supervisorAnswer ?? YesNoUnknown.Unknown
      );
      candidateR1Data?.choiceDate && setValue('choiceDate', new Date(candidateR1Data?.choiceDate));
      setValue(
        'plannedGMMRAmount',
        candidateR1Data?.plannedGMMRAmount?.toString() ?? smic?.toString() ?? ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCandidateR1Success, isQuestionsSuccess]);
  const supervisorAnswer = useController({ name: 'supervisorAnswer', control });
  const choiceDate = useController({ name: 'choiceDate', control });
  const { fields, update } = useFieldArray({
    control,
    name: 'assessment.responses',
    keyName: 'key',
    shouldUnregister: true,
  });

  const { isDirty, isSubmitting, isSubmitted, isSubmitSuccessful } = useFormState({
    control: control,
  });
  const formStatusContext = useContext(FormStatusContext);
  useEffect(() => {
    formStatusContext.setHasFormBeenTouched(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
    formStatusContext.updateValidationStatus({
      handleSubmit: () => assessmentHandleSubmit as () => Promise<void>,
      fetchStatus: validateStatus,
      formStateElements: { isSubmitSuccessful, isSubmitted, isSubmitting },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted, isSubmitting, isSubmitSuccessful, validateStatus]);

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (errorMessage.length > 0 && messagesEndRef.current) scrollToBottom();
  }, [errorMessage]);

  return (
    <>
      <div className={styles.title}>
        évaluation<div className={styles.subTitle}>(note 1 = faible, note 3 = bon)</div>
      </div>
      {(isLoadingCandidateR1 ||
        isFetchingCandidateR1 ||
        isLoadingSkills ||
        isFetchingSkills ||
        isLoadingQuestions ||
        isFetchingQuestions ||
        isLoadingExperiences ||
        !fields.length) && (
        <ContentLoader height="5rem" width="100%" uniqueKey="assessmentQuestions">
          <rect x="2%" y="10" rx="4" ry="4" width="80%" height="20" />
        </ContentLoader>
      )}
      {isCandidateR1Success &&
        !isFetchingCandidateR1 &&
        isQuestionsSuccess &&
        !isFetchingQuestions &&
        isSkillsSuccess &&
        !isFetchingSkills &&
        isExperiencesSuccess &&
        !!fields.length && (
          <div className={styles.container}>
            {fields.map((field, index) => (
              <AssessmentQuestion
                isDisplayMode={isDisplayMode}
                key={field.id}
                response={field}
                onChange={response => {
                  update(index, { ...response });
                }}
              />
            ))}
          </div>
        )}
      <div className={styles.separator} />
      <div className={styles.title}>choix sur le candidat</div>
      {(isLoadingCandidateR1 || isFetchingCandidateR1 || isLoadingSmic || isFetchingSmic) && (
        <ContentLoader height="5rem" width="100%" uniqueKey="assessmentChoices">
          <rect x="2%" y="10" rx="4" ry="4" width="80%" height="20" />
        </ContentLoader>
      )}
      {isCandidateR1Success && isSmicSuccess && !isFetchingCandidateR1 && (
        <div className={styles.container}>
          <TextAreaWithLightTitleWithDisplayMode
            isDisplayMode={isDisplayMode}
            textAreaClassName={styles.textArea}
            title={recruiterTextArea.title}
            placeHolder={recruiterTextArea.placeHolder}
            control={control}
            name={recruiterTextArea.name}
            valueLength={watch(recruiterTextArea.name)?.toString().length}
          />
          {currentBrand?.brand?.code !== 'RIS' && (
            <YesNoUnknownComponent
              isDisplayMode={isDisplayMode}
              label={
                isInHouse
                  ? 'Candidat retenu par le Responsable de compte'
                  : 'le/la candidat(e) retenu(e) par le RA/DA'
              }
              value={supervisorAnswer.field.value}
              onChange={supervisorAnswer.field.onChange}
            />
          )}
          <div className={styles.choice}>
            <WithLightTitle className={styles.date} title="choix acté le">
              <DatePickerInputWithDisplayMode
                isDisplayMode={isDisplayMode}
                date={choiceDate.field.value}
                onSelectDate={choiceDate.field.onChange}
              />
            </WithLightTitle>
            <WithLightTitle title="montant GMMR envisagé en €">
              <TextInputWithDisplayMode
                isDisplayMode={isDisplayMode}
                control={control}
                name="plannedGMMRAmount"
                rightIcon={<EuroSign />}
                type="number"
                defaultValue={smic?.toString()}
              />
            </WithLightTitle>
          </div>
          {currentBrand?.brand?.code !== 'RIS' && (
            <TextAreaWithLightTitleWithDisplayMode
              isDisplayMode={isDisplayMode}
              textAreaClassName={styles.textArea}
              title={supervisorTextArea.title}
              placeHolder={supervisorTextArea.placeHolder}
              control={control}
              name={supervisorTextArea.name}
              valueLength={watch(supervisorTextArea.name)?.toString().length}
            />
          )}
          <div className={styles.marginContainer}>
            <TextAreaWithLightTitleWithDisplayMode
              isDisplayMode={isDisplayMode}
              textAreaClassName={styles.textArea}
              title={keyPointsTextArea.title}
              placeHolder={keyPointsTextArea.placeHolder}
              control={control}
              name={keyPointsTextArea.name}
              valueLength={watch(keyPointsTextArea.name)?.toString().length}
            />
          </div>
        </div>
      )}
      {!isDisplayMode && (
        <div className={styles.btnGroup}>
          <FetchButton
            secondary
            title="enregistrer bilan de l'entretien"
            errorTitle="réessayer"
            fetchStatus={
              buttonType === ButtonType.VALIDATE && !isSubmitting
                ? validateStatus
                : FETCH_STATUS.FULFILLED
            }
            className={styles.button}
            onClick={() => assessmentHandleSubmit()}
            onSuccess={() => {
              if (buttonType === ButtonType.VALIDATE) toast.success('Votre R1 a été modifié');
            }}
            onError={() => {
              if (buttonType === ButtonType.VALIDATE) {
                toast.error("Erreur lors de l'enregistrement de votre R1");
              }
            }}
          />
          <div className={styles.closeBtn}>
            <FetchButton
              title="transmettre le R1 au CGC"
              errorTitle="réessayer"
              fetchStatus={
                buttonType === ButtonType.FINISH && !isSubmitting
                  ? finishStatus
                  : FETCH_STATUS.FULFILLED
              }
              className={styles.validateButton}
              onClick={() => assessmentHandleSubmit({ finish: true })}
              onSuccess={() => {
                if (buttonType === ButtonType.FINISH) setValidationModal(true);
              }}
              onError={() => {
                if (buttonType === ButtonType.FINISH) {
                  toast.error("Erreur lors de l'envoie de votre R1");
                }
              }}
            />
          </div>
        </div>
      )}
      {(errorMessage.length > 0 || Object.keys(formState.errors).length !== 0) && (
        <Message.Error className={styles.errorMessage}>
          <>
            <div className={styles.globalErrorMessage}>
              il y a des erreurs ou des champs non remplis dans le formulaire
            </div>
            {errorMessage.map(error => (
              <ul className={styles.globalErrorMessage} key={error.toString()}>
                {error}
              </ul>
            ))}
            <div ref={messagesEndRef} />
          </>
        </Message.Error>
      )}
      <InterviewValidationModal
        open={validationModal}
        isR1
        onClose={() => {
          setValidationModal(false);
          openCandidateFile({ candidateId: candidateR1Data?.candidateId });
        }}
      />
      <InterviewRefusalModal
        open={refusalModal}
        onClose={() => setRefusalModal(false)}
        fetchStatus={finishStatus}
        onSuccess={() => {
          setRefusalModal(false);
          openCandidateFile({ candidateId: candidateR1Data?.candidateId });
        }}
        onValidate={() =>
          candidateR1Data &&
          candidateR1Close.mutate({
            ...candidateR1Data,
            languages: skillsData?.languages,
            habilitations: skillsData?.habilitations,
            diplomas: skillsData?.diplomas,
            experiences: candidateExperiencesData,
            assessment: watch('assessment'),
            recruiterComment: watch('recruiterComment'),
            supervisorComment: watch('supervisorComment'),
            keyPoints: watch('keyPoints'),
            supervisorAnswer: watch('supervisorAnswer'),
            choiceDate: watch('choiceDate'),
            plannedGMMRAmount: watch('plannedGMMRAmount')
              ? parseFloat(watch('plannedGMMRAmount') as string)
              : undefined,
          })
        }
      />
    </>
  );
};

export default CandidateAssessment;
