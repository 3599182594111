import { Button, FetchButton, Modal } from '@randstad-lean-mobile-factory/react-components-core';
import React from 'react';

import { IllusWarning } from 'src/Assets';

import { Props } from './InterviewRefusalModal.types';

const InterviewRefusalModal = ({ open, onClose, onValidate, onSuccess, fetchStatus }: Props) => {
  return (
    <Modal
      onClose={onClose}
      title="Attention"
      open={open}
      icon={<IllusWarning />}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <FetchButton
          title="valider"
          fetchStatus={fetchStatus}
          onClick={onValidate}
          onSuccess={onSuccess}
        />,
      ]}
    >
      <div>
        Vous vous apprêtez à fermer l'entretien en refusant le candidat. Confirmez vous ce choix ?
      </div>
    </Modal>
  );
};

export default InterviewRefusalModal;
