import {
  AddButton,
  Button,
  Checkbox,
  ComboBox,
  FetchButton,
  Modal,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  CutFinger,
  Interversion,
  PlusCircle,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useFormWithZodResolver } from '@randstad-lean-mobile-factory/react-form-fields';
import moment from 'moment';
import React, { useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import { useController } from 'react-hook-form';

import { useFetchCompaniesWithThrottle } from 'src/Hooks/Company';
import { useFetchMissions } from 'src/Hooks/Missions/useFetchMissions';
import { useFetchQualificationsWithThrottle } from 'src/Hooks/Qualifications/useFetchQualifications';
import { OSMCompanyModelCompanyDtoCompanyAddressDto } from 'src/Services/API';
import { toFetchStatus } from 'src/Services/ReactQuery';

import styles from './MissionSearchModal.module.scss';
import { missionSearchModalSchema, Props } from './MissionSearchModal.types';

const MissionSearchModal = ({
  innerRef,
  selectedMissions,
  selectedCompany,
  selectedQualification,
  setSelectedCompany,
  setSelectedQualification,
  setSelectedMissions,
  missionDetailFetchStatus,
}: Props) => {
  const qualificationThrottledMutation = useFetchQualificationsWithThrottle();
  const companiesThrottledMutation = useFetchCompaniesWithThrottle();
  const missionSearchMutation = useFetchMissions();

  const { control, handleSubmit } = useFormWithZodResolver({
    schema: missionSearchModalSchema,
    defaultValues: {
      selectedCompany: selectedCompany,
      selectedQualification: selectedQualification,
      selectedMissionIds: selectedMissions.map(mission => mission.missionId),
    },
  });
  const qualificationController = useController({ control, name: 'selectedQualification' });
  const companyController = useController({ control, name: 'selectedCompany' });
  const selectedMissionsController = useController({ control, name: 'selectedMissionIds' });

  useEffect(() => {
    if (
      companyController.field.value !== undefined &&
      companyController.field.value !== null &&
      qualificationController.field.value !== undefined &&
      qualificationController.field.value !== null
    ) {
      missionSearchMutation.mutate({
        companyId: companyController.field.value.id ?? '',
        qualificationId: qualificationController.field.value.id ?? '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyController.field.value, qualificationController.field.value]);
  return (
    <Modal
      ref={innerRef}
      icon={<CutFinger />}
      onOpen={() => {
        selectedMissionsController.field.onChange(
          selectedMissions.map(mission => mission.missionId) ?? []
        );

        if (selectedCompany) {
          companyController.field.onChange(selectedCompany);
        }
        if (selectedQualification) {
          qualificationController.field.onChange(selectedQualification);
        }

        selectedCompany !== undefined &&
          selectedMissions !== undefined &&
          missionSearchMutation.mutate({
            companyId: selectedCompany?.id ?? companyController.field.value?.id ?? '',
            qualificationId:
              selectedQualification?.id ?? qualificationController.field.value?.id ?? '',
          });
      }}
      onClose={() => {
        companyController.field.onChange(undefined);
        qualificationController.field.onChange(undefined);
        selectedMissionsController.field.onChange([]);
      }}
      title="sélection de commande"
      trigger={
        <div className={styles.triggerModification}>
          {selectedMissions.length === 0 ? (
            <>
              <p className={styles.missionTriggerText}>0 commande associée</p>
              <span>
                <AddButton
                  className={styles.spanText}
                  icon={<PlusCircle />}
                  text="ajouter une commande"
                />
              </span>
            </>
          ) : (
            <>
              <p className={styles.missionTriggerText}>
                {`${
                  selectedMissions.length === 1
                    ? `Pour la commande `
                    : `Pour les ${selectedMissions.length} commandes `
                }(${selectedMissions.map(mission => mission.missionId).join(', ')})`}
              </p>
              <AddButton className={styles.spanText} icon={<Interversion />} text="modifier" />
            </>
          )}
        </div>
      }
      footerActionsLeft={[
        <Button.Tertiary
          onClick={() => {
            companyController.field.onChange(undefined);
            qualificationController.field.onChange(undefined);
            selectedMissionsController.field.onChange([]);
          }}
        >
          tout réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary onClick={innerRef.current?.close}>annuler</Button.Secondary>,
        <FetchButton
          disabled={
            !qualificationController.field.value ||
            !companyController.field.value ||
            (selectedMissionsController.field.value.length === 0 && selectedMissions.length === 0)
          }
          onClick={handleSubmit(values => {
            setSelectedCompany(
              selectedMissionsController.field.value.length > 0 ? values.selectedCompany : undefined
            );
            setSelectedQualification(
              values.selectedMissionIds.length > 0 ? values.selectedQualification : undefined
            );
            setSelectedMissions(
              missionSearchMutation.data?.filter(mission =>
                values.selectedMissionIds.includes(mission.missionId ?? '')
              ) ?? []
            );
          })}
          fetchStatus={missionDetailFetchStatus}
          title="valider"
          onSuccess={() => {
            innerRef.current?.close();
          }}
        />,
      ]}
    >
      <WithLightTitle title="choix de la qualification" className={styles.combobox}>
        <ComboBox
          id="qualification"
          value={qualificationController.field.value ?? null}
          minLengthToSearch={1}
          keyValueExtractor={(qualification: { id?: string; label?: string }) => ({
            key: qualification.id ?? '',
            value: qualification.label ?? '',
          })}
          placeholder="1 caractère minimum"
          onSearch={searchText =>
            searchText !== '' && qualificationThrottledMutation.mutate(searchText)
          }
          fetchStatus={toFetchStatus(qualificationThrottledMutation)}
          searchResults={
            qualificationThrottledMutation.data?.map(qualification => {
              return { id: qualification.identifier, label: qualification.label };
            }) ?? []
          }
          onChange={qualification =>
            qualificationController.field.onChange(qualification ?? undefined)
          }
        />
      </WithLightTitle>
      <WithLightTitle title="choix du compte" className={styles.combobox}>
        <ComboBox
          id="compte"
          value={companyController.field.value ?? null}
          minLengthToSearch={1}
          withSubLabel
          keyValueExtractor={(company: {
            id?: string;
            label?: string;
            siret?: string;
            companyAddress?: OSMCompanyModelCompanyDtoCompanyAddressDto;
          }) => ({
            key: company.id ?? '',
            value: company.label ? `${company.label} (${company.siret ?? 'siret inconnu'})` : '',
            subValue: `${company.companyAddress?.postalCode} - ${company.companyAddress?.city}`,
          })}
          onSearch={searchText =>
            searchText !== '' && companiesThrottledMutation.mutate(searchText)
          }
          fetchStatus={toFetchStatus(companiesThrottledMutation)}
          placeholder="1 caractère minimum"
          searchResults={
            companiesThrottledMutation.data?.map(company => {
              return {
                id: company.identifier,
                label: company.label,
                siret: company.siret,
                companyAddress: company.companyAddress,
              };
            }) ?? []
          }
          onChange={company => companyController.field.onChange(company ?? undefined)}
        />
      </WithLightTitle>
      {qualificationController.field.value !== undefined &&
        companyController.field.value !== undefined && (
          <>
            {missionSearchMutation.isLoading && (
              <ContentLoader height="5rem" width="100%" uniqueKey="mission-search">
                <rect x="2%" y="10" rx="4" ry="4" width="90%" height="16" />
                <rect x="2%" y="30" rx="4" ry="4" width="90%" height="16" />
                <rect x="2%" y="50" rx="4" ry="4" width="90%" height="16" />
              </ContentLoader>
            )}
            {missionSearchMutation.isSuccess && missionSearchMutation.data.length > 0 && (
              <>
                <div className={styles.selectAllCheckbox}>
                  <Checkbox
                    label="tout sélectionner"
                    className={styles.checkbox}
                    halfChecked={selectedMissionsController.field.value.length > 0}
                    checked={
                      selectedMissionsController.field.value.length ===
                      missionSearchMutation.data?.length
                    }
                    onChange={() =>
                      selectedMissionsController.field.value.length > 0
                        ? selectedMissionsController.field.onChange([])
                        : selectedMissionsController.field.onChange(
                            missionSearchMutation.data?.map(item => item.missionId ?? '') ?? []
                          )
                    }
                  />
                  <p
                    className={styles.missionCount}
                  >{`${selectedMissionsController.field.value.length} commande(s) ajoutée(s)`}</p>
                </div>
                <div className={styles.checkboxBorderTop}>
                  {missionSearchMutation.data?.map(mission => {
                    const isChecked = selectedMissionsController.field.value?.includes(
                      mission.missionId ?? ''
                    );
                    return (
                      <div key={mission.missionId} className={styles.checkbox}>
                        <Checkbox
                          key={mission.missionId}
                          children={
                            <div className={styles.checkboxChildren}>
                              <div
                                className={styles.missionNumber}
                              >{`${mission.missionId} - ${mission.company?.name}`}</div>
                              <div className={styles.missionInfo}>
                                {`${mission.qualification?.label} | début le ${moment(
                                  mission.startDate
                                ).format('L')}`}
                              </div>
                            </div>
                          }
                          checked={isChecked}
                          onChange={() =>
                            isChecked
                              ? selectedMissionsController.field.onChange(
                                  selectedMissionsController.field.value?.filter(
                                    missionItem => missionItem !== mission.missionId
                                  )
                                )
                              : selectedMissionsController.field.onChange([
                                  ...selectedMissionsController.field.value,
                                  mission.missionId ?? '',
                                ])
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            {missionSearchMutation.isSuccess && missionSearchMutation.data.length === 0 && (
              <div>
                Nous n'avons pas trouvé de commande à servir pour la qualification et le compte
                sélectionné
              </div>
            )}
          </>
        )}
    </Modal>
  );
};

export default MissionSearchModal;
