import BusinessesSkills from './ProfessionalData1/BusinessesSkills.component';
import Qualifications from './Qualifications/Qualifications.component';

interface CandidateResumeNavigationNode {
  name: string;
  default: string;
  path: string;
  routes: { name: string; linkTo: string; component?: () => JSX.Element; path: string }[];
}

export type CandidateResumeNav = { [key: string]: CandidateResumeNavigationNode };

const baseRoute = '/candidate/:candidateId/resume';

export const navigation: CandidateResumeNav = {
  qualifications: {
    name: 'qualification',
    default: './qualifications',
    path: `${baseRoute}/qualifications`,
    routes: [
      {
        name: 'qualifications',
        linkTo: './qualifications',
        path: `${baseRoute}/qualifications`,
        component: Qualifications,
      },
    ],
  },
  data_pro: {
    name: 'données professionnelles',
    default: './data_pro',
    path: `${baseRoute}/data_pro`,
    routes: [
      {
        name: 'données professionnelles 1/3',
        linkTo: './1',
        path: `${baseRoute}/data_pro/1`,
        component: BusinessesSkills,
      },
      {
        name: 'données professionnelles 2/3',
        linkTo: './2',
        path: `${baseRoute}/data_pro/2`,
        component: Qualifications,
      },
      {
        name: 'données professionnelles 3/3',
        linkTo: './3',
        path: `${baseRoute}/data_pro/3`,
        component: Qualifications,
      },
    ],
  },
  employee_data: {
    name: 'informations employé',
    default: './employee_data',
    path: `${baseRoute}/employee_data`,
    routes: [
      {
        name: 'informations employé',
        linkTo: './employee_data',
        path: `${baseRoute}/employee_data`,
        component: Qualifications,
      },
    ],
  },
  description: {
    name: 'description',
    default: './description',
    path: `${baseRoute}/description`,
    routes: [
      {
        name: 'description',
        linkTo: './description',
        path: `${baseRoute}/description`,
        component: Qualifications,
      },
    ],
  },
  overview: {
    name: 'aperçu',
    default: './overview',
    path: `${baseRoute}/overview`,
    routes: [
      {
        name: 'aperçu',
        linkTo: './overview',
        path: `${baseRoute}/overview`,
        component: Qualifications,
      },
    ],
  },
};
