import * as z from 'zod';

export const businessesSkillsSchema = z
  .object({
    businessesSkills: z.array(z.object({ id: z.string(), label: z.string() })).optional(),
    behaviourSkills: z.array(z.string()).optional(),
  })
  .refine(({ businessesSkills, behaviourSkills }) => {
    return (
      (businessesSkills && businessesSkills.length > 0) ||
      (behaviourSkills && behaviourSkills.length > 0)
    );
  });
