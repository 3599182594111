import * as z from 'zod';

export const qualificationsSchema = z
  .object({
    hasQualifications: z.boolean().optional(),
    qualifications: z.array(
      z.object({ qualificationId: z.string(), label: z.string(), main: z.boolean() })
    ),
  })
  .refine(
    ({ hasQualifications, qualifications }) => {
      return hasQualifications || qualifications.length > 0;
    },
    {
      message: 'Il faut choisir au moins une qualification',
      path: ['hasQualifications'],
    }
  );
