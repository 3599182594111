import './AvailabilityModal.scss';
import {
  Button,
  DatePicker,
  Modal,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { IllusRLCalendar } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import moment from 'moment';
import { useState } from 'react';

import styles from './AvailabilityModal.module.scss';
import { Props } from './AvailabilityModal.types';

const AvailabilityModal = ({ availabilityDate, saveAvailibilityDate, onClose }: Props) => {
  const [selectedDate, setSelectedDate] = useState(availabilityDate);
  return (
    <Modal
      icon={<IllusRLCalendar />}
      onClose={onClose}
      title="disponibilités"
      open
      footerActionsLeft={[
        <Button.Tertiary
          onClick={() => {
            setSelectedDate(availabilityDate);
          }}
        >
          tout réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary onClick={onClose}>annuler</Button.Secondary>,
        <Button.Primary
          disabled={selectedDate === undefined}
          onClick={() => {
            if (selectedDate !== undefined) {
              saveAvailibilityDate(selectedDate);
              onClose();
            }
          }}
        >
          valider
        </Button.Primary>,
      ]}
    >
      <WithLightTitle title="à partir du" className={styles.dateText}>
        {selectedDate ? moment(selectedDate).format('L') : '-- / -- / ----'}
      </WithLightTitle>
      <DatePicker
        selected={selectedDate}
        minDate={moment().subtract(2, 'days').toDate()}
        onChange={date => setSelectedDate(date ?? undefined)}
        calendarClassName="availabilityModal__date-picker-container"
      />
    </Modal>
  );
};

export default AvailabilityModal;
