import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

import { getBrandCode, getPerimeter } from 'src/Redux/Perimeter/Selectors';
import {
  canDoCriteriaSearch,
  getFirstName,
  getLastName,
  getResultFilters,
  getSearchCriteria,
  getSearchMode,
} from 'src/Redux/Search/Selectors';
import { ResultFilters, SEARCH_MODE } from 'src/Redux/Search/Types';
import { CandidateService, MatchingResult } from 'src/Services/API';

import { QueryKeys } from '../types';

const useApplyFilter = () => {
  const filters = useSelector(getResultFilters);
  const applyFilter = (candidates: MatchingResult[]) => {
    const filteredWorkingHoursCandidates =
      filters.workingHours.length > 0
        ? candidates.filter(candidate =>
            filters.workingHours
              .map(
                filter =>
                  candidate.nbHours !== undefined &&
                  (filter.min ? filter.min < candidate.nbHours : true) &&
                  (filter.max ? filter.max > candidate.nbHours : true)
              )
              .includes(true)
          )
        : candidates;
    return filteredWorkingHoursCandidates;
  };
  return applyFilter;
};

export const useFetchMatchingCandidates = (
  extraOptions?: UseQueryOptions<
    unknown,
    unknown,
    MatchingResult[],
    (Record<string, string> | ResultFilters | string)[]
  >
) => {
  const brandCode = useSelector(getBrandCode);
  const perimeter = useSelector(getPerimeter);
  const lastName = useSelector(getLastName);
  const firstName = useSelector(getFirstName);
  const criteria = useSelector(getSearchCriteria);
  const filters = useSelector(getResultFilters);
  const canSearchByCriteria = useSelector(canDoCriteriaSearch);
  const criteriaForRequest = {
    ...criteria,
    potentialPositions: criteria.potentialPositions.map(
      potentialPosition => potentialPosition.identifier
    ),
    habilitations: [...criteria.habilitations, ...criteria.caces],
    /* the following reducer take off the empty arrays from criteria. To not carry useless element in the request, the caces array
     is made empty since the useful information are now in the habilitations array */
    caces: [],
  };
  const criteriaKeys = Object.keys(criteriaForRequest) as (keyof typeof criteriaForRequest)[];
  const reducer = (
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    criteriaAccumulator: Record<string, any>,
    criteriaKey: keyof typeof criteriaForRequest
  ) => {
    criteriaAccumulator[criteriaKey] =
      Array.isArray(criteriaForRequest[criteriaKey]) &&
      /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
      (criteriaForRequest[criteriaKey] as any[]).length === 0
        ? undefined
        : criteriaForRequest[criteriaKey];
    return criteriaAccumulator;
  };
  const criteriaWithoutEmptyArray = criteriaKeys.reduce(reducer, {});
  const searchMode = useSelector(getSearchMode);
  const searchBody =
    searchMode === SEARCH_MODE.BY_NAME ? { lastName, firstName } : criteriaWithoutEmptyArray;
  const applyFilters = useApplyFilter();
  return useQuery(
    [QueryKeys.fetchMatchingCandidates, searchBody, filters],
    async () => {
      const matchingCandidates = await CandidateService.matchingControllerSearchCandidates({
        body: searchBody,
      });
      const filteredMatchingCandidate = applyFilters(matchingCandidates);
      if (searchMode === SEARCH_MODE.BY_NAME)
        ReactGA.event('Recherche par nom', {
          brandCode,
          agency: perimeter?.defaultAgencyId,
          regionId: perimeter?.regionId,
          zoneId: perimeter?.zoneId,
        });
      if (searchMode === SEARCH_MODE.BY_CRITERIA)
        ReactGA.event('Recherche par critère', {
          brandCode,
          agency: perimeter?.defaultAgencyId,
          regionId: perimeter?.regionId,
          zoneId: perimeter?.zoneId,
          nbCriterias: criteriaKeys.length,
          nbMatchs: filteredMatchingCandidate.length,
          qualifications: !!criteriaWithoutEmptyArray['qualifications'],
          availabilityDate: !!criteriaWithoutEmptyArray['availabilityDate'],
          keywords: !!criteriaWithoutEmptyArray['keywords'],
          skills: !!criteriaWithoutEmptyArray['skills'],
          location: !!criteriaWithoutEmptyArray['location'],
          diplomas: !!criteriaWithoutEmptyArray['diplomas'],
          languages: !!criteriaWithoutEmptyArray['languages'],
          habilitations: !!criteriaWithoutEmptyArray['habilitations'],
          caces: !!criteriaWithoutEmptyArray['caces'],
          company: !!criteriaWithoutEmptyArray['company'],
          drivingLicenses: !!criteriaWithoutEmptyArray['drivingLicenses'],
          service: !!criteriaWithoutEmptyArray['service'],
        });
      return filteredMatchingCandidate;
    },
    {
      staleTime: 300000,
      refetchOnWindowFocus: false,
      enabled: searchMode === SEARCH_MODE.BY_NAME || canSearchByCriteria,
      ...extraOptions,
    }
  );
};
